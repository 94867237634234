import { Button, Col, Divider, Row, Space } from "antd";
import { AddComments } from "./addComments";
import { Video } from "./video";

export const ChapterFooter = ({ title }) => {
  return (
    <div>
      <Row>
        <Col xs={12} sm={12} md={24} style={{ textAlign: "center" }}>
          <Space>
            <AddComments title={title} />
            <Video />
            <Button size="small" type="link">
              Notes from Family
            </Button>
          </Space>
        </Col>
        <Divider />
        {/* <Col xs={12} sm={12} md={6}>
        </Col>
        <Col xs={12} sm={12} md={6}>
        </Col>
        <Col xs={12} sm={12} md={6}>
        </Col> */}
      </Row>
    </div>
  );
};
