import { Row, Col, Image } from "antd";

export const FamilyHolidayToEgypt = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="family-holiday-to-egypt">Family Holiday to Egypt</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/38-family-holiday-to-egypt/group-egypt.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Sheraton Alexandra, Egypt</small>
              </p>
            </div>
          </div>
          <p>
            Fourteen years down the line, all was forgiven and forgotten between
            Roshanne and Avril who was now a travel agent, conducting tours to
            exotic destinations worldwide. Nahil decided to take the kids on a
            tour to Egypt including a cruise on the Nile. All three children
            along with Roshanne embarked on the tour with 15 others,
            coincidentally all friends, with Avril leading the tour. Some of the
            others in the group were Nissanka and Desiree with their son, Lumpy
            and wife, Arjun and Deepika, Amal, Saumya and daughter, and Chukka
            and his wife. Nahil wanted the kids to enjoy their holiday and got
            them to invite a best friend each to join them on the trip with all
            expenses met by Nahil, while his older son Vajira and he bonded with
            each other as they shared a room. It was Christmas Eve on the way
            back to Colombo. While in transit in Bahrain, Avril sat at the piano
            in the lobby and played carols, with everyone in the lounge joining
            in the singing. Later, he found Avril, Roshanne and the kids, buying
            gifts for each other at the Bahrain duty free, happily using his
            credit card. &ldquo;I now feel deeply for Arabs with multiple
            wives!&rdquo; says Nahil.
          </p>
        </Col>
      </Row>
    </div>
  );
};
