import { Row, Col } from "antd";

export const ParakramaJayasekeraAndFamily = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="parakrama-jayasekera-and-family">
          Parakrama Jayasekera and Family
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            wife May and daughter Roshanne had returned to Colombo ready to
            settle down long term in Sri Lanka. They invested all their savings
            brought along with them from the UK in Rukman Senanayake&rsquo;s
            transport business in Sri Lanka, which crashed, causing Para and May
            to lose all their capital bringing an unfortunate struggle to the
            family. Parakrama a Royalist, who was a part of Nahil&rsquo;s gang
            of Colombo buddies while schooling, would come by frequently for a
            drink with Nahil, accompanied occasionally by May and Roshanne,
            usually after Roshanne&rsquo;s athletics training at the Colombo
            University Grounds.
          </p>
          <p>
            Nahils first site of Roshanne was at the Cr&amp;FC grounds. One
            evening while having a beer with Para he noticed a good looking girl
            training on the groundsm the track which was used by school
            children. After a few rounds Roshanne came towards them at which
            point Nahil nudged Para and said &ldquo;look at that
            beauty&rsquo;&rsquo; to which Para answered &lsquo;&lsquo;Thats my
            daughter&rsquo;&rsquo;.&nbsp;
          </p>
          <p>
            Empathizing with their situation, Avril suggested to Nahil that they
            offer Para and family a chance to get their life together once again
            in the UK, by paying for their flights and giving them lodging at
            their Mews house in London. The Jayasekeras were grateful and
            accepted the offer. May and Roshanne were to leave first and Para to
            follow after he received a judgment on an ongoing court case.
          </p>
          <p>
            Retrogress to the mews; the front wall of the mews was painted
            cobalt blue that gave the mews a dated look which they decided to
            update by painting it entirely in white. It was during this time
            that BBC Scotland approached them, seeking the possibility of using
            the house in a film, based on Gavin Lyall&rsquo;s book &lsquo;The
            Secret Servant,&rsquo; since the mews flat in its original state is
            described in the book. Lyall originally wrote The Secret Servant as
            the pilot episode of a proposed BBC TV series. The series was
            directed by Alistair Reid, with a screenplay by Brian Clemens. The
            role of Harry Maxim was played by Charles Dance, with Jill Meager as
            Agnes and Harvy Ashby as George Harbinger. They gave it some
            thought, finally making a deal with the BBC representative .Instead
            of cash for the filming, they opted for white paint for the exterior
            wall of the Mews to be supplied by BBC. Considering this was quite
            an expanse of wall, it was a sweet deal. BBC made good on its
            promise once the movie was a wrap.
          </p>
          <p>
            More than happy with the deal, BBC Scotland started filming almost
            immediately, with Mahen Thambiah installed in the mews house as the
            overseer. They were quite aware of the mess the house would be in
            while filming was going on since they had previous experience of the
            mess created by a crew when a commercial was shot at their home on
            Flower Road, therefore they got back to Colombo before the circus
            commenced. A fortnight later Avril set off to London to settle in
            May and Roshanne who had just arrived there. Charles Dance, (Harry
            Maxim) in the movie, had made his presence felt by plastering
            stickers with his name all over the mews. Yes, he was a kind of
            narcissist!
          </p>
          <p>
            May and Roshanne settled in, with May getting a job almost
            immediately while Roshanne started French classes. Avril decided to
            stay in London since there was nothing much to keep her occupied in
            Colombo, while Nahil travelled into London quite frequently.
          </p>
          <p>
            A few months into Roshanne and May&rsquo;s stay, while Avril was
            still living in London, as usual Nahil had arrived in the UK. It
            seems that within a few weeks into his stay in London. he got
            emotionally involved with Roshanne who was still living at the mews
            with them. Driving mother and daughter to North London since he was
            on his way there, Roshanne who was cramped up at the back of
            the&nbsp;&nbsp;two seater had spontaneously started feeling
            Nahil&rsquo;s side. Not one to let go, he took the cue and it all
            started at that point. By this time May had moved to a flat in
            Sussex Gardens which was round the corner to the mews. Avril was
            oblivious to the going ons between Roshanne and Nahil, with all of
            them living in harmony at the mews. Not wanting to rock the boat too
            much, Nahil returned to Colombo two weeks later. As expected he
            would telephone Avril, followed by a chat with Roshanne if she was
            around. Avril says she often wondered why he played the record by
            Tammy W, titled &lsquo;Stand by Your Man&rsquo; and got her to
            listen to it!
          </p>
          <p>
            &nbsp;Returning to the UK a few weeks later, he noticed Roshanne had
            put on weight and didn&rsquo;t seem right, but there was no drama.
            &ldquo;I just kept the juggling act going between my wife and the
            lass, says Nahil.&rdquo;
          </p>
          <p>
            A few weeks later, Avril phoned Nahil desperately &ndash; with the
            news that she had taken Roshanne for a medical examination to their
            family GP,&nbsp;&nbsp;Dr. Narada Jayatilake after Roshanne had
            complained of a female problem. After examining her, Narada was
            positive that Roshanne was pregnant. It was then&nbsp;&nbsp;that it
            struck Nahil about her physical appearance on his previous visit,
            especially the bloated lips.
          </p>
          <p>
            What followed was stressful, sad and joyful at the same time.
            Stressful and sad that he had hurt Avril so much and joyful that his
            second son Vijitha was born. Roshanne and he, walked to the hospital
            since the mews was just around the corner to St. Mary&rsquo;s
            Paddington, and Vijitha Jayantha was born to Roshanne who was 18
            years and 05 months old at the time of his birth on October 10th
            1985 in the ward next to the famous &lsquo;Lindo Wing.&rdquo;
          </p>
          <p>
            Though they thought through some workable options to save their
            marriage, with a baby in the mix, the hurt went deeper than imagined
            on all fronts. Nahil and Avril went in for an amicable divorce with
            him marrying Roshanne in July that year.&rsquo;&rsquo;
          </p>
          <p>
            When all this blew up, before Vijitha&rsquo;s birth, Para
            (Parakrama) had made his way to the UK. Embarrassed and angry, he
            was hunting around London in search of Nahil to get
            &lsquo;even&rsquo; for his folly with his daughter, while Nahil with
            a heavily-pregnant Roshanne kept moving from one friend&rsquo;s home
            to another, until Gamini D requested him to return to Colombo and
            take refuge at his home, which he did, along with Roshanne.
            Eventually Para backed off after being advised by many, ironically
            including Avril, to let go, clearing the way for the two of them to
            move back to London. Vijitha was born shortly after.
          </p>
          <p>
            Nahil and Roshanne returned with Vijitha, now four months old, to
            Colombo amidst the scandal &ndash; his friend&rsquo;s daughter
            pregnant by him, now with the child in tow. His brother-in-law
            Vasantha Karaliedde, Dr. Lakshman&rsquo;s brother, knew the extent
            of the scandal and wrote him a letter empathising with his
            situation, quoting Oscar Wilde - &lsquo;the only thing worse than
            being spoken of, is not being spoken of,&rsquo; -
            encouraging&nbsp;&nbsp;him to take all this in his stride and not be
            embarrassed.&rdquo;
          </p>
          <p>
            He stayed the first few days with Gamini, subsequently moving into
            a&nbsp;&nbsp;flat owned by Kili, free of charge, right behind the
            Presidential Secretariat, above Rumors Salon, living there for just
            over a year. With the passage of time and more scandalous events
            happening in Colombo, his indiscretion was pushed to the backburner.
            Nahil bought a house in Green Path and lived there in peace until
            his next adventure &ndash; the &lsquo;JVP&rsquo;in 1989!
          </p>
          <p>
            Vasantha, who was an eminent lawyer with an excellent command of the
            English language, worked as a legal draftsman in the UK. Sadly he
            passed away under tragic circumstances.
          </p>
          <p>
            Nahil and Roshanne got married straight after Avril divorced Nahil
            and a year later his daughter, Anika was born 20th October
            1986.&nbsp;
          </p>
        </Col>
      </Row>
    </div>
  );
};
