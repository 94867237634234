import { Row, Col } from "antd";

export const MrLakshmanKadirgamar = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="mr-lakshman-kadirgamar-his-mentor">
          Mr. Lakshman Kadirgamar – His Mentor
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Being an active old boy he would often meet Lakshman Kadirgamar, at
            the time he was President of the Trinity College Kandy Old Boys
            Association.
          </p>
          <p>
            During the period Nahil was consulting Lakshman Kadirgamar, an
            expert on world Intellectual Property Rights and Copyright law,
            on&nbsp;&nbsp;ETV matters, he was invited by Lakshman to his
            residence to &lsquo;discuss something very important&rsquo; that
            could be discussed only in the presence of his close friends.
            &ldquo;I was elated,&rdquo; says Nahil, &ldquo;to be counted in his
            circle of close friends.&rdquo; Sinha Ratnatunga was the junior
            lawyer in the ETV case, he and Suganthi Wijesooriya representing De
            Serams, were the only three people present at the meeting. LK told
            them that Chandrika had sent word through Barnes Ratwatte,
            requesting him to join the SLFP National List since it would be good
            PR for her to have a respectable Tamil on the list. He had been
            quite sarcastic while discussing the invitation, saying something to
            the effect &ldquo;Is this all I&rsquo;m good for? I hope she
            doesn&rsquo;t expect me to put a pottu, dress in a verti and walk
            around Wellawatte!&rdquo; Sinha and Suganthi were opposed to the
            request, citing that as President of the Bar Association, being a
            part of the Sri Lankan political framework was not the best for him.
            During the discussion Nahil somehow figured out by LK&rsquo;s body
            language that he wanted to take up this challenge and encouraged him
            to do so, since this would be an effective platform&nbsp;&nbsp;to
            execute his thoughts and ideas using the full force of the
            government machinery to put forward his social reform and plans for
            the country. All this was impossible as the President of the Bar
            Association and the Trinity College Kandy Old Boys Association...
          </p>
          <p>
            Nahil advised him not to be &lsquo;made use of&rsquo; after the
            elections by making it a pre-condition that before he joins the
            party, he should be given the post of Minister of Foreign Affairs or
            Finance, and not to be relegated to a corner like the minister of
            Cultural Affairs. Lakshman wanted to know why Nahil being an UNPer
            &ndash; since his dad&rsquo;s time &ndash; was supporting him in the
            opposition.&rsquo;&rsquo;
          </p>
          <p>
            &ldquo;I told him it&rsquo;s always good to have civil people on the
            other side&nbsp;&nbsp;who will not in an argument pull a gun on
            me!&rdquo; One of his major achievements was getting the LTTE banned
            in the UK and Europe, there by irking most of the Tamil diaspora.
          </p>
          <p>
            He achieved international prominence as Foreign Minister due to his
            wide ranging condemnation of the LTTE (Tamil Tigers) and his effort
            to have them banned internationally. Following the victory of the
            UPFA in April 2004, he was nominated as a possible candidate for
            Prime Minister of Sri Lanka, but on April 6th President
            Kumaranatunge appointed Mahinda Rajapaksa to the post.
          </p>
          <p>
            He was also an Honorary Master of the Inner Temple- one of the four
            Inns of Court in England. In 2004 he was elected as an Honorary
            Fellow of Balliol College, University of Oxford and the president of
            the Oxford Union in 1959. LK acquired a wide circle of friends
            during his time at Oxford University, one of whom was, Chris Patton,
            who lobbied for LK&rsquo;s photograph to be included on the walls of
            the Oxford Union. The other Sri Lankans who served in the Oxford
            Union were Mr. Lalith Athulatmudali as President in 1958 and Mr.
            SWRD Bandaranayake as Secretary in 1923.&nbsp;
          </p>
          <p>
            In Trinity, although he was much senior to Nahil, their paths would
            cross, meeting often at functions or homes of mutual friends. Nahil
            impressed by LK says that his speech was always articulate and
            eloquent, along with the endearing quality of being an avid
            listener, immersing himself in the moment, which gave him the
            advantage of comprehending any conversation to perfection. According
            to Nahil, this was one outstanding quality of Lakshman Kadirgamar.
          </p>
          <p>
            At the time LK was the Minister of Foreign Affairs, Nahil&rsquo;s TV
            station, ETV, was in full function. Nahil used their media advantage
            to give LK maximum coverage whenever possible. One event of
            significance was the outstanding speech he made at the UN in New
            York. Sinha Ratnatunga, the Editor of the Sunday Times newspaper,
            was part of LK&rsquo;s media team. Nahil paid for Sinha&rsquo;s
            travel to NY and back so he could get the recording of the speech
            and rush the recording back to ETV, an exercise that otherwise would
            have cost Nahil, in his words &lsquo;an arm and a leg,&rsquo; if
            they tried broadcasting live, with the time zone difference also
            being a negative factor. Sinha returned with the tape a few days
            later and the speech was broadcast as a live event.
          </p>
          <p>
            During this period, while the case was full on, Nahil would meet
            with LK on ETV matters. A part of LK&rsquo;s team was Suganthi
            Wijesooriya (no relation of Nahil&rsquo;s), his assistant and his
            girlfriend. Nahil first went to him for a legal opinion while LK was
            living in his residence at the Bullers road junction.
          </p>

          <p>
            Star TV, instigated by the Maharajas sent Nahil a &lsquo;cease and
            desist&rsquo; letter, accusing him of violating the copyright law by
            downloading their signal and transmitting through ETV. He told them
            to scramble the signal if it was such a big deal, fully aware that
            by doing so their business model would go for a six since their main
            concern was building up a healthy viewer base.
          </p>
          <p>
            Star TV was clueless as to where Sri Lanka was on the map, if not
            for Maharajas winding them up, whinging that they were paying US$
            50,000 for half an hour of BBC, while ETV broadcast it 24x7 free.
            Nahil would randomly pick programmes like &lsquo;The Bold and the
            Beautiful&rsquo; or &lsquo;WWF,&rsquo; transmitting whatever that
            appealed to his viewers. His business model was to advertise 20-30
            second advertisements in the same time slots as Star TV. Helping him
            along with this was his crew, who were by now experts at identifying
            the time slots, always ready with the tapes to insert the
            advertisements, overriding the Star TV adverts.
          </p>
          <p>
            Nahil took the letter to Lakshman Kadirgamar, managing to convince
            him to look at it from his perspective. Star TV had no broadcasting
            rights into Sri Lanka, whereas Nahil had a valid license and
            therefore Star TV was the infringer, not him. If someone broadcast
            from a mountain top, the cops could get up there and pull the
            transmitter down, but they are not in a position to go up and pull
            down a satellite. LK accepted Nahil&rsquo;s explanation on this.
            They went to courts, admonishing Star TV for writing threatening
            letters to ETV. After all, it was their accuser Star TV that was
            illegally broadcasting into our land without a license. The case was
            progressing quite well, virtually coming to decision time, when LK
            says, &ldquo;Let&rsquo;s go to the UK and meet up with my Oxford
            buddy Jeremy Laver, an expert in IP law and his assistant Heather
            Lawrence, for their opinion on this.&rdquo; Laver communicated to
            Nahil that even if he had done this in the UK, it would have been
            legal. Nahil thinks this was also a little joy ride for LK and
            Suganthi.
          </p>
          <p>
            With their judgment about to be given, he sent a copy of all this to
            the Court with a copy to Star TV. Shortly after, an Englishman from
            Hong Kong turned up with a prepared document from their legal
            department to settle the matter out of courts with the conditions
            outlined. The first condition was that ETV acts as the official
            broadcaster for Star TV for two years. The second condition was the
            withdrawal of the case against them. Amazingly, this unique bit of
            legislature has not been practiced in any country up to now, since
            copyright violation in satellite broadcasting from their footprint
            has never been tested. Star TV was weary of a precedent being
            created. If you give it some thought, how can a foreign TV station
            broadcast to another nation without a broadcasting license? However,
            as a conclusion they had a few beers where camaraderie and warm
            humour reigned.
          </p>
          <p>
            &nbsp;That year, while ETV was advertising the upcoming screening of
            the Hong Kong Sevens, Nahil received a call from a British woman in
            Hong Kong, who, from her tone of voice, sounded miffed. She wanted
            to know if he was &lsquo;so and so&rsquo; from ETV. He answered in
            the affirmative, enquiring what her problem was. She says, &ldquo;I
            hear you are selling advertising space pertaining to the screening
            of the Hong Kong Sevens next week and it&rsquo;s illegal.&rdquo;
            Nahil wanted to know if she was from the Legal or Commercial
            Department of Star TV; she informed him she was from Sales. He then
            let her know that the Star TV broadcast could be viewed by anyone
            with a satellite dish and went on to tell her that ETV was
            broadcasting Star TV programmes to the poor masses by transmitting
            to them on the normal terrestrial channels as they were not in a
            position to afford a satellite dish. After hearing him out, she says
            to him, &ldquo;So you are the Robin Hood of Sri Lanka?&rdquo; He
            says, &ldquo;No, I&rsquo;m the Saradiyel of Sri Lanka.&rdquo;
            &ldquo;Who&rsquo;s that?&rdquo; she asks, to which he replies,
            &ldquo;It&rsquo;s a long story. Let&rsquo;s meet for a coffee
            someday, and then I will dish it out to you.&rdquo;
          </p>

          <p>
            &nbsp;ETV had just obtained a broadcasting license and launched ETV.
            With all the equipment installed, they were in the throes of
            conducting test transmissions when unexpectedly, he got a message
            from a friend, Asoka Gunasekera, Secretary, Ministry of
            Telecommunication, during the time Mr.Premadasa was the President.
            He says, &ldquo;Sorry Nahil, Mr. Premadasa wants your TV
            broadcasting stopped and your station sealed.&rdquo; Taken by
            suprise, he immediately inquired from the Head of the
            Telecommunications Regulatory Commission (TRC), Manikavasagam, what
            all this drama was about. He says, &ldquo;We haven&rsquo;t been
            given a reason, but it&rsquo;s a directive from the
            President.&rdquo; The drill was to haul away all the equipment from
            the station and store it at the Telecom warehouses. Nahil told him
            this move would ruin the equipment since it needed to be kept in an
            air-conditioned environment and suggested they come and seal the
            entire building. With a bit of persuasion, they got the authorities
            to cut them some slack by sealing the equipment, which they did by
            covering it with brown paper. &ldquo;It was about the size of a
            cabinet, tied up in twine and sealed with red sealing wax.&rdquo;
            Nahil told them this was all wrong, comparing it to the equivalent
            of a rotor in a car (the distributor motor) which can be
            demobilized. He instructed them to take the main transmitter tube
            and keep it wherever they wanted, which they agreed to.
          </p>
          <p>
            While trying to find out the reason for this sudden move by Mr.
            Premadasa, &nbsp;&nbsp;Nahil &nbsp;&nbsp;figured
            &nbsp;&nbsp;Maharajas, &nbsp;&nbsp;being &nbsp;&nbsp;competitors,
            had something to do with it. He was spot on. They spun a story to
            Premadasa who had just come out of his impeachment by Gamini
            Dissanayake saying, &ldquo;Do you think a schoolmate of Gamini
            Dissanayake owning a TV station is a good idea? You need to
            terminate their broadcast immediately.&rdquo; It was obvious that
            they were desperate to shut ETV down and successfully planted a seed
            of doubt in Mr. Premadasa.
          </p>
          <p>
            This was a load of fabricated nonsense. Nahil tried to meet Mr.
            Premadasa to put things right, using various mutual connections. He
            happened to know Mr.Premadasa&rsquo;s best friend, who was then the
            Chairman of the Building Materials Corporation (BMC) &ndash; Anton
            Alwis. Anton was appointed as a Director of ETV just to keep
            Mr.Premadasa happy, mainly because with Anton on board ETV he would
            not try to tarnish Mr.Premadasa in any way. Usually Nahil has an
            unorthodox approach to almost all things, but using his media outlet
            to mess with the President would have been disastrous and something
            he would never do. He says the word impeachment was foreign to him,
            until he read it in the newspapers.
          </p>
          <p>
            Weeks had gone by and his effort to see Mr.Premadasa seemed futile
            until his brother-in-law, Dr. Lakshman Karaliedde on holiday from
            London, noticed the station was off the air and wanted to know what
            had happened. Nahil told him about their predicament and, among
            other things, about their futile effort to meet the President.
          </p>
          <p>
            He suggested he&rsquo;d speak to the Mahanayake of Malwatte who was
            a relative, to secure an appointment for Nahil with Mr.Premadasa. He
            was also the Mahanayake&rsquo;s physician while living in Sri Lanka.
            The Mahanayake indicated to Lakshman that the TV station and other
            stuff were of no consequence to him and he was willing to set up an
            appointment solely on the basis that Nahil was a Sinhalese Buddhist
            from Kandy. The next day he got a call from Anton Alwis that
            Mr.Premadasa had wanted him to bring &lsquo;Mr. Nahil&rsquo; to meet
            him. His first thought was &lsquo;license OK, back to business as
            usual.&rsquo; He was there the next morning at the Prime
            Minister&rsquo;s office on Flower Road at 9 a.m. with Anton and
            Vipula Soysa. They were kept hanging around till 11:30 &ndash; the
            last lot to be called in.
          </p>
          <p>
            As they walked in, he says, &ldquo;Mama enna kiwwe Malwatte kiyapu
            hinda&rdquo; &ndash; (&ldquo;I wanted you to come here because
            Malwatte asked me to.) I know you were involved in the
            impeachment.&rdquo; Nahil was in a fluster wondering how to tell
            Mr.Premadasa that it was not so, patiently holding on for a moment
            to correct him. Nahil had no clue where to start, waiting for a
            break in the conversation to enlighten him. Finally, getting a word
            in, he said to him in the gentlest way possible, &ldquo;Sir, I
            don&rsquo;t think your information is quite right.&rdquo;
            Mr.Premadasa in a flurry pushes his chair back and says, &ldquo;Are
            you trying to tell me that I don&rsquo;t know my job?&rdquo; In
            other words, zip it up! This was a one-way exercise. &ldquo;Apith
            ekka innawa kiyala monawa hari karala pennanna oney&rdquo; &ndash;
            (&ldquo;If you are with us, you should do something to prove
            it&rdquo;). The final thing he said was, &ldquo;Oneynam apith ekka
            happila balanna, dannawa ne Upali ta wechcha wade&rdquo; &ndash;
            (&ldquo;You know what will happen if you mess around with us &ndash;
            you know what happened to Upali&rdquo;). Here he was trying to take
            credit for Upali&rsquo;s disappearance, and threatening Nahil with
            the same fate.
          </p>
          <p>
            Phew! After all this Nahil knew he needed to take the kids and leave
            to the UK, for their safety and his sanity, which he did. He left
            with the children to the UK the next day.
          </p>
          <p>
            While Mr.Premadasa was pitching into Nahil, Sanath Gunathilake, the
            actor, was sitting outside in the corridor and as the door was open
            he had heard all the drama. On their way out he tells Nahil,
            &ldquo;Hondata waduna neda?&rdquo; &ndash; (&ldquo;You got it bad,
            right?&rdquo;). Later on, when Nahil met Sanath at a function he
            asked him how things went for him during his meeting with Mr.
            Premadasa. He says, &ldquo;My meeting was funnier.&rdquo;
          </p>
          <p>
            A general election was coming up and Mr. Premadasa was trying to get
            Sanath to contest under the UNP banner. Sanath had told him,
            &ldquo;Sir, api oya politics danne na neda, api actors la&rdquo;
            &ndash; (&ldquo;Sir, we don&rsquo;t know politics, we are
            actors&rdquo;) to which Premadasa had replied, &ldquo;Eka thamai,
            api okkoma actors wela&rdquo; (&ldquo;That&rsquo;s true, we are all
            actors now&rdquo;). Nahil was amazed by Premadasa&rsquo;s honesty!
            Here he is confessing the truth for the first time. All the
            shouting, anger and pushing back his chair during their meeting had
            been a drama; mere acting.
          </p>

          <p>
            While in the UK after all the drama, weeks into his stay,
            Mr.Premadasa was assassinated on the 1st of May. Nimal, who was in
            London at the time, visited Nahil on his birthday, being the 8th of
            May, with a magnum of champagne and a shirt. The shirt was for Nahil
            and the bottle of bubbly for Mr.Premadasa. After Mr.Premadasa passed
            away, Nahil and the kids returned to Colombo.
          </p>
          <p>
            A few weeks after Mr.Premadasa&rsquo;s demise, D.B. Wijetunga took
            over as president. Dr. Darrell Mathew was Mr. Wijetunge&rsquo;s
            physician at the time and was well known to Nahil. He volunteered to
            speak to DB regarding the ETV licence confiscated by Mr. Premadasa.
          </p>
          <p>
            Nahil was grateful for his help and agreed to a meeting that was set
            up almost immediately. At the meeting, following a brief overview of
            what happened, DB says, &ldquo;Ah mata kiyanna oney na, mama dannawa
            wechcha wade&rdquo; &ndash; (Ah You don&rsquo;t have to tell me. I
            know exactly what happened.&rdquo;) With that said, he places a call
            to the Minister of Cultural Affairs, who was in charge of licences,
            to return the ETV licence. All it took was a few minutes.&nbsp;
          </p>
          <p>
            ETV was one station with two channels, airing concurrently as ETV1
            and ETV2. Usually, when a licence is issued, two frequencies are
            included in the licensing. How this works is when your second
            transmitter overlaps &ndash; say there&rsquo;s one here, and one in
            Galle or wherever, it&rsquo;s the same signal you get, termed as
            &lsquo;ghosting&rsquo; from the receiver, so you have two
            frequencies &ndash; either this or that. When Nahil&rsquo;s licence
            was issued, there was no specification of the number of channels, it
            was a plain old broadcasting licence.
          </p>
          <p>
            With many frequencies available for national coverage on the second
            frequency, he would usually choose a day when a special event was to
            be broadcast, for instance, an important cricket match, and insert a
            slide on ETV1 that read &lsquo;the following match will be shown on
            ETV2&rsquo;. He was only giving the frequency since ETV2 was
            non-existent at the time. The Secretary of the Ministry of
            Telecommunications K.S. Gunasekera, freaked out and called him to
            say, &ldquo;Nahil, &ldquo;What the hell are you talking about an
            ETV2? You don&rsquo;t have a licence!&rdquo; He calmly told K.S. to
            check his documentation where he will notice that there is no
            mention of the number of channels. K.S rants saying, &ldquo;No, no,
            you are not authorized to do that.&rdquo; Nahil immediately inserted
            a notice on ETV1 that read: &ldquo;K.S. Gunasekara has informed us
            that we are not authorized to broadcast the match. You can call him
            on the following numbers,&rdquo; and displayed K.S.&rsquo;s home
            number and a few other numbers of the Ministry on the screen.
          </p>
          <p>
            He telephoned Nahil the same day saying, I was having lunch at home
            and all these calls are a bloody nuisance. I think you are right
            about the second channel, please go ahead and show the cricket
            match.&rdquo; Nahil, while thanking K.S. for permitting the
            broadcast on the second channel, took the opportunity to request for
            a document confirming the same faxed to him.
          </p>
          <p>
            The next morning, what he received was not permission to show the
            match, but the full licence for a second channel. Nahil heard that
            K.S. had been inundated with calls during his lunch break after ETV
            displayed his telephone numbers on TV! In true &lsquo;Nahil
            fashion,&rsquo; he set the pace with channel 2, with the other
            stations following suit.
          </p>
          <p>
            Funnily, in the mix was a letter written and jointly signed by MTV,
            Rupavahini and ITN requesting that Nahil should be stopped from
            pirating since this practice was supposedly spoiling the image of
            the country. These people were behaving like a bunch of spoilt
            brats. They seem to forget that thanks to his maneuvering of the
            system they were able to obtain two channels, which continue even
            now.
          </p>
          <p>
            Following his lead, all the local TV stations had received licences
            for channels 1 and 2. Nahil was broadcasting BBC news 24 hours a day
            at no cost while MTV was paying BBC US$ 50K for half an hour of
            broadcast each day, of which they were griping about as usual.
            Lakshman Kadirgamar wanted Nahil to join him for dinner that
            evening, to meet, a good friend of his, McDonald, Head of BBC, who
            was passing through Colombo. The meeting&rsquo;s objective was for
            Nahil to discuss the situation with the man and sort something out.
            Soon they were on first name basis and he says, &ldquo;Nahil, you
            know, you are taking on the might of the BBC,&rdquo; to which he
            replied that what he was told was &ldquo;very interesting,&rdquo;
            outwardly he made a joke of it and laughed even though on the inside
            he resolved he would enjoy taking on the &lsquo;might&rsquo; of the
            BBC. No one was going to browbeat him. Never!
          </p>
        </Col>
      </Row>
    </div>
  );
};
