import { Row, Col, Image } from "antd";

export const Th5EngineerPOLines = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="5th-engineer-p&o-lines">5th Engineer, P&O Lines</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Leicester College done and HND in Mechanical and Production
            Engineering in hand, he was contemplating returning to Sri Lanka,
            when fate played a different hand. He had extended his visa thrice
            due to Tuula. Besides he was wasting his father’s money hanging
            around in the UK. His options were either to find a job immediately
            in engineering to stay on or return to Sri Lanka.
          </p>
          <p>
            As fate would have it, a day later, a friend, Ratnasiri de Silva,
            who was also an ex - Walkers apprentice, a willing victim of ‘Flower
            Power,’ who had just concluded his contract on a ship, requested
            Nahil who was very familiar with getting around in London to
            accompany him to a job interview he had with a shipping company in
            the East End, including visits to other shipping companies in the
            area.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/09-5th-engineer-p%26o-lines/crew-ss-nowshera.jpg"
              }
            />
            <div style={{ textAlign: "center" }}>
              <p>
                <small>
                  Crew on SS Nowshera – Nahil (L), Vasantha Gnanakone and
                  buddies
                </small>
              </p>
            </div>
          </div>
          <p>
            Ratnasiri was the son of the former Member of Parliament for
            Habaraduwa, Mr. G.V.S. de Silva, a great economist who was the
            private secretary to Mr. Phillip Gunawardena, a founder of the
            Marxist movement in Sri Lanka, and Minister of Agriculture, Food and
            Cooperatives, in the cabinet of the late Mr. S.W.R.D. Bandaranaike.
            GVS was responsible for drafting the controversial Paddy Lands Act,
            which gave tenant cultivators some rights.
          </p>
          <p>
            Following their visit to four shipping companies in the East End,
            they got to Leadenhall Street, where the impressive P&O office was
            located. Ratnasiri had an interview with P&O Lines. While Nahil sat
            around waiting for him, Ratnasiri got back from the interview
            looking dejected. He conveyed to Nahil that he felt his interview
            did not go well and he was sure he was not getting the job. Nahil
            asked Ratnasiri if he would mind him facing the interview, to which
            Ratnasiri agreed gracefully, requesting Nahil to go ahead. He met
            the interviewer, David Long, who was impressed with his
            qualifications. He aced the aptitude test which made David question
            whether he had done it before. Nahil told him, “No, it was just too
            easy.” Amazingly, he ended up with the job his friend was
            interviewed for! The post was for 5th Engineer on the ship MV
            ‘Chakdhara,’ a ship belonging to the British India Steam
            Navigational Company – P&O Shipping Lines. Nahil was told he could
            join any time and when he called dad with the exciting news his
            dad’s response was, ‘‘What on earth were you thinking accepting the
            job? How can you go to sea, you can’t even swim!’’
          </p>
          <p>
            The British India Steam Navigation Company (BISNC) was formed in
            1856 as the Calcutta and Burmah Steam Navigation Company formed out
            of Mackinnon Mackenzi & Co, a trading partnership of the Scots
            William Mackinnon and Robert Makenzie, to carry mail between
            Calcutta and Rangoon. The 3,035 tonne, MV Chakdara on which Nahil
            sailed as 5th Engineer was built in 1914, purchased by BISNC in 1933
            and eventually sold to Scindia SN Co., in the 1970s renamed MV
            Burmenstan.
          </p>
          <p>
            Shortly after Nahil got his job as a Marine Engineer, Tuula returned
            to Finland, subsequently settling down in Stockholm, Sweden. He says
            her absence in his life was painful, but he had to be rational in
            his decisions even at the cost of a broken heart. This was the
            period in history when the Suez Canal was closed and ships coming
            from the Atlantic had to go around the Cape of Good Hope to get to
            the Far East, a 54-day sail. He was missing her so much that he
            disembarked in Hong Kong, and immediately boarded a flight to
            Stockholm, on his way to see Tuula again. After spending two awesome
            weeks with her, and much tears and sadness, it was a ‘forever
            goodbye’.
          </p>
          <p>
            Still at P&O Lines, in 1972 he had the opportunity of travelling to
            Europe to train on a Sulzer RND 1050 diesel engine at the main
            research centre in Winterthur, Switzerland, which was an excellent
            addition to his qualifications.
          </p>
          <p>
            He knew the big guys at the Head Office were fond of him; this was
            confirmed to him later on. Being tenacious, he took full advantage
            of their affection to get transferred to different ships every three
            months, even though the standard rule was a few years per ship. His
            agenda was to get the opportunity to work on different, new engines,
            thereby gaining more experience. As he kept transferring to various
            vessels, expanding his knowledge and experience, he was able to work
            on five large engines manufactured in the UK, Germany and Holland.
          </p>
          <p>
            He was able to work on a steam engine, which was an excellent,
            opportunity as his ultimate goal was to work on the best diesel
            engines in the world manufactured in Switzerland and when he sent in
            his request, it was granted. A few days later he jetted off to
            Switzerland for a month to experience and work on the best engines
            being developed there.
          </p>
          <p>
            There was an Indian sailor named Mike Rozera, a Second Engineer on
            another ship owned by P&O, who was envious of Nahil, and was always
            trying to find ways and means to get at him.
          </p>
          <p>
            As a 5th Engineer, part of Nahil’s job was to take the log of the
            first watch and enter the details in the proper logbook stored in
            the cabin of the Chief Officer. While attending to the job at hand,
            he noticed the personal files of the employees stacked on a shelf in
            the Chief’s cabin and decided to glance through his file. He found a
            letter of introduction that read ‘he is an exceptional eastern
            service recruit and we look forward to his progress,’ which had been
            forwarded from the Mackinnon office in Bombay, while requesting a
            report of his progress. Mike Rozera had read this file, hence his
            jealousy towards Nahil.
          </p>
        </Col>
      </Row>
    </div>
  );
};
