import { Row, Col, Image } from "antd";

export const CleaningtheBeiraLake = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="cleaning-the-beira-lake">Cleaning the Beira Lake</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/54-cleaning-the-beira-lake/beira-lake.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>The Beira Lake prior to the cleanup</small>
              </p>
            </div>
          </div>
          <p>
            The Navam Mawatha Business Complex was Nahil&rsquo;s little
            empire.East West was the owning company of IBM, Forbes and Walker
            and Sri Lanka Shipping buildings which included the central car park
            area at Navam Mawatha. Due to the dry season, there was a foul smell
            around the Navam Mawatha Complex, permeating through with the breeze
            from the Beira Lake. The UDA, if he remembers correctly, was under
            the purview of Mangala Samaraweera. This was a reoccurring issue,
            especially during the dry season. The Australian and Canadian
            governments had given the UDA a grant of US$1Meach and appointed
            their consultants. Volumes of research were done by these people,
            but to no avail.{" "}
            <strong>
              However, they left few volumes of the research done of the Beira
              Lake.&nbsp;
            </strong>
          </p>
          <p>
            One hundred years ago the Beira, a salt water lake situated in the
            centre of the city of Colombo was 1.65 sq km (0.64 sq ml) which down
            the years has reduced to 0.65 km2 (0.25 sq mi). During the colonial
            era of the Portuguese, Dutch and the British, the lake was used for
            a wide variety of purposes. It still retains its Portuguese name -
            Beira - which inPortuguese means border or edge. The lake is
            connected to many intricate canals which provided an easy route for
            the transporting of goods within the city and suburban cities. The
            lake was built by the Portuguese to protect Colombo from enemies,
            mainly the local Kings, by the construction of the moat which was
            initiated by digging the marshy land surrounding the fort in all
            directions except the west, which was the Indian Ocean.
          </p>
          <p>
            Unfortunately, the water available in the marsh was not enough to
            feed the lake until a Portuguese captain, Lopo de Brito, while he
            was chasing away an invasion lead by King Vijayabahu V11, discovered
            a stream that flowed between the Mount of Demetagoda and Mount of
            St. Sabastian. After informing the officials at the fort about the
            stream, it was connected to the moat they had dug, creating Beira
            Lake.
          </p>
          <p>
            After the Dutch laid siege and captured the lake full of crocodiles
            it was expanded by 1/3 of the original and created several islands
            such as Slave Island with some of the islands large enough to build
            a village and plant 600 coconut trees.&nbsp;
          </p>
          <p>
            Once the British took over they removed the crocodiles and developed
            the area surrounding the lake for recreational activities such as
            rowing and yachting. Ceylon&rsquo;s first botanical gardens - the
            Kew Gardens, was opened in Slave Island in 1910 to cultivate
            seedlings provided by the Royal Botanical Gardens at Kew in London,
            to be eventually planted in Peradeniya Botanical Gardens.
          </p>
          <p>
            Sadly by the 19th century land reclamation for development began and
            the land area of the lake was reduced, while pollution began to
            increase and the lake turned green emanating a bad odor due to over
            100 years of pollution with the algae that are present in the water
            contributing to the bad odor, very prominent during dry weather due
            to high rates of evaporation which fades off during the rainy
            season.-
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/54-cleaning-the-beira-lake/beira-lake-project-pipes.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Beira Project Pipes</small>
              </p>
            </div>
          </div>
          <p>
            When Nahil agreed to take over the Beira project, the general
            discussion after many years of research was to raise the water level
            of the lake by aerating the water having water jets shooting up into
            the air hopefully taking down some algae.
          </p>
          <p>
            Nahil&rsquo;s simple solution was to flush the entire Beira by
            pumping in seawater in large quantities and displacing the dirty
            stagnant algae ridden water into the ocean, and bring it back to how
            it was before - a salt water lake.
          </p>
          <p>
            To counter any objections, they laid a pipe from a position south of
            Galle Face hotel, running up to the Gangarama temple. A steel
            structure was built on which submersible seawater pumps were mounted
            pumping 1000 tons an hour of seawater. Finally, the Beira was blue
            and the sea in and around the Colpetty area was green. The
            18&rsquo;&rsquo; diameter pipe ran adjacent to the railway line,
            Duplication road and the banks of the Beira.
          </p>
          <p>
            To get permission for the project he was referred to the National
            Building Research Organization (NBRO), headed by Mr. Godwin Vithane.
            Even though Godwin was a good friend he objected to it and was
            adamant that the saltwater would harm the steel foundations around
            the Beira. Nahil countered by saying that if that was so, all the
            structures along the coastal belt which had the usual 40mm concrete
            cover over the steel would have perished by now, but are not
            affected and the chances of this happening were very rare. As a last
            resort Godwin warned Nahil that the foundation of Temple Trees would
            also be affected. Nahil immediately measured the height of the
            intended water level and the foundation of Temple Trees, proving
            Temple Trees was much higher and will not be affected. In
            desperation, Godwin insisted the capillary action of the water could
            climb up and still affect the foundation. Finally, being a good
            buddy of Godwin&rsquo;s, Nahil told him &lsquo;&rsquo;Godwin please
            just cool it and let me get on with the job at hand.&rsquo;&rsquo;
            He finally relented.
          </p>
          <p>
            Nahil met the cash flow for the project which included donations
            from the Navam Mawatha building owners with the assurance from
            Mangala that he will arrange some compensation by the municipality
            to the Navam Mawatha building owners to compensate for their
            contributions.
          </p>
          <p>
            The pipeline adjacent to the railway line by the side of the
            Sasakawa building was piping with concrete collars joining at 12
            foot intervals, with manholes every 100 meters.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/54-cleaning-the-beira-lake/beira-lake-project.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Working on the Beira Project</small>
              </p>
            </div>
          </div>
          <p>
            They did not realise the concrete collars never settled down due to
            the frequent passage of trains, which they noticed only when they
            started the pumps. To their utter embarrassment, all the collars
            were spewing out water.
          </p>
          <p>
            It was then Nahil remembered the large pipes under the roads in
            Europe. Whenever they sprung a leak, they would send a rubber
            sleeve, sealing all the leaks, the concrete and the surrounding
            area, giving it all the support.
          </p>
          <p>
            He found a polythene bag manufacturer who made polythene rolls to
            any desired diameter. He turned out thick gauge polythene thereby
            sealing all these with polythene lining. The polythene itself was
            changed one manhole to another when they started the pumps getting
            the water to pull the ball at the front end of the line.
          </p>
          <p>
            In 2015 he was requested by Mr Gotabaya Rajapaksa to visit him at
            his home and explain to him how the Beira project was done. He took
            all the photographs he had of the project and used them to explain
            the workings of the project after which, Mr. Gotabaya wanted him to
            explain it to his staff who were assigned to carry out the
            project.The next morning three army brigadiers came to his office
            and Nahil explained to them the entire project.
          </p>
          <p>
            When Nahil started this project, the team was very nervous thinking
            that the fish in the Beira would die due to the seawater conversion.
            Therefore they were ready with the gear to clean up, in the event it
            happened. Thankfully, it did not. The heavy seawater was very
            gradually coming up without any turbulance thus the fish got used to
            two kinds of water. Studies carried out by various people who had
            researched the Beira Lake unanimously affirm that the pH balance of
            the Beira water has always been excellent. Part of the smaller fish
            from the sea had been flushed into the Beira with the seawater and
            was breeding in the lake. Strangely these little fish turned out to
            be quite large lake fish.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/54-cleaning-the-beira-lake/beira-lake-project-after-cleaning.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>The Beira Lake post cleanup</small>
              </p>
            </div>
          </div>
          <p>
            In 2015 he was requested by Mr Gotabaya Rajapaksa to visit him at
            his home and explain to him how the Beira project was done. He took
            all the photographs he had of the project and used them to explain
            the workings of the project after which, Mr. Gotabaya wanted him to
            explain it to his staff who were assigned to carry out the
            project.The next morning three army brigadiers came to his office
            and Nahil explained to them the entire project.
          </p>
          <p>
            After cleaning up the lake, Nahil handed over all the equipment to
            the department of Low Lying areas
          </p>
          <p>
            On the flipside, by doing this project he at least has a story to
            tell. He can proudly say, &ldquo;Hell yeah, I drained the Beira Lake
            that day...&rdquo;
          </p>
        </Col>
      </Row>
    </div>
  );
};
