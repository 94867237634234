import { Row, Col } from "antd";

export const TheBeginning = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="the-beginning">The Beginning</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            The year he was born, moreover, the day he was born, was significant
            in the history of the world. It was the day WWII officially ended.
            The allies had won and he came bawling into the world on the morning
            of 8th May 1945 – Victory in Europe Day – at the Rutnam Nursing Home
            in Colombo 02
          </p>
        </Col>
      </Row>
    </div>
  );
};
