import { Row, Col, Image } from "antd";

export const TuulaRippati = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="tuula-rippati">Tuula Rippati</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          {/* <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "300px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/07-tuula-rippati/nahil.jpeg"
            />
            <div style={{ textAlign: "center" }}>
              <p>
                <small>Young Nahil</small>
              </p>
            </div>
          </div> */}
          <p>
            One morning, climbing up the stairs with some groceries to Lakshman
            Umagiliya’s flat, he stopped dead in his tracks dazzled at the sight
            of a beautiful, busty blonde in a Cossack hat, making her way down.
            Gobsmacked, he left the groceries on the step to retrieve later to
            follow her. He just couldn’t get her off his mind. As fate would
            have it, there she was again a few days later. By now visibly
            smitten, he followed her to Lancaster Gate station, stood behind her
            in the queue and bought a ticket to her destination, Euston.
          </p>

          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/07-tuula-rippati/the-vw-variant.jpg"
            />
            <div style={{ textAlign: "center" }}>
              <p>
                <small>The VW Variant</small>
              </p>
            </div>
          </div>

          <p>
            Heading into the same carriage, he sat beside her, striking up a
            conversation and admitting that he had followed her there to talk to
            her. In Euston he lazed around untill she got back to the station
            and returned to Lancaster Gate with her, managing to get her name
            before she went up to the Marsh House Hotel next door.
          </p>
          <p>
            On inquiring, she had given her name as Petruska, but when he called
            the hotel, he was informed that there was no one by that name.
            Determined, he rang again and this time around, described her to the
            receptionist as a beautiful lass from Finland. What he heard next
            was music to his ears. “Oh yes, that must be Tuula Rippati.” Excited
            he called her room, confessing that he was the guy she met on the
            tube. Without wasting time on small talk over the phone, he invited
            her for a coffee. She reluctantly agreed and they had coffee that
            evening at the Finnish Club in Sussex Gardens. After the coffee,
            since it was Tuula’s first visit to London, excited, he took her
            sightseeing to Trafalgar Square, Piccadilly Circus, London Bridge,
            Westminster and other places of interest around the city in his VW
            Beetle, ending the evening by taking her dancing to a club in the
            vicinity, after which they spent the night together. In his opinion,
            this was a major romantic conquest for him.
          </p>

          <p>
            Their romance thrived. A few days later she confessed to him that he
            was the first non-white person she had ever been around, and by
            dating Nahil, she was ‘taking romance to a whole new level’. As
            things got emotionally serious between them, he helped to get her
            visa extended to stay in the UK as an ‘au pair,’ which allowed her
            to work at a school in Kent. A short time later they moved in
            together to 168, Holland Park Avenue in Notting Hill Gate. “Now I
            hope you are impressed by my chatterbility (sic) skills!”
          </p>
          <p>
            Tuula, who initially was on vacation in London for two weeks, ended
            up staying with him for three years. They probably would have got
            married, he says, if not for his father, who after being introduced
            to Tuula remarked that culturally it would not work out for them.
          </p>
          <p>
            During a recent chat with me about Tuula, he said he believed that
            if he had taken a stand and married Tuula, they probably would have
            still been together saving all three of his wives from heartache!
            “Que sera sera.’’
          </p>
        </Col>
      </Row>
    </div>
  );
};
