import { Row, Col } from "antd";

export const JothyGodage = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="jothy-godage">Jothy Godage</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Nahil was very familiar with this area since this was an ancestral
            property that had previously belonged to Jothy&rsquo;s grandmother.
            As students, Nahil along with his buddies, Jothy, Dushantha
            Abeysingha, Shantha De Silva, &lsquo;Wal&rsquo; Samarasinghe, and a
            few other friends from Royal College and St. Thomas College, made up
            a wild group of guys, who together loafed around this area with
            Jothy, as he collected five to ten rupees&rsquo; rent from his
            grandmother&rsquo;s tenants living on her land. They would use the
            cash to buy a bottle of booze, getting smashing drunk on those
            evenings!
          </p>
          <p>
            East West Properties was the owning company of the Peliyagoda
            development while the Bloemendhal project was different and
            amusing.&nbsp;&nbsp;They had a survey plan drawn that showed the
            canal at the back as one boundary of the land. They thought it was
            marshland with a canal running through it. However they realized
            after filling it that the boundaries had moved, thus gaining 20
            perches more. During the filling, the canal had moved when the
            bulldozers packed the soil into the existing canal, which was deep
            and nearly sank the bulldozers.
          </p>
          <p>
            After developing the land they sold the land to Suby Tea, who built
            it up, and their offices are there, to date.
          </p>
          <p>
            During all this excitement, Godage bailed out from the project
            lamenting that this was &lsquo;too stressful&rsquo; for him. Nahil
            decided to settle him off and worked out the figures taking into
            account the development cost and the investment made valuing the
            company three ways.
          </p>
          <p>
            Jothy was quite happy with the deal although he wasted all his
            money, clubbing every night and getting drunk, till he coughed some
            blood one evening, and was tested and declared positive for cancer.
            The guy was forty-five and partied hard like he was going to die
            (pun intended) He was out of control, spiraling downwards, worse
            than before the diagnosis.
          </p>
          <p>
            With his illness diagnosed as terminal, Jothy blew all his money and
            Nahil felt an intervention was in order. Jothy knew the rest of
            Nahil&rsquo;s family quite well and Nahil&rsquo;s elder sister Dr.
            Mahes and her hubby Dr.Kumar.&nbsp;
          </p>
          <p>
            They met for dinner, when Kumar said to him, &ldquo;I don&rsquo;t
            know about your bloody tests, but you don&rsquo;t look ill at
            all&rdquo; Both being doctors, they should know, they said
            &ldquo;Come to the UK and get another opinion.&rdquo; Jothy was
            broke and had no money even for the flight, which Nahil paid for. He
            got to London and got himself checked out; there was no cancer.
            Sadly it was a total misdiagnosis by his local oncologist. After all
            this, he bounced back strong and confident, and lived for a good
            twenty years or more, passing away about five years ago.
          </p>
          <p>
            Just a little thing about Jothy - since going broke he would borrow
            money from other mates, telling them that he would pay them back
            once &ldquo;Nahil settles what he owes me for my land,&rdquo; even
            though he had already been settled in full, over and above! His
            friends believed they would get their cash back and gave freely,
            though nothing was paid back. Whenever they complained to Nahil, he
            squared them up because Jothy was his friend, using company money to
            settle everyone, until he realised he couldn&rsquo;t go on doing
            this. Vishu, by now absolutely flustered, drew up an agreement to
            the effect that this was the &ldquo;final payment,&rdquo; Jothy
            assured and guaranteed that there was nothing more due to him, nor
            more claims to be made. Three months later he turns up again. Nahil
            says, &ldquo;Look here Jothy, you signed this waiver,&rdquo; to
            which he says, &ldquo;But Nahil if I didn&rsquo;t sign it, you
            wouldn&rsquo;t have given me the money that day.&rdquo; I guess all
            this&nbsp;&nbsp;is a part of being friends.
          </p>
          <p>
            In time Jothy met and married someone who took good care of him
            &nbsp;as he happily moved on with his life.
          </p>
        </Col>
      </Row>
    </div>
  );
};
