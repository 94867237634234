import { Row, Col, Image } from "antd";
import { ChapterFooter } from "../chapterFooter/chapterFooter";

export const PursuingAnEngineeringDegreeInTheUK = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="pursuing-an-engineering-degree-in-the-uk">
          Pursuing an Engineering Degree in the UK
        </h1>
      </div>
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/05-pursuing-an-engineering-degree-in-the-uk/walkers-sons-certificate.jpg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Walkers & Son Apprenticeship</small>
              </p>
            </div>
          </div>
          <p>
            During the period 1964-1967, in his third year at Walkers, he firmly
            believed the Walkers engineering qualification was world-class, with
            the bonus of working in engineering workshops and going on board
            ships, which he enjoyed.
          </p>
          <p>
            This was much-needed exposure for him personally since part of the
            training was ‘onboard’ ship work which was very interesting. The
            apprentice used this opportunity to do a little bit of side business
            – buying cigarettes and scotch from the sailors on board at
            duty-free prices and selling them outside, which was a regular
            thing. During this period, he says, “I would see my peers who had
            concluded their apprenticeship just ahead of me still cycling to
            their jobs. I thought, darn this is not going to work for me.” He
            short-circuited the four-year course, doing it in three years,
            deciding to further his engineering studies in England. His thought
            was, there was no way he was going to be biking it to the office on
            completion of his apprenticeship even though he owned a Vespa
            (scooter), a mini, and the Willies Aerolark, which he remembers
            riding and driving in sequence.
          </p>
          <p>
            Nahil made his way to the British High Commission, got hold of the
            Universities Central Council on Admissions (UCCA) books, found 15
            addresses, wrote all the addresses down, bought 15 aerogrammes and
            persuaded the Walkers Secretary to type out the same letter on all
            and mail them off.
          </p>
          <p>
            September was an amazing month for Nahil. He mailed the letters, on
            the 5th and by the 15th he received the first reply saying ‘you
            appear to be qualified – please send your official application
            through your High Commissioner in the UK.’ His friend Dayantha’s
            dad, Mr. Wilmot Liyanage, a very close friend of his father, was the
            Chief Accountant at Radio Ceylon during the time Livvy Wijemanne was
            Chairman. Since Mr. Liyanage knew many influential people in the
            Government, Nahil handed him the letter he had received from the
            College in the UK. With this letter, he got Nahil’s passage
            approved, exchange 45/- GBP a month and all the necessary
            documentation. By the 20th of September, he was in Leicester
            College, heading for his HND in Mechanical and Production
            Engineering studies.
          </p>

          <p>
            As he recalls his time in the UK, he continues about his maiden
            voyage to the West. Before he left for the UK, he was sharing a room
            on a side lane off Bullers Road, Colombo 07, with his buddy, Premlal
            Gunasekera who captained St. Thomas’ College in cricket with his
            claim to fame being that under his captaincy they won the
            Royal-Thomian encounter which was the last time St. Thomas’ won, in
            a long time. An annex in the same building was occupied by Ms. Enid
            Handunge, sister of the Olympic medal-winning boxer, a Trinitian,
            Leslie Handunge. She treated Premlal and Nahil like her sons. When
            he dropped in to say goodbye to her before leaving for the airport,
            being a great fan of Trinity College Kandy, she was shocked that
            Nahil was not wearing his college tie. She pulled off his tie and
            insisted he wore the college tie in her possession. He is glad he
            took her advice.
          </p>
          <p>
            Bags packed, he confidently set off. He was flying on a BOAC VC10
            aircraft. Arriving in London, just as he got to the arrival gate, a
            gentleman who was welcoming some foreign dignitaries, recognising
            the tie came up to him, introduced himself as Jayantha Dhanapala and
            shook his hand, He was an old Trinitian, working as an Attaché at
            the Ceylon High Commission, who later went on to be a high official
            at the UN. Aunty Enid was right. To date, Jayantha shares this
            little story with those around, whenever they meet.
          </p>
        </Col>
      </Row>
      <ChapterFooter title={"  Pursuing an Engineering Degree in the UK"} />
    </div>
  );
};
