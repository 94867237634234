import { Row, Col, Image } from "antd";

export const MeetingIndrani = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="meeting-indrani">Meeting Indrani</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Nahil’s father had heard from a friend that a family he knew in
            Negombo was looking for a groom for their daughter. He took the
            initiative and gave Mrs. Senanayake – a family friend – the job of
            setting up the venue for Nahil to meet the young lady and her
            parents. He knew there was no easy way out and agreed to see her. Of
            course, like any sensible youth, he first wanted a glimpse of this
            lady before he committed himself to meeting her in person. The stage
            was set one Wednesday morning at Mrs. Senanayake’s batik shop, down
            Dickman’s Road. The plan was for the prospective bride to visit the
            shop with her mother, while father and son walked around looking at
            batiks. Things went as planned. They walked in. The rest, as they
            say, is history...
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "500px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/11-meeting-indrani/nahil-indrani-capro-club.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "500px" }}>
              <p>
                <small>
                  Indrani and Nahil wedding at the Capri Club, Colombo 03 Ms.
                  Samarasinghe, Ajantha De Soysa, Nahil, Indrani, Sunil Perera
                  and Mastr. Samarasinghe
                </small>
              </p>
            </div>
          </div>
          <p>
            A few days later an official ‘face-to-face and meet the parents’ was
            set up at the Blue Lagoon Hotel in Negombo. Since his father was in
            Kandy, ‘aunty’ Senanayake accompanied him to the meeting.
          </p>
          <p>
            He had the pleasure of driving ‘aunty’ to Negombo that evening in
            his MGTF, which had the hardest suspension. The poor lady did not
            find it pleasurable at all as she was thrown around and bumped along
            to Negombo. Her return trip to Colombo was less traumatic, thanks to
            his intended father-in-law who made arrangements to send her back in
            an alternate vehicle.
          </p>
          <p>
            Hector De Silva was a landed proprietor and a coconut mill owner in
            Katana. Indrani was the only girl and the youngest in her family
            while her brothers, Ajith and Manik, were acquainted with Nahil
            since Manik studied at Trinity around the same time as Nahil.
          </p>
          <p>
            Once they got to the venue and the introductions were made, Nahil
            found Hector to be a great conversationalist. They got talking to
            each other while having drinks, losing track of the real reason he
            was there. He had not spoken one word to the girl since he arrived,
            until Hector said, “Why don’t you and Indrani have a look around
            this place?” They did just that – walking around the garden and
            getting to know each other. For the next few days, he drove every
            day to see her at her home on Ave Maria Road, Negombo, until their
            wedding.
          </p>
          <p>
            Ten days later, with the usual fanfare, they got married one sunny
            September day at the Capri Club in Kollupitiya. One distinct little
            thing he remembers of the wedding is the song ‘Kadalla’ sung by the
            wedding band. This was a song made popular by Mignonne Rutnam.
          </p>
          <p>
            At the time of his marriage, he was the 2nd Engineer of the ‘Lanka
            Rani’ owned by the Ceylon Shipping Corporation and was scheduled to
            leave on a voyage a few days after the wedding. If Indrani was to
            join him as captains and engineers could take their wives along, she
            needed her passport in her married name. Fortunately, a guest at the
            wedding who happened to be the Commissioner of Immigration at the
            time, gifted them her passport at the wedding.
          </p>
          <p>
            After their honeymoon at Bentota Beach Hotel, they set sail to the
            UK, going around Europe.
          </p>
          <p>
            On their return to Colombo after a few voyages, they moved into a
            flat on Gower Street, one of four owned by Indrani’s dad, Hector De
            Silva. Hector and wife Indira occupied one flat, while the other
            three were written to the three children. Indrani’s was occupied by
            Denham Alwis, who was a Director at State Plantations. He owned two
            cars, his official car and a mini minor, which were parked in an
            unauthorised extension built by him down a little lane at the back
            of the flats.
          </p>
          <p>
            He had always maintained to Hector that when Indrani got married he
            would move out, but three years had already gone by and he continued
            to stay. “Hector uncle himself was embarrassed since this flat was
            promised to Indrani – Not that I wanted to stay there in particular,
            as I had my accommodation. I told Hector, ‘I don’t think you’re
            going to make ‘project get Denham out’ successful, may I take the
            project over?” He gave Nahil the greenlight. After the go ahead, it
            was down to business the ‘Nahil’ way.
          </p>
          <p>
            To facilitate his haulage business, he had bought a few hefty low-
            loaders that carry D9 bulldozers. As part of the refurbishment of
            the flat that Indrani and he occupied, he had sent one of the cast
            iron bathtubs in the flat for enamelling at the East West yard at
            Peliyagoda. When it was time to transport the bathtub back to the
            flat after refurbishing, Nahil instructed the manager to send it to
            the Gower Street flat on one of their huge low-loaders. “This huge
            thing arrived with this little bathtub on it.” Since he could drive
            these heavy vehicles, he took over the driving seat with the bath
            tub still atop the low-loader and reversed the machine right down
            the little lane, at the end of which the extended garage was, and
            deliberately crashed into it. He remembers the driver and cleaner
            being in their element shouting, ‘Sir, Sir,’ assuming he had made a
            mistake. He thrashed it till the roof came down on the Mini Minor
            that belonged to Denham, who was not in at the time. Later Nahil
            apologised saying, “Sorry about the accident. Being an unauthorised
            extension, I understand you were taking it down anyway, weren’t
            you?”
          </p>
          <p>
            Nahil helped Denham take the shed down neatly, stacking all the
            material in the common area after which he dropped a note under
            Denham’s door apologising for the mishap requesting Denham to send
            his Mini Minor to the British Motor company for repair, and assuring
            him that the bill would be taken care of. The next day he got a call
            from Denham’s lawyers. The first statement being, “You know,
            harassing tenants... they can end up being the owners. That’s the
            law of the country.” Nahil replied, “No issue because they are
            paying only Rs. 500 rent, it’s as good as they own it.” After the
            opening line, they had wanted to know if the flat could be purchased
            by Denham. Nahil said, “Alright, here’s my price,” They agreed. The
            deal was done. According to Nahil, Once the money was paid, Indrani
            bought a lovely house on 20 perches in Dehiwala that belonged to
            Captain Ranjan Kumarasinghe, a guy with good design skills. He says,
            “It was a great investment.’’
          </p>
          <p>
            It was the time when the Colombo Dockyard was booming. Nahil left
            Shipping Corporation and joined Dockyard, working under one of the
            best Marine Engineers, Ananda Diyabalanage. They had done their
            Chief Engineer Certification together at Poplar College in East
            London. Working at the Dockyard was akin to resigning from the ship.
            Therefore he applied for study leave from the Shipping Corporation
            and left to London with Indrani in tow, to complete his Chief
            Engineer exam.
          </p>
          <p>
            While in the UK they lived at Hendrik Avenue off Nightingale Lane,
            where a petrol service station was located at the top of the lane.
            Whenever he met up with Indrani’s brother Manik, who had completed
            his exams in the UK and was on a quest for a part-time job, they
            would often discuss the prejudice and discrimination meted out
            towards most foreigners by prospective employers when looking for
            jobs. On the flip side, the jobs available at stores like Marks &
            Spencer and Sainsbury’s stuffing shelves, which were much easier to
            source, were also out of the equation. They would look at the
            applications and say, “You’re too qualified, once we train you,
            you’ll leave the job halfway through.” In these instances,
            qualifications went left field even when looking for an unskilled
            job.
          </p>
          <p>
            Fortunately, one evening, walking home past the petrol station which
            was manned by a bunch of young Greeks, on a whim, Nahil inquired
            from one of them about the possibility of a job vacancy there. The
            Greek had seemed relieved and said, “Yes, there will be seven
            vacancies from next week.”
          </p>
          <p>
            The guys were all going back to Greece and needed immediate
            replacement. Nahil met the owners whose only requirement was that he
            finds an entire team to man the shed. Nahil passed the word around
            and found six guys who were more than thrilled to join him. That was
            a great break for all of them. Taking this opportunity they also
            operated a car repair outlet in an abandoned warehouse behind the
            shed, taking on minor jobs like fixing brakes, clutch plates and
            other uncomplicated repairs.
          </p>
          <p>
            The petrol station was owned by two brothers, David and Peter
            Britain, who drove two brand-new Jaguars. Whenever they came around
            during the weekends to get their cars detailed by the team, the guys
            battled between them to clean the car because it was the only chance
            they had of getting their hands on a fancy machine. He determines
            that as students, even cleaning the carpets in a Jaguar was a
            privilege for them.
          </p>
          <p>
            It was the day of the annual fire inspection at the petrol station,
            an exercise where the Fire Department checks it for any risks and
            offers basic training. “They said, ‘Right, guys, what steps would
            you take in case of a fire?’ Nahil said, ‘Large ones, two at a
            time.’” He remembers that vividly. It went down so well with the
            fire crew that they all started laughing. That was the end of that
            safety check. He believes this petrol station is still manned by Sri
            Lankans.
          </p>
          <p>
            While on study leave in the UK to facilitate his Chief Engineer
            certification, besides the three to four months of leave, a monthly
            allowance was paid to the students, which Nahil did not collect.
            Thankfully he had some savings which tided the couple over the
            period. Once he finished the exam, with his certificate in hand, he
            met David Soysa, the Shipping Corporation’s representative in London
            who looked after all the studies and payments, etc. This was just a
            created job with perks to enable David to hang around in London.
            Nahil had not been paid his allowance because he had not responded
            to the many messages David had sent him inquiring about his
            progress, or sent his progress reports, resulting in not being paid
            for three months.
          </p>
          <p>
            Once he had finished his exam he requested David to settle the
            three-month backlog of his allowance. Nahil then informed David that
            before the exam, he had no way of checking his progress, after which
            Nahil showed him his certificate saying, “I passed, so I must have
            progressed.” David seemed to enjoy the little twist in the way Nahil
            worded his request and paid him immediately.
          </p>
        </Col>
      </Row>
    </div>
  );
};
