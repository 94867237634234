import { Row, Col } from "antd";

export const TheIBMAgency = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="the-ibm-agency">The IBM Agency</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Regressing to the Indian gentleman, Harish Vohra &ndash; the then
            Head of IBM Sri Lanka, who had leased out the ground and first floor
            of the East West-owned IBM building at Navam Mawatha and
            Nahil&rsquo;s house &ndash; over a short period of time became a
            great friend of Nahil&rsquo;s. In fact he considered Nahil his best
            friend.
          </p>
          <p>
            One day he says, &ldquo;Nahil, you know, we are in the throes of
            outsourcing our PC business to companies like Hayleys and others
            interested in Information Technology.&rdquo; Nahil played it down
            showing no interest. Then Vohra suddenly mentioned that a Mr.
            Cunningham, from IBM, was due in Colombo the following week to carry
            out interviews for the awarding of the IBM agency and requested
            Nahil to make a bid for it.
          </p>
          <p>
            Just after this conversation with Vohra, Nahil was stepping out of
            the office when he ran into Peter D&rsquo;Almeida. Nahil asked him
            if he knew any good software people. He informed Nahil that he knew
            Ajith Ayer, an excellent software guy who was a good buddy of his.
            Nahil contacted Ajith, briefed him and requested him to make a
            comprehensive and convincing presentation to Cunningham, to help
            East West win the IBM agency.
          </p>
          <p>
            Nahil thinks it was a foregone conclusion in the local business
            scene that EW would be awarded the agency. It was perceived by the
            competition that this was all eyewash by Cunningham, going through
            the motions of interviews and perusing the proposals from other
            companies seemingly doing what was &lsquo;politically correct&rsquo;
            in the circumstances.
          </p>
          <p>
            Finally, Cunningham arrived in Colombo. At the meeting, a slide
            presentation was made showing how Ajith&rsquo;s software would drive
            up hardware sales. Ajith, though not employed by EW, was brought in
            just for this special purpose.
          </p>
          <p>
            EW was awarded the agency which culminated in a celebratory dinner
            that evening. At dinner, Cunningham tells Ajith, &ldquo;The
            presentation was excellent but you can never get it right using your
            software, it will only create enemies. Please use our existing
            software.&rdquo; Point taken.
          </p>
          <p>
            The agency came under the purview of East-West Information Systems,
            now E-Wis, which Nahil later sold to Sanjeeva Wickremanayake.
          </p>
          <p>
            From that point on, their computer business was on an upswing. EWIS
            launched off with an office on 13th Lane, distributing IBM machines.
            At the time they had something called IBM compatibles. With everyone
            eager to own a PC, MS-dos and PC-dos, they manufactured the PC-dos
            for their clientele, selling all this through a new company they
            launched named &lsquo;Computer Land,&rsquo; a subsidiary of EWIS, to
            handle the compatibles made in Korea, leaving the import of high-end
            IBM machines to East West Information Systems. Selling the
            compatibles through Computer Land helped them capture a substantial
            part of the local IT market.
          </p>

          <p>
            The multi-story carpark at the IBM building has a bit of history.
            While the World Trade Centre was under construction, the owning
            company, Overseas Realty, was looking for prestigious clients like
            IBM to benchmark their tenancy. Nahil had an inclination that IBM
            was being tapped by Overseas Realty as a potential tenant and
            decided to go one better by offering IBM unlimited parking along
            with a valet parking service. This advantage, to his knowledge, was
            not a possibility at the WTC.
          </p>
          <p>
            As promised he delivered in three months, initially giving them a
            multi-story carpark beside the IBM building on 15 perches with a car
            lift where the cars were valet parked, and a further 60 perches of
            parking right in the middle of Navam Mawatha. This land, owned by
            the Selvanayagam&rsquo;s of Carsons, looked like a rubbish tip and
            really disgraceful. He requested them to develop it or sell it to
            him. They went for the latter option, hence the car park. With the
            purchase of the land, there was plenty of parking. Right after that,
            he converted the multi-story carpark into office spaces, exchanging
            the car lift for two regular passenger lifts and installed the car
            lift for the conveyance of cargo at his multi-storey warehouse in
            Peliyagoda.
          </p>
          <p>
            In the early days, just for a lark, Nahil and Jothy would visit
            Customs auctions together, picking out random stuff at very low
            prices. Nahil says he learned something from these auctions. As an
            example, if a car is ceased or impounded, a few days before the
            auction, the Customs guys remove an engine or some other vital part
            from the vehicle and leaves it in a warehouse, to be sold
            separately, while the car with a part missing is also placed in the
            same warehouse. There is an established pack of traders who are
            present at every auction. Whoever is bidding on the car knows it is
            minus an engine or whatever part that&rsquo;s taken off, thus they
            bid for the vehicle and the missing part separately. The warehouse
            is usually open two days before the auction to the public for
            inspection of the items. Finally, the buyer gets a partless car at a
            very low price, and the missing part at a pittance, which is
            eventually assembled and viola! There is a fully functioning car. It
            was then that Nahil realized that if the need arose, the system
            could be manipulated in a million ways. This was no doubt a learning
            experience for him.
          </p>
        </Col>
      </Row>
    </div>
  );
};
