import { Row, Col, Image } from "antd";

export const SinglePointMooringBuoyColomboPort = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="single-point-mooring-buoy-colombo-port">
          Single Point Mooring Buoy - Colombo Port
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            While operating the tugs ‘MT Sigiri’ and ‘MT Nilgiri’ through Off
            Shore Marine Services – an East West subsidiary, – a Single Point
            Mooring Buoy or SPMB was installed at the Colombo Port, off shore. A
            SPMB consists of a buoy that is permanently moored to the seabed by
            utilising multiple mooring lines/anchors/chains allowing cargo
            transfer of liquid petroleum products. An SPM contains a bearing
            system that allows a part of it to rotate around the moored
            geostatic subsea manifold connections and weathervaning tankers.
            SPMs are capable of handling ships of any tonnage, including the
            very large crude oil carriers, when no alternative facility is
            available. In shallow water, SPMs are used to load and unload crude
            oil and refined products from onshore and offshore oilfields or
            refineries, usually through some kind of storage facility. These
            buoys are usually suitable for use by all types of oil tanker, and
            the moorings usually supply to dedicated tankers which can moor
            without assistance.
          </p>
          <p>
            Whenever a tanker brings crude oil into Colombo, she is anchored off
            the edge of the port and a flexible hose goes down connecting to one
            of the stationary pipes on the peer. In order to secure the ship
            from drifting away, or the connected hose being damaged during bad
            weather, a tug is in attendance to keep the ship in place. Ever
            since the SPBM was installed by the Hong Kong-based Swire Group –
            owners of Cathay Pacific Airways, a tug on a renewable charter was
            also provided by them. This was a lucrative business that was
            hijacked by the Swire Group, thereby every tender forwarded by the
            Petroleum Corporation carried the exact specifications of the Swire
            Group’s tug on charter, thus stalling everyone else from getting the
            business. This tug was painted red.
          </p>
          <p>
            When Nahil realised that local service providers were being
            sidelined by the contractor, with their focus on the Swire Group
            tug, he advised those from Petroleum Corp involved in this project,
            since they were so specific, to include the Swire Group name by
            painting it on the back of the tug.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/19-single-point-mooring-buoy-colombo-port/single-point-mooring-buoy.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Single Point Mooring Buoy</small>
              </p>
            </div>
          </div>
          <p>
            Off Shore Marine Services had a 150 horsepower tug. However the
            requirement of the contractor, Petroleum Corporation and the Ports
            Authority was 200 horsepower. Since its bow was under capacity, Off
            Shore Marine Services gave a guarantee to the contractor indicating
            it would upgrade the bow thruster to the required capacity. Lester
            was in Germany buying the 200 horse power bow thruster when the
            Iran/Iraq war broke out. Unfortunately, due to this unpredicted
            incident, he couldn’t ship it immediately because of a new ruling, a
            48-hour cooling period for all cargo, in case they were explosives.
            With a confirmed deadline set for installation and handing over, the
            only viable solution was to ship it as personal baggage, which
            Lester did, using his credit card. Thankfully the 48-hour rule did
            not apply to his 1.5 tonnes of personal baggage. This tugger was
            three tonnes. Nahil modified a Massey Ferguson tractor and mounted
            it on the tug, so the wheel could pull the rope. They won the
            contract for two years. Nahil says of the Ports Authority people,
            “They were always appreciative of real effort like this and
            supported us 100%.”
          </p>
          <p>
            It was now the early 1980s, while his business boomed, his personal
            life was in shambles. The late nights and almost every weekend spent
            building his empire was taking a toll on his marriage. Although a
            great provider, he was ‘never home,’ according to Indrani. She was
            right. No amount of excuses could get him out of this situation. He
            emphasises that the concept of ‘happy wife, happy life’ was never
            for him. Maybe the fact that he is a stickler for detail contributes
            to his inability to find any person, man or woman, who meets his
            exceptional standards long-term. He tells me that he is a “hands-on
            person and a perfectionist”. In his youth he figured out that
            delegating never works for him, making him seem obnoxious. “Who
            cares? Isn’t getting the job done of utmost importance? I am a 100%
            results-oriented person. In my pyramid of life at the acme comes
            work, with everything else trailing behind in whatever order.”
          </p>
          <p>
            Nahil was in Singapore in the Cross World Navigation office of
            Captain Charles Gnanakone, on the 20th floor off Robina House, when
            Lester called him saying, “Nahil, I have something terrible to tell
            you.” “My first thought was someone had died. Lester said ‘Indrani
            has left you’. In absolute relief, I said, ‘Is that all?” Nahil was
            relieved because he was expecting to hear about a death in the
            family or some other disaster. Lester added: “Anyway, don’t do
            anything rash,” to which he replied, “I’m with Charlie now, the
            window is open, and I am about to jump off.” His little joke cut
            through the seriousness of the conversation and they laughed it off.
          </p>
          <p>
            Nahil got back to Colombo once his work was done to find out that
            Vajira, who was around 18 months at the time, had been taken to
            Nuwara Eliya by Indrani in an ‘Eveready’ van and was living there in
            a guest house.
          </p>
          <p>
            Nahil borrowed Lester’s car, and hastened to Nuwara Eliya
            accompanied by Maggie, baby Vajira’s nanny, with details of their
            whereabouts, first dropping in to see his father in Kandy to keep
            him informed, after which he proceeded to Nuwara Eliya. Once he got
            there he did a stakeout for the ‘Eveready’ van and found it parked
            opposite the Priory Guest House on High Street. He went in picked up
            his son and headed straight to the Police station, where he gave
            them a statement to the effect and brought Vajira back to Colombo.
          </p>
          <p>
            The initial discord between them was because of something insulting
            she said to Nahil, after which he left their marital home and took
            refuge at No.36, Siripa Road, the home of his late friend, Ana
            Malalgoda, who was a close buddy.
          </p>
          <p>
            A few days later he was advised by his sisters that he should let
            his wife have custody of the boy for various reasons, one being his
            tender age, which he says “made sense” at the time, therefore
            unfortunately, he gave Indrani full legal and physical custody of
            Vajira.
          </p>
          <p>
            By April of that year, Indrani and Nahil separated, with Indrani and
            Vajira living in her recently-acquired house in Dehiwala. Finally,
            they divorced in 1982 and she remarried.
          </p>
          <p>
            Later on, after the brouhaha had settled, he moved to an annex, a
            garage extension down Havelock Road, owned by Rienzi Perera.
            Initially, Nahil had access to Vajira whenever he wanted to visit
            him. However, subsequently his visits were restricted and he could
            see Vajira only at Indrani’s home. Even taking Vajira to the beach
            was not a possibility.
          </p>
        </Col>
      </Row>
    </div>
  );
};
