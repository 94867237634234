import { Col, Image, Row } from "antd";

export const Preface = () => {
  return (
    <div style={{ padding: "10px" }}>
      <Row>
        <Col xs={0} sm={0} md={24}>
          <div style={{ textAlign: "center" }}>
            <h1 id="preface">Preface</h1>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <div
            style={{
              margin: "10px",
              textAlign: "center",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                " https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/simply-nahil-cover-page.jpg"
              }
            />
            <div style={{ textAlign: "center" }}>
              <p>
                <strong>
                  <small>Simply Nahil</small>
                </strong>
              </p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={0} md={0}>
          <div style={{ textAlign: "center" }}>
            <h1 id="preface">Preface</h1>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <p>
            2021. The years have rushed by. Right now the world is a mess with
            COVID - 19 rampant and not slowing down. The kids have ‘flown the
            coop’while Nahil, an ‘empty nester,’lives alone in his bachelor’s
            pad at Crescat Residencies. It’s been seven years since Vajira
            married Tharushi, five years since Vijitha and Chantal married and
            migrated to settle down in Boston. While Chantal works on her MBA at
            Harvard, Vijitha is still contemplating his career path. In the mix,
            Anika, his only girl and the youngest, married Rehaan, a budding
            young politician, four years ago, gifting Nahil with two grand sons.
          </p>
          <p>
            As we sit sipping an excellent cup of coffee rustled up by his
            elderly housekeeper Tikiri Manike on the 24th floor of the
            condominium he calls home, the lights of Colombo city flickering
            around us set up an awesome ambiance. What strikes me most in my
            deep-ended curiosity of this unassuming, kind and humble man is his
            analysis of life. He is able to perceive the simple looking through
            the lens of life that shows up macro cosmic. His analysis of
            contemporary concerns and larger-than-life situations has never
            seemed to faze him, given his brilliant mind. He recalls, with a
            chuckle, that even as a child he saw the world in technicolour. For
            him, the blacks and whites of reality were juxtaposed with the
            richness and vibrancy of the reds, yellows and blues of his
            imagination.
          </p>
        </Col>
      </Row>
    </div>
  );
};
