import { Row, Col } from "antd";

export const NahilAndAvril = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="nahil-and-avril">Nahil and Avril</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            It was July 1980. Dan who had recently joined East West, was in the
            throes of setting up East West Clearing and Forwarding. After a
            night out one Friday evening, he had by chance met up with a young
            lady at a private function held at the Taj Samudra Hotel. So taken
            up by her, he mentioned to Nahil the next day that he had met this
            amazing girl who worked as a trainee at a foreign mission and was
            keen for Nahil to meet her. By now Nahil had separated from his
            first wife Indrani and was living in Rienzi Perera’s garage
            extension off Havelock Road, while his son Vajira, now three years
            old, lived with his mother, occasionally spending a few days with
            him. “The following Monday, Dan tells me that he is trying to get
            this girl to come for an interview as his secretary!” He was quite
            intrigued and reluctantly admits, a tad excited to meet her. “After
            all the anticipation she turned up and boy oh boy, I was really
            impressed. My initial thought was, ‘Hey now it’s obvious why Dan was
            so persistent about hiring her as his secretary.’ This young girl
            was Avril, who became Nahil’s second wife. “I interviewed her; the
            interview was a total disaster, her shorthand was bad and typing
            worse!” Even so, he hired her on the spot and her job at East West
            Clearing & Forwarding commenced the following Monday.
          </p>
          <p>
            The weekend before taking up this new appointment was the Bradby
            weekend, the second leg of the Bradby. Nahil was at Nittawela for
            the match, after which he attended the Bradby dance at the Queens
            Hotel, only to bump into Avril; she was at the match and the dance
            with a group of young friends. “As soon as I set eyes on her, I was
            most curious to know who she was dancing with, making it a point to
            say ‘hello’ to her, while my eyes roamed around her group, trying to
            get some insight,” says Nahil. Avril introduced Nahil to her buddies
            as her new boss, after which he introduced her to his sister Kanthi
            as his new employee.
          </p>
          <p>
            Avril started work at the East West Clearing & Forwarding office on
            the fifth floor of Ceylinco House, as a part of a staff of three,
            plus Dan the boss. The following weekend, Dan arranged a night out
            at the Blue Leopard Night Club, the most happening club at the time,
            to celebrate the launch of EWCF. It was a party of four, comprising
            Dan, Sharma (a close friend of Avril’s), Nahil and Avril. As the
            night proceeded Nahil and Avril danced only with each other. The
            rest is history. After a whirlwind romance, she moved in with him,
            later on, they got married once his divorce from Indrani, his first
            wife, was finalized. On the advice of Minoli, Lester’s then-wife,
            Avril resigned from EWCF, three weeks into her job, thus ditching
            Dan who was out of Colombo on work during that weekend, only to get
            back on Monday and find out he was without a secretary.
          </p>
          <p>
            Dan’s operation that started with two wharf clerks and a secretary –
            an ungrateful girl who bailed out on him after two weeks on the job
            – operated with over 200 wharf clerks from an office at the Cargills
            building in Fort five years later.
          </p>
          <p>
            While their business East West Enterprises was at the peak, Lester
            with his expertise was excellent in shipping. Brian Ratnayake, who
            was in the Baltic Exchange, was the EW representative and go-to guy
            in the UK for ship brokering while charters were managed by Lester.
            In the first year of their operation, all Shipping Corporation
            charter requirements were met by Lester and Brian, generating a
            solid income for Sri Lanka Shipping. However, even though Brian was
            a great shipbroker, sadly, he had zero business comprehension.
            Lloyds List London, a shipping-related newspaper, featured Aitken
            Spence Shipping in their supplement on Sri Lanka. Brian, who managed
            Sri Lanka Shipping Co., in the UK, posted an advertisement in this
            newspaper pertaining to ship brokering.
          </p>
          <p>
            Starting with ‘Our agents in Sri Lanka, East West Enterprises,
            handling ship brokering, etc.,’ to their utter chagrin, it ended
            with “We also sell Sri Lankan curry powder” at the bottom of the
            advertisement. Of course, he heard an earful of profanity from
            Nahil, while Lester, always the diplomat, was speechless.
          </p>
          <p>
            Brian’s excuse was Indrani, Nahil’s wife was exporting tinned curry
            powder to Brian’s wife Gladys to be sold mostly among the Sri Lankan
            expats in the UK and he had assumed that advertising the ‘curry
            powder’ in a shipping newspaper was a good idea at the time. Nahil
            told Brian he was a Peterite with amazing business acumen. “Brian is
            one of those guys who would tell a story with a punch-line that
            takes forever. His jokes are no laughing matter,” says Nahil.
          </p>
        </Col>
      </Row>
    </div>
  );
};
