import { Row, Col } from "antd";

export const LankaMarineServicesNahilTakesOnJohnKeells = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="lanka-marine-services-nahil-takes-on-john-keells">
          Lanka Marine Services – Nahil Takes On JKH
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            LMS was a Government-owned monopoly selling oil to ships with a
            staff of 170 plus people. Their main asset was a 35,000-tonne
            petroleum storage unit situated in Bloemendhal Road with a pipeline
            running from the storage facility to the port to distribute the oil,
            fill the tanks, get back and pump it to the ships. The privatisation
            of LMS was spearheaded by PERC &ndash; Public Enterprise Reform
            Commission &ndash; under the purview of P.B. Jayasundera.
          </p>
          <p>
            Nahil attended a &lsquo;pre-bid&rsquo; buyers meeting consisting of
            a few foreign companies and four local companies, bidding for LMS.
            The floor price was one billion rupees. Nahil always messed these
            meetings up by saying things like, &ldquo;When this is privatized
            the monopoly that you currently sustain will be diluted by other
            companies selling bunker services. Therefore the only items you are
            selling off are the tanks on Bloemendhal Road and the pipeline sans
            the monopoly. Who in their right mind will bid on and pay a floor
            price of one billion rupees for a rusty tank?&rdquo;
          </p>
          <p>
            It was the year 2002, and a billion rupees was a huge amount of
            money. Nahil&rsquo;s point drove home, compelling all the other
            bidders to give up, realizing they could dock a similar size thing
            offshore, bunkering and selling oil to ships in and out of the port.
            The one billion was PERC&rsquo;s idea of how much the thing was
            worth. Most importantly there was a draft agreement given to all the
            bidders, a document that the winner would have to sign so that each
            bidder would be aware of what they were getting into.
          </p>
          <p>
            This was a four-way agreement &ndash; the Government, Petroleum,
            Ports Authority and the winning bid. John Keells bid one billion and
            paid one billion winning the bid.
          </p>
          <p>
            As he had formerly deliberated at the meetings, that someone could
            easily operate a tanker, Nahil operated a tanker that was larger
            than the entire fleet of tanks in the port &ndash; a 37,000-ton
            &lsquo;stunner&rsquo; &ndash; previously the Emerald, now re-named
            Bunker 1. He bought it from Singapore, with a Russian crew on board
            and took&nbsp;&nbsp;delivery of it in Fujairah.
          </p>
          <p>
            Remember how Nahil had stated that someone could bust the monopoly?
            Here he was, &lsquo;walking the talk!&rsquo; Just after he bought
            and took delivery &nbsp;&nbsp;of the ship, he had an agreement with
            a Maldivian, Ahamed Manikku of Universal Enterprises, and Kenneth
            Pendegrast, his local partner, who promised to buy the oil and pump
            it on board the ship so that it sailed into Colombo Port with a
            cargo of oil. They were dilly-dallying while Nahil was losing money
            &ndash; delays in this business cost big money...
          </p>
          <p>
            By then he was running low on cash and approached the Bank of Ceylon
            management to negotiate a loan, with the affirmation that it would
            be paid back once he sold the oil. The bank officials handling his
            request were seeking some kind of collateral from Nahil; just then
            Sumi Munasinghe met him in the corridor and inquired as to what he
            was doing there. He gave her a brief account of his situation with
            the ship and the delays. She says, &ldquo;Wait a minute&rdquo; and
            heads off to speak to the officers. Sumi was the Chairman. She
            arranged four million dollars with no collateral, which he paid
            back, once again in record time.
          </p>
          <p>
            He started the bunker operation, steadily picking up his market
            share off John Keells, with two smaller bunkers also operating
            inside the port. As he happily motored on, he got a letter from the
            Ports Authority, signed by Parakrama Dissanayake, well known for
            heading the container scene in Sri Lanka and also the head of the
            port &ndash; informing him that according to the agreement, all
            bunkering has to go through the common user facility &ndash; the
            line from the quay to Bloemendhal. Nahil was in a slight conundrum
            wondering how he could sell through this medium, which would never
            happen. He produced a copy of the draft agreement he was given by
            LMS at the last potential buyers&rsquo; meeting and questioned as to
            where on the agreement was such a clause found and requested for a
            copy. It transpired that John Keells, had supposedly signed the
            draft agreement that had this clause, though none of the others who
            received the draft agreement, including Nahil, found a clause to the
            effect in their copies. John Keells had inserted a paragraph into
            the original letter. He managed to obtain a copy of the agreement
            that JK had signed from the Parliament Library. The copy that all
            the bidders had, proved what JK had signed was different from
            theirs.
          </p>
          <p>
            Nahil promptly took both agreements to De Sarams, the lawyers, and
            filed a case against John Keells for fraudulently inserting the
            clause in question. The JK anchor lawyer was Romesh De Silva, who
            given a chance would drag a case on for ages, maybe till kingdom
            comes. These delays well suited John Keells as most of the Directors
            were not shareholders and thus were not affected. With the case
            dragging on, Nahil had to pay the Russian crew for doing sweet
            nothing, still unable to sell his quantity of oil. As per the letter
            from the Ports Authority, the oil owned by him had to go through the
            common user facility which Nahil thought was crazy. He was out on a
            limb looking for a solution. On a whim, he checked the scrap price
            of tankers. He had bought Bunker 1 at its scrap price because it was
            a lovely machine though not a double hull. Generally, to operate in
            the USA a double hull is needed, the lack of which is why he got
            Bunker 1 so cheap. He believes a double hull gives extra protection.
            The scrap price had leaped up and just then he got an offer from
            Bangladesh.
          </p>
          <p>
            Bunker 1 was hanging around doing nothing for around three months
            with 6,000 tonnes of oil in store and the Bangladeshis wanted the
            tank free of oil since the oil hinders the scrapping process that
            becomes a major issue. Looking for a solution he called Susantha
            Ratnayake - the President of John Keells at the
            time.&nbsp;&nbsp;Nahil said to him &lsquo;&lsquo;Susantha why
            don&rsquo;t you buy my oil and I&rsquo;ll drop the
            case.&rsquo;&rsquo;Amazingly everything happened speedily from
            thereon. The oil was bought, the machine sold and the case was
            withdrawn. It was a cakewalk. &nbsp;
          </p>
          <p>
            As Nahil&rsquo;s friend Anthony (Baba) Page would say, &ldquo;A bad
            settlement is better than a good judgment. Hanging around in courts
            is a waste of time.&rdquo; This was an open-and-shut case where
            something was obtained after furnishing fraudulent documents. Once
            Nahil closed his case against JK, he handed over the documents to a
            prominent lawyer. Digging deep, his team found other discrepancies
            besides the fraud in the deal, and JK met its LMS Waterloo.
          </p>
          <p>
            John Keells had to return Lanka Marine Services to the Government.
            One billion rupees down the bilges. That&rsquo;s why Ajith
            Gunawardene and John Keells love Nahil!
          </p>
        </Col>
      </Row>
    </div>
  );
};
