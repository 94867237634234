import { Row, Col } from "antd";

export const TheKirulaRoadYard = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="the-kirula-road-yard-now-asiri-medical">
          The Kirula Road Yard - Now Asiri Medical
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            After developing and selling the land at Bloemendhal Road, they were
            in desperate need of a yard to park their heavy vehicles. As they
            looked at a map of Colombo, hypothetically if the land was priced at
            Rs. 5,000 a perch in Colombo 7, as you come out radially towards
            Colombo 5, in the Jawatte Road area, there is a marked drop in price
            though the two areas are almost physically adjacent. After driving
            around the area, a large lot on Kirula Road proved satisfactory.
          </p>
          <p>
            This large lot was owned by Tudawe Brothers, but unfortunately it
            was encroached by many shanties. Old Mr. Tudawe averred, &ldquo;I
            will sell this lot to you but I doubt the shanty folk will ever
            leave. I have been negotiating with them for the past 10 years to no
            avail.&rdquo; By now an expert in shanty clearance, Nahil took up
            the challenge declaring, &ldquo;Let me try. The second time around
            should be peanuts!&rdquo; Immediately on acquiring the land, Nahil
            summoned a meeting with all the tenants and offered cash to all the
            tenants to move. They were all in agreement of moving away, though
            the majority voiced their fear of being left bereft of their dues.
          </p>
          <p>
            This was a catch 22 situation. Nahil&rsquo;s question was what if he
            paid them and they didn&rsquo;t leave. A quick decision had to be
            taken so he got hold of the &lsquo;Godfather&rsquo; of the lot, Mr.
            Mahathung, who owned the illicit brewery setup at the rear end of
            the land. Trying to keep it simple, Nahil said to Mahathung,
            &ldquo;If all of you agree to leave, we will pay you&rdquo; &ndash;
            explaining to him the conditions of payment. He was happy and
            persuaded Nahil to have one drink in celebration &ndash;moonlight
            hooch. He found himself gradually getting high on this rubbish, made
            his excuses and left on a positive note. Lester was out of Colombo
            when Nahil executed the transaction with these folk. He assured
            Lester that he would clear the tenants in three months, but managed
            to do so in one month. Finally, the entire &lsquo;wattha&rsquo;
            left, with Nahil making sure each family found places to re-locate
            to, and also helped to transport their bits and pieces.
          </p>
          <p>
            He immediately got down one of their bulldozers and started filling
            the dip at the rear end of the land with truckloads of soil, with
            the dozer fortuitously knocking down fences in the compound, the
            little demarcations made by the occupants.
          </p>
          <p>
            The occupants&rsquo; kids had fun with the dozer, riding on it and
            generally rioting. He turned a blind eye to all this since he had
            achieved his objective. Once everyone was relocated, they developed
            the land and made it their haulage yard.
          </p>
          <p>
            Just two years later they developed the Peliyagoda property and
            moved the haulage yard to that location. Peliyagoda was more
            convenient, being close to the Colombo Port.
          </p>
          <p>
            Once the yard was moved away from Kirula Road, the owners of Asiri
            Hospitals approached Nahil with a proposal to purchase the lot for
            the construction of a hospital, offering EWH shares in the hospital
            as an alternative to cash, citing the doctors in his family. Just to
            get some kind of direction before he made a decision, Nahil phoned
            his sisters, who let him know that they would not be settling in Sri
            Lanka. He opted for cash instead of shares which, if he had
            accepted, would have made him a substantial shareholder of Asiri
            Hospitals.
          </p>
          <p>
            While the Maduru Oya freight was being transported from the port to
            the site, EWH was among the freight forwarders involved in the
            haulage of goods belonging to these contactors whom they worked with
            direct, avoiding involvement with subcontractors. One afternoon, the
            foreign contractors gathered at the port on a day the port was
            extremely busy, a buzz of activity going on with import of cranes,
            and other machinery, whilst nothing was branded or labeled. Nahil
            informed them that he was expecting a shipment on a particular day
            and requested all their equipment to be mustered in their facility
            to enable the efficient transport of the goods, once the ship
            arrived. He had some machines in his lot which did not belong to
            EWH, though at a glance, it gave the impression to his foreign
            visitors that this huge fleet of machines belonged to them. This
            showcased their competition, as physically depleted of goods.
            &ldquo;Cheeky stuff,&rdquo; says Nahil.
          </p>

          <p>
            It was the final day of the Royal/Thomian encounter. Roshanne and
            Nahil were on their way home around 11 p.m., after dinner at the
            home of Priath and Christine Fernando. Nahil was driving a
            Mitsubishi Lancer wagon as they made their way home from Nawala to
            Flower Road. Stopping at a red light at Horton Place, he noticed a
            car pulling up alongside, with two young men revving their car
            engine, looking towards him.
          </p>
          <p>
            Nahil immediately figured they were up for a race. Taking up the
            challenge, he shot off as the lights changed colour. As both cars
            approached the Alexandra Place roundabout, the other car cut into
            Nahil expecting to send him over the roundabout, when Nahil
            realizing what they were trying to do, intentionally whacked the
            right hand side of the other car. The car spun around twice and
            stopped 15-20feet in front of Nahil&rsquo;s car.
          </p>
          <p>
            By now Roshanne was hysterical. Two well-built young men got off
            their now wrecked car and came towards them, got hold of the front
            of the Lancer and started lifting the Lancer wagon up and down.
            Trying to avoid confrontation at any cost, Nahil engaged his vehicle
            into first gear and slowly nudged the car towards their knees after
            which they backed down and took off. When Nahil drove to the
            Colpetty Police Station to make an entry, they requested him to make
            an entry at the Cinnamon Gardens Police Station since the accident
            took place under their jurisdiction. The next morning, he sent
            someone to the Cinnamon Gardens Police station and found out the two
            lads were the sons of Atu Dadlani. Nahil called upon his former
            neighbour and friend, Mohan Lalvani, who in turn spoke to Atu.
            Finally, both parties decided to undertake to repair their vehicles
            and things were settled amicably.
          </p>

          <p>
            At the height of the JVP insurgency, Nahil received a telephone call
            from the JVP demanding 100,000 rupees with a threat that failure to
            pay would result in a bomb in front of the IBM building. The man on
            the phone told him that he should take this very seriously, and
            continued to give instructions to leave the cash atop a dustbin
            opposite his home and stay indoors for four hours; with the usual
            &ldquo;don&rsquo;t talk about it to anyone, if you do...&rdquo; He
            followed the instructions and the cash vanished. As instructed,
            Nahil stayed indoors for four hours aware that they were well known
            to hit the people they extorted money from, and eventually left for
            Singapore later on in the evening with the kids.
          </p>
          <p>
            While in Singapore he formed a company named &lsquo;SunLow&rsquo;,
            with Saumya, Sanjeewa and Victor Lo suppliers of all the accessories
            for the IBM building. After spending a few weeks in Singapore, he
            returned to Colombo. Once he returned he had figured out that the
            JVP would once again try to hornswoggle him. Anticipating a
            re-occurrence, he wired his residence telephone system and borrowed
            a gun from Deva Corea, who was in charge of the President&rsquo;s
            security, in readiness to take on the culprits.
          </p>
          <p>
            As anticipated the JVP called once again, this time demanding
            500,000 rupees, with the same &lsquo;bin top&rsquo; drill. He got
            the JVP man to call him back multiple times, giving time for the
            call to be traced and recorded. Nahil took this recording to his
            office and played it back to his telephone operator, Aloma, hoping
            she would recognise the voice. Aloma was unable to recognise the
            voice but was very sure that the operator action was from a NEC
            telephone system, exactly like the one she had previously worked on.
          </p>
          <p>
            He called Lokki Abhayaratne, a Director at Fentons, which is the GSA
            for NEC. His Sales Department confirmed they had sold around 40
            units. One unit was sold to a call centre in the Colpetty area. When
            Nahil visited the call centre, the lady manning the booth was able
            to trace his number, vividly recalling the two perpetrators.
            Convinced they would call once again, he left the EWIS number with
            the booth operator as his contact and left. Nahil was in Kandy when
            he received a call that the two men were at the call centre.
          </p>
          <p>
            Buying time, the smart call centre lady had instructed the two men
            to return in a few minutes to make their call, citing the phones
            were engaged as a means to stall them so that she had time to call
            EWIS. Nahil called Deva Corea who was in charge of the
            President&rsquo;s security and he rushed with a backup to Liberty
            Plaza where the communication centre was located. The lady pointed
            out the two men who by peradventure were hanging around Liberty
            Plaza with the lady at the booth following them at a safe distance.
            Once Deva got there she was able to point them out, after which the
            two men were arrested and taken to the Colpetty Police Station where
            they were properly beaten up by the cops.
          </p>
          <p>
            Thereafter, the cops informed Nahil that one of the men was his
            relative, his father&rsquo;s brother&rsquo;s, son-in-law, whom he
            had helped financially on a few occasions. The man had assaulted a
            judge in Kurunegala and the cops were after him. Besides that, he
            had no job and was helpless.
          </p>
          <p>
            Nahil called Ashley Herath, a lawyer in Kurunegala who was a friend
            as well as the lawyer for the Judge, to find out whether he could do
            something to help sort this thing out. This was a volatile period
            when inevitably the cops would bump off anyone with a suspicion of
            being linked to the JVP. Nahil being aware of the fact was not in
            favour of the two men meeting the same fate.
          </p>
          <p>
            The case dragged on for two-and-a-half years. The man had bought a
            motorcycle with the 100K, which he returned to Nahil. He withdrew
            the case and handed the bike over to Aloma as a reward for her
            intelligence and brilliant performance. Shortly after she moved
            overseas, gifting the bike to her brother. He never heard from his
            relative again.
          </p>
        </Col>
      </Row>
    </div>
  );
};
