import { Row, Col } from "antd";

export const TheNavamMawathaBusinessComplex = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="the-navam-mawatha-business-complex">
          The Navam Mawatha Business Complex
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            There were two &lsquo;petti kades&rsquo; operating inside the Navam
            Mawatha Business Complex. The two &lsquo;kade&rsquo;
            operators,&rsquo; though offered an attractive incentive to move,
            were refusing to do so. Business was very good for the two since
            there was no place in the vicinity other than the petti kades to buy
            even a cup of tea. After a while, one guy relented, accepted a
            pay-out and left while the other operated his kade under a jam tree
            in the car park belonging to Carsons. Nahil decided to cut the jam
            tree as a way to deter him and get him to move. It was an epic fail.
            The guy still operated unscathed, in the boiling hot sun, because
            his business was booming. Nahil decided to make a deal with him and
            told him, &ldquo;Sir, please move from here. Hanging out here
            won&rsquo;t work for you,&rdquo; offering him a brand-new
            three-wheeler as part of the deal. He did not budge.Thinking of a
            way to get around this, he finally came up with an idea that he had
            a gut feeling would work. Nihal was a peon working in the East West
            office who had previously worked at a food outlet. Nahil set up a
            little kiosk at the corner of the IBM car park, opposite Forbes,
            with a bar counter and stools, a fridge and tea boiler with Nihal in
            charge. The kiosk became a hangout for many office workers during
            their tea and lunch breaks, with further demand after a TV set was
            installed inside the kiosk during the Cricket World Cup.
          </p>
          <p>
            Suddenly it seemed the &lsquo;petti kade&rsquo; mudalali was feeling
            the pinch, with a majority of his clients leaving chits and moving
            to &lsquo;Nihal&rsquo;s Kade.&rsquo; Poor guy, no one had settled
            their bills and he was in a bad way. He came to Nahil and agreed to
            leave. He was given a new three-wheeler and some cash. He was
            actually happy to move away.
          </p>
          <p>
            The East West building was the first at Navam Mawatha. Nahil is a
            perfectionist and the curb appeal of property really mattered to
            him. He literally went to town by getting the roads inside the
            complex paved with pre-mixed concrete, new drains built and the road
            marked with pedestrian crossings, etc., because he firmly believes
            this enhances the value of their property and also helps their
            neighbours&rsquo; property look good.
          </p>
          <p>
            The NMBC was his own little empire. He banned three-wheelers from
            parking inside and all sweep sellers had to turn off their music and
            megaphones inside the complex, making sure to introduce some
            discipline before things got out of hand.
          </p>
        </Col>
      </Row>
    </div>
  );
};
