import { Row, Col, Image } from "antd";

export const Bunker1The37000TonneDeadWeightTanker = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="bunker-1-the-37000-tonne-dead-weight-tanker">
          Bunker 1 - The 37,000 Tonne Dead Weight Tanker
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/17-bunker-1-the-37000-tonne-dead-weight-tanker/emerald-sea-bunker-1.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>The Emerald Sea – Re-named Bunker 1</small>
              </p>
            </div>
          </div>
          <p>
            As East West continued its upward trend in business, it enjoyed a
            series of ‘firsts’ in diverse areas of its shipping business, the
            first major investment being a bunkering service. Bunkering means
            supplying to the fuel containers of a ship or refuelling of a ship.
            The company owned and operated the largest ship in Sri Lanka, the
            37,000 DWT (Dead Weight Tanker) MT ‘Bunker 1’ – previously known as
            the Emerald Sea – together with the feeder tankers and salvage and
            towage tugs, the MT ‘Rangiri’ and the MT ‘Ranveli’. This operation
            was through East West Bunker Services Pvt. Ltd., which carried out
            bunkering services offshore, headed by Captain Ranjith Weerasinghe.
            Chatting to him on how the operation worked, he says, the OPL (off
            port limit) position he selected as a shallow patch was anchoring in
            the deep Indian Ocean – 6 56N, 079 40E – initially to get away from
            the 12-mile territory rule during the time the Ceylon Shipping
            Corporation monopoly was in place. Even though the outside port
            limit is 6-7 miles, the Sri Lanka Ports Authority and other
            authorities still consider the OPL position to be outside the
            12-mile territory. Now with the liberalisation, it is possible to
            bunker anywhere. He says that he has been trying to get through to
            those concerned that the OPL is off port and not off territory.
            Unfortunately to his chagrin, his endless communication seems to be
            falling on deaf ears!{" "}
          </p>

          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/17-bunker-1-the-37000-tonne-dead-weight-tanker/emerald-sea-bunker-1-2.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>The Emerald Sea – Re-named Bunker 1</small>
              </p>
            </div>
          </div>
          <p>
            OSMS also operated salvage and towage tugs – the MT ‘Nilgiri’ and MT
            ‘Sigiri,’ supply vessels ‘OMS1’ and ‘OMS2,’ through Offshore Marine
            Services Ltd.
          </p>
          <p>
            Always one step ahead in business, it was around 1978 that 20 feet
            and 40 feet containers were being used in shipping into and out of
            Sri Lanka, although the infrastructure for the business of
            containers, especially in the areas of storage, etc. were not in
            place. Nahil, recognising the deficit and seizing the opportunity,
            launched East West Containers (Pvt) Limited, owning and operating
            the first off-port container freight station in Peliyagoda. This was
            the only container storage facility and yard on the island at the
            time, headed by Capt. Ryle Mendis, an ex-Shipping Corporation
            Captain.
          </p>
          <p>
            EWC had just launched operations when Iraq invaded Kuwait, followed
            by the Iran/Iraq war. EWC was stowing around 200 Iraq line
            containers in their yard. After the war, Iraq lines were no more.
            With the storage cost for these containers building up till the
            cut-off point a year and a half later, they obtained automatic
            ownership. Once the containers riveted to EWC, to do with the lot as
            they wish, they sold the empty containers making a substantial
            income.
          </p>
        </Col>
      </Row>
    </div>
  );
};
