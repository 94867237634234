import { Row, Col, Image } from "antd";
import { ChapterFooter } from "../chapterFooter/chapterFooter";

export const AgnesDeSilvaMalalgodaAmma = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="agnes-de-silva-malalgoda-amma">
          Agnes De Silva Malalgoda – Amma
        </h1>
      </div>
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/01-Agnes-De-Silva-Malalgoda%E2%80%93Amma/01-amma-01.jpg"
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Agnes De Silva Malalgoda – Amma</small>
              </p>
            </div>
          </div>
          <p>
            As he recalls incidents and anecdotes of growing up in Kandy with
            his family of five, his tone softens discussing his parents and
            siblings, of whom he says with pride, “In their lives and careers
            they have made a mark and left a legacy for their generations to
            follow .” His dad Gratian Wijesuriya, a native of Udugampola
            Gampaha, was a civil engineer - “A very smart man, who always
            thought out of the box and was ahead of his time.” His father was
            responsible for designing and building many beautiful homes in and
            around Kandy. Though built in the seventies, these houses are still
            very current in aesthetics and contemporary in style. An example
            would be his family home in Galkanda Road, Aniwatte, Kandy, which
            stands as a testament to his dad’s design genius.
          </p>
          <p>
            He speaks with pride of his mother, who loved him dearly. She was
            Agnes De Silva Malalgoda, who hailed from Bentota, a student and
            later a teacher at Good Shepherd Convent, Kandy, Pushpadana
            Vidyalaya Kandy and a school in Minuwangoda, where she taught Home
            Science. When thinking up a name for him, she drew inspiration from
            the best student in her class, Nahil Samarakkody
          </p>
          <br></br>
          <p>
            He has had a few thumps on his head and books thrown at him while in
            college with the misconception that he couldn’t even spell his name
            properly! Interestingly, he tracked down Nahil Samarakkody the son
            of Edmund Samarakkody, a lawyer, trade unionist, politician and
            Member of Parliament for Dehiowita. Edmund was married to his first
            cousin Dagmar Samarakkody and had a daughter Chulanganee, and son
            Nahil, who was a Judge at the Mount Lavinia Courts. Nahil phoned him
            and said to him, “By the way, I’m so and so, I’d like to meet you.
            “Thanks to you being my mother’s favourite student, I got this
            awkward name.
          </p>
          <p>
            Nahil S. says, “Great, that will be nice,” and hangs up after
            setting a date for a meet-up. The next day he heard the man had
            committed suicide. “Oh gosh!’’ he thought, “What a name!” He was
            unhappy that he didn’t get a chance to meet Nahil
          </p>
          <p>
            His father decided to mark his birth with the ‘Victory in Europe
            Day’ a.k.a. VE Day, by inserting Jayantha to his name. Consequently
            his name was coined as Muthukuda Wijesuriya Arachchiage Don Jayantha
            Nahil Wijesuriya
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/01-Agnes-De-Silva-Malalgoda%E2%80%93Amma/02-family-portrait-photo-01.jpg"
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Thaththa, Nahil, Mahes, Kanthi and Amma</small>
              </p>
            </div>
          </div>
          <p>
            He was the middle child, the thorn between two roses. His older
            sibling Maheswari and the younger Srikanthi were students of Girls’
            High School Kandy and are now doctors, Mahes a Pathologist and
            Kanthi a Paediatrician. Mahes married Dr. Kumar De Silva whom she
            met at university and moved to London with their three children,
            Ranil, Amali and Nimali. Kanthi married Dr. Lakshman Karalliedde and
            settled in Kandy for a while, later moving to New Zealand and
            finally migrating to the UK with their sons, Janaka, Dhammika and
            Siddantha.
          </p>
          <p>
            He spent a few hours discussing the beginning of his mesmerising
            life story. With much nostalgia, he recalls that his parents always
            made his sisters and him feel important and loved. Their children
            were their utmost priority. Nothing was too much work or trouble
            when it came to the three of them. At times, even though busy with
            work, it was fascinating in retrospect that they would put aside
            anything and everything just to be hands-on with the children.
          </p>
          <p>
            Clamouring for their mother’s attention the three children would
            often ask her whom she loved the most. Her apt reply was: Akka since
            she is the eldest, Nangi since she is the youngest and Nahil since
            he is the only boy!
          </p>
          <p>
            They were showered with love and encouraged to pursue their dreams,
            coaxed and guided through whatever project they took on to bring out
            each child’s potential and taught basic moral values; it was
            ingrained in the three young minds to always be truthful and guard
            their integrity, which they’d say would be of great value when they
            stepped out into the adult world.
          </p>
          <p>
            They were a very closely-knit unit and still are. Even after their
            parents’ demise, the siblings, now all in their seventies and oceans
            apart, still look out for each other.
          </p>
        </Col>

        <Col xs={24} sm={24} md={24} style={{ textAlign: "center" }}>
          <Image src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/01-Agnes-De-Silva-Malalgoda%E2%80%93Amma/02-Aniwatte-Family-01.jpg" />
          <div style={{ textAlign: "center" }}>
            <p>
              <small>
                No. 4, Galkanda Road, Aniwatte, Kandy – L-R Chandrakanthi,
                Kanthi, Ratna, Mahes and the family pet dog, Hardy
              </small>
            </p>
          </div>
        </Col>
      </Row>
      <ChapterFooter title={" Agnes De Silva Malalgoda – Amma"} />
    </div>
  );
};
