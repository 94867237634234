import { Row, Col } from "antd";

export const Donating15MRupees = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="donating-15-m-rupees">Donating 15 M Rupees</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            One evening Nahil was invited to Ravi Karunanayake&rsquo;s home, he
            was one of the few laymen present for drinks and dinner among a heap
            of UNP politicians. Also present was Lal Wickramatunge and his
            brother the late Lasantha Wickramatunga with Nahil in Ravi&rsquo;s
            beautifully landscaped garden sipping on a drink. Whilst chatting
            they noticed there was a discussion taking place inside the house
            between the large number of politicans gathered inside. Thondaman
            was there with his posse, among whom was a guy named Yoga, an old
            Trinitian, whose father Mr. Ramiah was a friend of Nahil&rsquo;s
            father, Gratian Wijesuriya. Yoga, much younger than Nahil, was the
            regular &lsquo;ball picker&rsquo; whenever they played their
            Saturday cricket matches in their Kandy neighbourhood as teenagers.
            Those with Thondaman, including Yoga, were crossovers who had to be
            paid for their game of political &lsquo;musical chairs&rsquo;.
          </p>
          <p>
            Just as they got down to their second beer, Ranil Wickremasinghe
            strolled into the garden and joined them. Looking around he says,
            &ldquo;You know, I&rsquo;ve given all these &lsquo;buggers&rsquo;
            the ministries that they requested during the crossover and now they
            all want money.&rdquo; He continues to say that just like CHAEBOL -
            the Korean Chamber of Commerce or similar business groups, the UNP
            was ready to support a few chosen private sector companies.
          </p>
          <p>
            Just then Nahil knew a handout &lsquo;dadayak&rsquo; from him was
            inevitable. Ranil avers, &ldquo;These people are demanding Rs. 60
            million by tomorrow afternoon, can you please help?&rdquo; Three of
            those present, including Nahil, pledged 15 million each, the other
            two being Samson Silva and another businessman.
          </p>
          <p>
            Once Nahil got the Rs.15 million ready, Ravi came by at his request
            to his apartment at the IBM building to take the cheque to Ranil,
            However, he insisted that Nahil should give it to the
            &lsquo;Boss&rsquo; personally. They drove to Fifth Lane, meeting
            Ranil in the front room of his residence. After some small talk
            Nahil handed over the cheque to Ranil, who was so unsure of
            himself,he nearly dropped the cheque. In 2001 after all this
            Nahil&rsquo;s only request to Ranil was that he gets all the pot
            holes on Dutugamunu Road, Paliyagoda where the East West warehouse
            complex is situated, a public road, filled, tarred and compacted .
            The request was never adhered to. The only response he got from
            Ranil was that he will speak to some person at the UDA and get the
            road paved, but he did not do so. Nahil believes this is a signature
            trait - that Ranil - forgets names, faces and favours. He also says
            that he could have carpeted Dutugamunu mawatha with that sum of
            money.
          </p>
          <p>
            Nahil was at a dinner hosted by Tamara Kunanayakam, Sri Lanka
            Diplomat and former Permanent Representative to the UN, in her home
            at Rosmead Place. Besides him the other two guests present were
            Palitha Kohona, former Permanent Representative to the UN and Rajiva
            Wijesinghe, Political Analyst and cousin of Ranil Wickremasinghe.
            During the conversation Nahil, in lighter vein recalled his donation
            of Rs.15 million to Ranil, On hearing this Rajiva&rsquo;s immediate
            reaction was that he would report the incident to the Bribery
            Commission the next day. Nahil requested him to hold back a few days
            until he checked with his Finance Director if the three cheques had
            been forwarded to him from his bank after the collection of the
            Rs.15M. ten years ago. Nahil says &lsquo;&lsquo;I will standby my
            statement.&rsquo;&rsquo; Once everything checked out Rajiva reported
            this to the Bribery Commission. A few months later Nahil was called
            by the bribery commission for an interview. He gave them the copies
            of the cheques and told the person who interviewed him the entire
            story. This person seemed to take great delight in Nahil&rsquo;s
            story.&nbsp;
          </p>
        </Col>
      </Row>
    </div>
  );
};
