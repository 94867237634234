import { Row, Col } from "antd";

export const DrSLGunasekera = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="dr-s-l-gunasekera">Dr.S.L. Gunasekera</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Nahil has never been too involved in politics although if he hears
            about an individual, a leader of a political party, who makes
            positive sense with his or her aspirations for a better Sri Lanka,
            he has always tried to help. The &lsquo;Sihala Urumaya&rsquo; which
            in Sinhala means inheritance or birthright of the Sinhala people,
            was launched by Champika Ranawaka with the late Mr. S.L. Gunasekera,
            as President of the party.He was a very sensible and upright person,
            a smart man, who Nahil says, never had a chance to work for the good
            of this country, due to a few over-zealous &lsquo;Sihalayas&rdquo;.
            Ironically the Sihala Urumaya was not a &lsquo;Sinhala
            Buddhist&rsquo; party. It was a &lsquo;Sinhala&rsquo; party that had
            many Catholics and Protestants who voted for the party in the
            General Elections held in October 2007.
          </p>
          <p>
            They sidelined him for being a Christian, which was the main
            criticism of the party members . He was born a Christian, and as a
            young man he became an agnostic after the untimely death of his
            father and finally an atheist. It seemed that most of the Urumaya
            members defined SL by his &lsquo;belief&rsquo; or more rightly said
            his un-belief. They overlooked his stand-out qualities and his
            exceptional code of ethics which guided his conduct in a manner that
            was patriotic, honest and socially committed among the other
            excellent characteristics of a good and upright human being.
          </p>
          <p>
            Nahil has copies of the entire collection of books SL has written,
            faithfully attending every book launch.
          </p>
          <p>
            The civil war was on when Nahil first met Punyakanthi nee De Soysa,
            who is married to Chanaka, a strong UNPer, who was the Chairman of
            Sri Lanka Insurance and Trans-Asia Hotel and also a relation of his
            sister&rsquo;s husband&rsquo;s family.
          </p>
          <p>
            Punyakanthi telephoned Nahil requesting a meeting at her residence
            on Charles Drive. Also present was Champika Ranawaka. After the
            preliminary introductions and small talk, Champika remarked
            &ldquo;Balanna may Colomba inna Demala okkoma LTTE ekata support
            karanawa. Koheda Sinhala businessman la ekkenekwath innawada apita
            udawwak karanna&rdquo; &ndash; (&ldquo;All the Tamil businessmen in
            Colombo support the LTTE, is there one Sinhala businessman around
            who can help us?&rdquo;).
          </p>
          <p>
            Punyakanthi was supporting the &lsquo;Urumaya&rsquo; and wanted
            Nahil to hear this, virtually &lsquo;straight from the
            source&rsquo;. In the course of their conversation, noticing that
            there was no other vehicle parked outside Punyakanthi&rsquo;s home,
            Nahil inquired from Champika how he commuted to Charles Drive.
            Champika divulged to Nahil that his vehicle had recently toppled
            over and had been condemned. That very day Nahil bought him a new
            vehicle. Since then whenever Champika requested financial assistance
            he would help him. He also helped with the Sihala Urumaya
            advertising, though he never got too involved with the
            &lsquo;Urumaya&rsquo; members.
          </p>
          <p>
            During this period, the &lsquo;Urumaya&rsquo; ran a press
            advertisement depicting a weighing scale, with the minorities
            enjoying a more comfortable status than the majority, making the
            minorities behave like the majority. Nahil has been asked on many an
            occasion if he favoured any political party or individual. He says
            he does not, simply on the basis that all these political aspirants
            are very fluid. Looking at it from his perspective, today&rsquo;s
            heroes can be tomorrow&rsquo;s zeroes. A good example was SL. He was
            the only sensible person in the party but they got rid of him
            because of his religion. The burning issue was how to set about
            defeating the LTTE, not SL&rsquo;s religious affiliations.
          </p>
          <p>
            He was instantly irked with Champika and the Urumaya members over
            their petty behaviour and treatment of Dr. SL. This was
            &lsquo;it&rsquo; for him as he uncoupled himself from the Urumaya
            almost overnight although he continued to support Champika who was
            giving his support to the Rajapaksa&rsquo;s to defeat the LTTE.
          </p>
          <p>
            About 12 years ago there was a severe typhoon that affected Myanmar,
            a Buddhist nation, that Champika was adamant they should help,
            disclosing to Nahil that the nation had been severely destroyed by
            the typhoon with the low-lying areas flooded and the people
            suffering. With compunction, Chris Dharmakirthi, Rathana Thero, and
            Nahil were on a flight to Myanmar to help this poor, underdeveloped
            country and its typhoon-hit, suffering masses.
          </p>
          <p>
            Driving from the airport through the city, they witnessed heavy
            traffic and enormous buildings set in a throbbing metropolis and
            night life, so much so that Rathana Thero commented,
            &ldquo;Egollanta puluwan apita udaw karanna&rdquo; &ndash;
            (&ldquo;They are in a position to help us&rdquo;.) Nahil&rsquo;s
            immediate thought was, &lsquo;What are we doing here? They are far
            more developed than us.&rsquo; This was the city and they expected
            to see worse as they drove to the villages. Their assumptions were
            way off the mark. It was heartening to see how well-organised the
            Burmese were, with roofing sheets and other aid. He strongly
            believes that human beings are stubborn and resilient with a
            God-given ability to arise time and time again after a disaster of
            this nature. He spent two days of &lsquo;rasthiadu&rsquo; &ndash;
            (&lsquo;wasted time&rsquo;)and got back home.
          </p>
        </Col>
      </Row>
    </div>
  );
};
