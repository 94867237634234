import { Row, Col, Image } from "antd";

export const EastWestProperties = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="east-west-properties">East West Properties</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/30-east-west-properties/east-west-properties-peliygaoda.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>East West Properties - Peliyagoda</small>
              </p>
            </div>
          </div>
          <p>
            A college buddy of Nahil&rsquo;s, Vishu, short for Vishwanathan, who
            passed away some years ago, was the auditor at East West.
          </p>
          <p>
            The tax laws of the country provided for all capital expenditure to
            be written off as deductible expenditure in the year of expenditure,
            known as LSD. Suddenly the Inland Revenue Department (IRD) says,
            &ldquo;Alright guys, it is time to pay your taxes.&rdquo;
          </p>

          <p>
            Since they now had to pay their taxes, with all their holdings under
            East-West Enterprises, they formed a company named East-West
            Properties, considering tax exemptions were been given by the Urban
            Development Authority for property development approved under the
            UDA scheme. The Peliyagoda area was undeveloped and the UDA offered
            the incentive of a tax holiday to anyone who invested and developed
            this land giving an assurance to undertake to build up the
            infrastructure like&nbsp;&nbsp;roads sixty feet wide, drains, water
            supply and power lines.
          </p>
          <p>
            They bought the Peliyagoda land and were happily carrying on
            business in haulage and clearing, with their vehicles, presumed
            parked there, while they paid a monthly rental into this tax-free
            company, East West Properties. The storage of vehicles and other
            activities was just an eyewash &ndash; since the marshy land was not
            reclaimed yet, though according to their books they were paying a
            rental because it was deductible in the earning company and tax-free
            in the receiving company. In reality, they were paying rent to work
            and park in a marsh. After a few years, they &nbsp;developed the
            site and this was the beginnings of East West Properties.
          </p>
        </Col>
      </Row>
    </div>
  );
};
