import { Row, Col, Image } from "antd";

export const AirlankaCargo = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="airlanka-cargo">Airlanka Cargo</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            In 2001 he recalled meeting Ranil at a function hosted by Ravi
            Karunanayake, where, at a discussion, Ranil mentioned to the group
            he was chatting with, that once he became PM he would get rid of the
            Emirates agreement, which at the time, restricted any other local
            airline operating out of Colombo.
          </p>
          <p>
            Taking him at his word, once the Emirates agreement was terminated,
            East West launched a cargo carrier, using a good marketing ploy by
            naming the enterprise Airlanka Cargo. The name
            &lsquo;Airlanka&rsquo; was not registered by the Registrar of
            Companies, thus keeping the name open to anyone to use. As they
            started up Airlanka Cargo, there were no restrictions in place for
            cargo carriers. They started up virtually hitting the ground
            running, doing test runs using a Russian aircraft on the
            Colombo-Male-Sharjah-Chennai-Colombo sector. The project was
            amazing. It was all about getting the ground-work in place for a
            passenger carrier and not about the cash. Their ploy of using the
            name &lsquo;Airlanka&rsquo; paid off, so much so that whenever they
            handed out their business cards to people, with Airlanka on the
            cards, it opened many doors, though they made it a point to
            enlighten everyone that their operation was independent, with no
            connection to SriLankan Airlines. The name helped when it came to
            Sharjah and other destinations.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/63-airlanka-cargo/air-lanka-cargo.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>East West Cargo Place - AirLanka.org</small>
              </p>
            </div>
          </div>
          <p>
            In the midst of all this, the SriLankan Airlines management was up
            in arms that East West was operating under the Airlanka banner.
            Aware that East West was in the clear using the name Airlanka,
            operating within the rules, there was nothing they could do, thus
            SriLankan started annoying Airlanka by filing cases against it. An
            injunction was filed for two weeks each time. They kept repeating
            this many times over which amounted to Vexatious Litigation. This
            barred them from using the name Airlanka when answering phones etc.
            SriLankan used this harassment tactic, which was good. They sent
            Shibly Aziz, their lawyer, to Dubai to negotiate this, by which time
            Nahil had many letters and emails in support of Airlanka, outlining
            how the Emirates agreement was &lsquo;thrust&rsquo; upon the
            Government &ndash; a four-way airline agreement between SriLankan,
            Emirates, the Sri Lankan Government and the Department of Civil
            Aviation, prepared by Emirates and handed over to CBK.
          </p>
          <p>
            A person who worked previously for the Department of Civil Aviation,
            presently working in the Middle East, informed Nahil that there was
            a way out, claiming the DCA was not party to a commercial venture,
            since they had removed the Director General of Civil Aviation (DGC)
            from being a party to the Emirates/SriLankan Airlines agreement; he
            confirmed this avenue could be used. The DCA is responsible for the
            issue of licences for airlines. The Government had left the DCA out
            of the SriLankan-Emirates agreement, therefore it could issue
            licences without this being an issue.
          </p>
          <p>
            The day Shibly was leaving for Dubai, Nahil met and informed him
            there was a loophole in the system since the issuing authority was
            the DGC who had no affiliation to either SriLankan or Emirates
            airlines. Shibly mentioned that he would keep in mind what Nahil had
            told him. A few days later, he conveyed to Nahil that according to
            Emirates, if Nahil proceeded with his airline, Emirates would send
            back the Sri Lankan housemaids employed there. Nahil ceased
            operations immediately.
          </p>
          <p>
            East West was keen to operate the Colombo/Jaffna run under the
            &lsquo;Airlanka&rsquo; banner and tried to do the pilot run in an
            aircraft chartered from Russia. This aircraft had been previously
            used by the charter operator for gun and ammo running in Africa. The
            aircraft, chartered for US$ 50,000, had to comply with the DGC
            regulations which the operator promised to get done. Airlanka had
            already paid the cash to the airline operator when the airport
            informed them that the aircraft was being prepped for take-off
            illegally. On account of the operator&rsquo;s dishonesty, East West
            decided to get the charter operator to furnish a bank guarantee of
            US$ 50,000, which they were unable to encash. This went on for three
            years. In the melee, the same aircraft was sent to Karachi with a
            haul of cargo. Whilst landing, the plane&rsquo;s landing gear played
            up and the flight crash-landed on the runway, causing the airport to
            be closed. Airlanka Cargo was banned from arriving into Karachi!
          </p>
        </Col>
      </Row>
    </div>
  );
};
