import { Row, Col } from "antd";

export const ChairmanCWE = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="chairman-cwe">Chairman CWE</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Ravi Karunanayake was Minister of Trade in the UNP Government, in
            the early 2000s. On meeting Nahil at a function, while chatting over
            beers, the topic of the CWE came up. In the middle of the
            discussion, Ranil insisting that the government had no business
            selling &ldquo;karola&rdquo; came up.
          </p>
          <p>
            The Government had decided to privatize Sathosa. Ravi, being the
            Minister in charge, requested Nahil to take over as Chairman with a
            mandate of prioratizing some order into the system before the
            privatisation of the CWE. He agreed to take up the challenge pro
            bono, without an official car or salary.
          </p>
          <p>
            He requested the appointment of Lal Wickrematunge as hisdeputy to
            handle the unions and other related stuff. While Lal used the
            Chairman&rsquo;s official car, Nahil used his own jeep.
          </p>
          <p>
            Based on what they were told upon taking over, they expected to walk
            into a den of rouges, only to be pleasantly surprised to meet the
            most humble, hardworking and honourable people. Since the CWE was a
            massive organisation, all the systems were already in place for the
            proper administration of the organisation. For instance, if one were
            to advertise sugar, rice, onions, etc., the size of the advert, the
            number of days it should run, the newspaper and the specifications
            of the advertisements, including the opening of trading among the
            other departments and openings of tenders and awards, everything was
            streamlined.
          </p>
          <p>
            Though the CWE was messed up, he was surprised, as a leading
            businessman, to witness more corruption over the years in
            &lsquo;blue-chip&rsquo; private organisations than at the CWE. He
            restructured the CWE under the banner of &lsquo;Lanka
            Sathosa.&rsquo;
          </p>
          <p>
            Nahil is still not sure if they wanted him to &lsquo;rob.&rsquo;
            They argued that the Government had no business to be selling karola
            at the CWE. The question was, will the private traders be interested
            in purchasing it in its present state?
          </p>
          <p>
            He did an extensive background research of the organization.
            Realizing no one was going to buy this messed up enterprise in its
            present state, he planned to reinvent the outlets on par with, or
            better than, the bigger supermarkets like Cargills. The CWE had
            motorized tables, etc., and were the first to barcode all items on
            sale. Some of the smart young sales people knew the numbers which
            they would roll off by memory. Nahil and the senior staff organized
            a bar code initiative at the Chamber of Commerce where the small
            producers were each given a barcode which they could print on their
            &lsquo;kadju&rsquo; packets and other produce. He sourced the
            accounting software that was being used by Cargills, from Anthony
            Page (Baba), the Chairman of Cargills. Baba graciously offered the
            accounting software including the people to implement it, though a
            competitor, since this was a national effort.
          </p>
          <p>
            Before the advent of this system, the produce for sale was fully
            coded by the staff, using barcode printers with the labels pasted on
            the items, till eventually, the code was pre-printed, saving the
            staff time, pasting things at the point of sale. This practice was
            in operation at the Jawatte, Welisara and Rajagiriya outlets which
            were efficiently operated. Ravi was elated, suggesting they opt for
            the outlets to be open 24 hours. Nahil agreed to this and launched
            24 hour operations. These three outlets were a model for
            implimenting of other outlets island wide.
          </p>
          <p>
            Things had picked up rapidly and it was smooth sailing when the
            owner of Mustafa Centre Singapore turned up with an idea of making a
            bid to buy over the supermarket chain. Ravi sent him to Nahil, who
            showed him around.
          </p>
          <p>
            During the ensuing conversation between Nahil and the owner of the
            Mustafa Centre, the latter mentioned that he started his business
            with his brothers, selling handkerchiefs on the pavement in
            Singapore, gradually rising to be a large and popular departmental
            store in Singapore. Nahil, aware that this store is congested at all
            times, was curious to know about the problem of shoplifting by
            customers, inquiring as to what measures they take to keep
            shoplifters at bay. His answer was amazing. He explained to Nahil
            that he had no problem with the customers shoplifting since they
            would steal, maybe come back and do so once again in a month,
            whereas if his staff was robbing him, it would be a huge concern,
            considering it would be a daily occurrence.
          </p>
          <p>
            He mentioned that inside all his stores, cameras were trained on the
            staff, though not anymore. With technological advances, shoplifting
            is not that easy and hardly occurs now.
          </p>
          <p>
            He further stated that previously the HR Department would show the
            new recruits the cameras, warning them not to try anything funny,
            putting the &lsquo;fear of Moses&rsquo; into them from day one.
          </p>
          <p>
            When a handful of local enterprises, were ready to bid for the
            organisation, Ravi. K was opposed to transferring 100% of the Lanka
            Sathosa shares, insisting that the Government should have some
            control of it. When Nahil questioned him as to why, Ravi averred
            that this way they could keep the cost of living down. Nahil pointed
            out to him that what he was saying was nonsense because unlike the
            good old days, Lanka Sathosa was presently catering to less than 5%
            of the market. Then Ravi messed the sale up to some extent, debating
            whether to sell 49% off and the Government to be in control of the
            rest, to which Nahil disagreed. To tide over his error of judgment,
            Ravi suggested a deal, insisting they have a management agreement
            with the buyer like Emirates had with SriLankan Airlines. Nahil was
            against it and suggested that they should operate in accordance with
            the Companies Act. In his analysis of the entire operation he seemed
            to think that maybe 30 years ago, CWE was a &lsquo;kingmaker,&rsquo;
            a mass trading house that spiralled downwards after imports started,
            now basically no more. He emphasized that these Government types are
            control freaks, wanting control of everything they touch and without
            this they will revert to the nobodies they were. That&rsquo;s the
            long and short of it. Sadly, it&rsquo;s happening even today. Even
            in the term PPP used today; instead of privatization, two more Ps
            have been added, which means &lsquo;private-public
            partnership&rsquo; or some such rubbish. He believes the two Ps give
            them a handle on it. It should have been just a privatization, and
            sadly this is no more.
          </p>
          <p>
            As expected, the expansion and divestment of Lanka Sathosa, the
            raising of funds and equity was not possible, thanks to Ravi and his
            lack of foresight. Subsequently, the shares were sold to a rather
            unique consortium comprising Carsons, Arpico and Ceylon Biscuits who
            made a 600 million rupee bid for Lanka Sathosa. A few weeks later
            Roshanne passed away and Nahil resigned. As the buyers tried raising
            the capital for the 49%, the shareholders could not be matched by
            the government - thus the expansion could not take place and Sathosa
            reverted to the government, with the original buyers loosing the
            money invested.
          </p>
        </Col>
      </Row>
    </div>
  );
};
