import { Row, Col } from "antd";

export const AcquiringAndSaleOfHotelIntercontinentalColombo = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="acquiring-and-sale-of-hotel-intercontinental-colombo">
          Acquiring and Sale of Hotel Intercontinental Colombo
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Kumar Sharma, an Indian living in Sri Lanka, owned the hotel which
            he had acquired from the Intercontinental chain. Vijitha,
            Nahil&rsquo;s son, was a school mate and a great friend of
            Sharma&rsquo;s son, Sandeep. The young man, though not much to show
            by way of academia, was an amiable lad, who would occasionally stay
            over at Nahil&rsquo;s. His father enrolled him at the best hotel
            school in Switzerland to follow a three-year course in Hotel
            Management, whereas regrettably, he returned to Colombo after three
            months, affirming to his dad that he was not interested in hotel
            management or any related field.
          </p>
          <p>
            Sharma had bought the hotel from the Intercontinental chain,
            sometime before, envisioning his son would take over and operate the
            hotel after his graduation in Switzerland. Considering his
            son&rsquo;s decision, all his grandiose plans for the lad went left
            field. Disappointed, he offered to sell the hotel to Nahil.
          </p>
          <p>
            Lanka Securities was the brokers of the transaction. The price
            Sharma quoted seemed like a good deal; interested, Nahil inquired of
            him if he had any debt, to which he replied, &ldquo;What debt? I
            have ten billion cash surplus.&rdquo; Nahil bought the hotel with no
            financial due diligence, nor physical due diligence. He managed to
            raise the necessary cash, buying the hotel without even visiting it;
            this was an honest deal between two friends.
          </p>
          <p>
            On acquiring the hotel he did some refurbishing, getting the hotel
            ready for the market place, although the civil war was slowing
            things down on the tourism front. The Central Bank had erected a
            massive barrier almost opposite the entrance into the hotel,
            subjecting hotel visitors to double security checks, one at the
            roundabout and the other at the barrier. Prasanna Jayawardena of
            Elephant Corridor fame, a college mate of Nahil&rsquo;s, was
            visiting the Governor of the Central Bank, and invited Nahil to tag
            along so he could state his case concerning the excess security
            barrier opposite his hotel. As Nahil tried to explain to the
            Governor the inconvenience faced by visitors to the hotel due to the
            two security checks three meters apart, he humbly requested him if
            possible to intervene and make it a single check. The Governor got
            aggressive and said something to the effect of &ldquo;Do you want me
            to get fully checked?&rdquo; &ndash; meaning &lsquo;I can make it
            worse for you&rsquo;. That was frustrating. You go to him for a
            solution and he becomes the problem. As a rebuttal, Nahil was about
            to say something nasty to the man, when Prasanna anticipated his
            mood and kicked him under the table as a sign to keep his mouth
            shut.
          </p>
          <p>
            They were in this huge room which had a cupboard in one corner. When
            the man opened the cupboard to get something, Nahil noticed that it
            held a large number of fancy suits whereas Nahil expected to see a
            Library of books pertaining to Finance.
          </p>
          <p>
            Nahil made vast improvements on a barrage of mistakes he found in
            the hotel structure &ndash; the steps were the wrong size and the
            plumbing messed up, among a host of other sundry mistakes. In
            addition, auxiliary repairs were carried out wherever necessary.
          </p>
          <p>
            Meanwhile, the Ports Authority, which was on the lookout for a
            building to purchase as an office complex, made an offer to buy the
            hotel for this purpose. Nahil&rsquo;s secretary at the time was
            Surani, whose husband knew the bigwigs of the Ports Authority.
            Acting as a broker, he was aggressively trying to get the deal
            together based on the price quoted to him. Nahil maintained he would
            sell only if he got his price. The Sunday Leader got a hold of the
            story and ripped it to bits with the headlines, &lsquo;Why is the
            Ports Authority trying to buy a 5-star hotel to use as an
            office?&rsquo; The Tuesday after the article appeared, he happened
            to meet Mahinda Rajapaksa in the lobby of the hotel, on his way out
            from a wedding. Greeting him, MR says, &ldquo;What&rsquo;s this
            story that you are trying to sell the hotel Nahil? Are you crazy?
            Hang on to it, real estate price is going to skyrocket in the near
            future&rsquo;&rsquo;. Nahil took his advice and held on. He reckons
            MR was his best financial consultant, better than his bank&rsquo;.
            Suddenly the war was over, the price doubled, and Nahil was rocking
            it!
          </p>
          <p>
            Nimal Perera, and Margu Murugeswaran, with the help of Nimal,
            Dhammika Perera&rsquo;s lieutenant, made a bid for the hotel. East
            West owned 95% of the whole business, though it was a public quoted
            company. The public holding was negligible. Hayleys, due to a
            shortage of finances, bought 51%, getting the controlling interest
            of the hotel whilst the balance was sold in the open market, which
            was arranged by Nimal Perera. Hayleys may have bought the bulk of
            the remaining shares subsequently. Each time your total holdings go
            beyond 30% you have to make a general offer to the other
            shareholders to buy at the same price.
          </p>
          <p>
            After Nahil sold Hotel Intercontinental to Hayleys who bought 51% of
            the shares, Nahil remained the Chairman of the hotel until
          </p>
          <p>
            Hayleys made a general offer after which the old board appoints a
            new board while the former retires, giving time for the remaining
            49% shares to be listed at the CSE.Nahil realized he had not been
            paid his monthly stipend of Rs.1 M, for three months, and inquired
            from Mohan Pandithage as to why he was not being paid. Mohan was
            brash and told him that his claim for the Rs. 1M ceased the day he
            bought the hotel from Nahil. This was so utterly childish and
            completely out of order. Nahil suggested to Mr. Margu to send Mohan
            for accounting classes. His three million rupees is still
            outstanding. Nirj Deva Aditthiya got himself appointed to the new
            board of directors and always uses the Presidential suit whenever he
            arrives for a board meeting. Though he has done nothing he still
            uses the Presidential suit.
          </p>
        </Col>
      </Row>
    </div>
  );
};
