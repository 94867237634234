import { Row, Col, Image } from "antd";

export const ETVSold = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="etv-sold">ETV Sold!</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "350px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/44-etv-sold/lmd-nahil-july-2002.jpeg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "350px" }}>
              <p>
                <small>LMD Magazine July 2002</small>
              </p>
            </div>
          </div>
          <p>
            John Keells never had an opportunity to make a bid for ETV, since
            the Edirisinghes had already expressed their interest. Nahil did
            extensive research into their business holdings and managed to
            procure an introduction to Jeevaka Edirisinghe through a lady
            working for East West, who knew him. EAP had a vast majority of
            ventures that included liquor licenses, gold trading, pawn brokering
            and finance; businesses that accumulated quite a bit of
            un-accountable cash. Using this as his trump card, he informed
            Jeevaka that he knew they had some unaccountable cash in their
            various companies. Since ETV was a BOI project which had a 10 year
            tax holiday, they couldchannel all their taxable income to ETV as an
            income tax free company. Printing and media businesses are
            tax-deductible accounts, therefore ETV as atax free company was
            un-tangible and could technically be his &lsquo;laundry&rsquo;,
            Jeevaka&rsquo;s eyes lit up, and it was sold!&rdquo;
          </p>
          <p>
            Nahil remembers wishing he had started with a higher quote, although
            he decided to let it go and moved on to his next venture. In
            retrospect, thinking of the sale, the letter &lsquo;E&rsquo; could
            have played a part in the deal too. ETV stood for Extra Terrestrial
            Vision while the EAP group wanted it to be called Edirisinghe TV.
          </p>
          <p>
            One year into his clearing and forwarding operation, Dan married
            Kamini De Silva &ndash; a purser at Air Lanka. Ironically the
            wedding was at his residence down Davidson Road, Bambalapitiya,
            behind the St. Peter&rsquo;s College Grounds. This house was
            previously occupied by Maharajah. Dan&rsquo;s little physiological
            warfare with his ex-employers was fully on. After the &rsquo;83
            riots, he went on to buy the Maharajah Building on Bankshall Street.
            This was a major achievement. He won his war.
          </p>
          <p>
            Later on, around 1986, Lester and Nahil quit their partnership. By
            this time Nahil was fully involved in the property scene. He offered
            Dan a chance to buy the clearing and forwarding operation from them.
            This was all Dan had and as they were all getting old and the
            chances that the next generation would be interested in taking over
            the businesses were questionable. Still Dan did not seem to be
            confident enough and refused. Therefore Nahil came up with a
            strategy to give Dan a stepping stone.
          </p>
          <p>
            It was decided to run the organization without a board or meetings.
            He was virtually thrown into the deep end and managing C&amp;F all
            on his own and doing an extremely good job of it. He gained the
            confidence necessary and agreed to the sale. His only request was to
            continue to keep the name East West. This is the only company that
            was sold from the EW group that still carries the East West name,
            viz., East West Clearing &amp; Forwarding. Dan&rsquo;s clearing and
            forwarding business is located in proximity to the East West
            warehouse complex which Dan occasionally claims, to be a part of his
            operation.
          </p>
        </Col>
      </Row>
    </div>
  );
};
