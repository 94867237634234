import { Row, Col, Image } from "antd";

export const MeetingTuulaInSweden = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="meeting-tuula-in-sweden">Meeting Tuula in Sweden</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            There was a scholarship being offered to Stockholm, Sweden for
            Maintenance Management, a course carried out by Idharmar Consult,
            Sweden, offered through the Chamber of Commerce Sri Lanka. On a
            whim, Nahil&rsquo;s Secretary, Yasmin, since Nahil was handling
            haulage work and very much into maintenance management, filled the
            form and submitted it. To his suprise being a senior director of a
            company, he was awarded the scholarship and headed to Sweden,
            driving from London to Stockholm&nbsp;&nbsp;over 2000 km which
            included three ferry crossings and took three days by himself to get
            there&nbsp;&nbsp;driving a Porsche 924.&nbsp;
          </p>
          <p>
            Settling down to one month in Sweden, he started on the maintenance
            course that included 45 individuals from different parts of the
            world. Two weeks into the training, now utterly bored with this
            &lsquo;maintenance&rsquo; thing, he thought of meeting up with
            Tuula, his Finnish ex-girlfriend. He had lost track of her over the
            years, bur remembered that she had an uncle, Koskinnens, living in a
            village known as Kellakoski, close to Helsinki with whom Nahil had
            shared a meal, on his visit to see Tuula about 20 years prior. He
            drove straight to the post office that had all the Scandinavian
            telephone directories. Glancing through, he found four Koskinnens.
            Taking all four numbers down, he got back to the hotel intending to
            call all four numbers with the help of a Finnish-speaking guest at
            the hotel. He made a strike on the third attempt, getting through to
            Tuula&rsquo;s uncle. Introducing himself, he requested information
            on her whereabouts. He remembered Nahil and obliged by informing him
            that she was working in Stockholm for the Moskvitch car company as a
            secretary. That afternoon when he drove to her workplace she was
            visibly astounded to see him driving in a Porsche to visit her.
            After a bit of catching up as 20 years had gone by and she was
            involved with a Swedish guy, she invited him to join her for dinner
            at her sister Anja&rsquo;s home where she was living at the time.
            During his visit, he noticed on the mantelpiece the old Castanets
            and photographs that Tuula and he had taken together in Spain while
            driving around Europe two decades ago.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/24-meeting-tuula-in-sweden/porche-24-sweden.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Porche 924 used in Sweden</small>
              </p>
            </div>
          </div>
          <p>
            Since that meeting, he has tried to get in contact with her on
            numerous occasions, but it has been a rather futile effort. A few
            weeks ago, after spending a few hours searching for her on social
            media he thought his attempt was successful, only to find that it
            was a different&nbsp;&nbsp;female with the identical name. Now in
            her seventies, he often wonders what she looks like.
          </p>
          <p>
            He recalls, during the time he was living with Tuula in the UK,
            they, together with Thomian Thirikumar Ratnabalasuriya, toured the
            continent in his VW Variant, visiting Barcelona, Spain. The three of
            them were so drunk on cheap Spanish wine that once, while at a
            bullfight, to the amazement of the other spectators, they were
            cheering the bull instead of the matador! Before returning to London
            they drove to Finland, youth hostelling on the way till they got to
            the home of Tuula&rsquo;s uncle Koskinnens. She was not too keen to
            take him along to visit her village high up in the north. This was
            during the late 60s, obviously, her &lsquo;dark-skinned&rsquo;
            boyfriend would have caused a proper rumble...
          </p>

          <p>
            Nahil returned to Colombo in three weeks, from what was supposed to
            be one month&rsquo;s training in Sweden. Avril&rsquo;s mom was not
            happy with the two of them. In her words they were &lsquo;living in
            sin,&rsquo; embarrassing the family, etc. Her concerns, including a
            few other challenges, spurred them to make the relationship legal
            and they got married on the 10th of June that year. The signatories
            were Sonia Virasinghe for Avril and Lester Weinman for Nahil.
          </p>
          <p>
            The marriage was registered with a civil ceremony at
            their&nbsp;&nbsp;residence, followed by a &lsquo;rocking&rsquo;
            celebration at The Ceylon Intercontinental Hotel that evening.
            &ldquo;The party was fantastic,&rdquo; says Nahil. &lsquo;&lsquo;All
            our family and close friends were there to celebrate with
            us.&rsquo;&rsquo; He remembers that almost all of the guys at the
            party were pretty boozed up by the end of the evening.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/24-meeting-tuula-in-sweden/friends.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>
                  L-R: Bandula Molligoda, Thirikumar Rathnabalasuriya, Kume,
                  Lokika Soyza, Premlal Galagoda, Pancha Gunaratne and Nandalal
                  Molligoda
                </small>
              </p>
            </div>
          </div>
          <p>
            During this period they were living in a rented home on 16th Lane in
            Colombo 03, after which they bought a beautiful house on Flower
            Road, No.33, from Bunchy and Goolbai Gunasekara, re-modelled the
            house and moved in. Unfortunately, the noise pollution factor was
            overlooked when the house was purchased and they sold it as fast as
            they bought it, moving into an apartment at 121/1 Turret Road, owned
            by the Thahas, after a few months there they finally settled down at
            128, Reid Avenue in a beautiful house that underwent a major
            renovation before they moved in. During this time they travelled to
            London quite frequently and subsequently bought a mews house in the
            heart of London, No.2, London Mews, London Street, London W2. Since
            Avril dabbles in interior designing, she decided to stay back and
            re-furbish the house while Nahil travelled between the two cities
            every few weeks.
          </p>
          <p>
            After getting the house in order, Mahen who was &lsquo;fresh off the
            boat&rsquo; literally, to the UK, to pursue his degree at University
            of London, moved into the mews and Avril returned to Colombo. Life
            went on.
          </p>
          <p>
            While they lived down Reid Avenue which was at the Lauries Road end,
            their opposite house neighbour was A.J. Ranasinghe of
            &lsquo;Sapaththu soup&rsquo; fame, a great friend of President
            Premadasa.
          </p>

          <p>
            Although Nahil had acknowledged the guy a few times with a nod of
            his head on his way to office and back,&nbsp;&nbsp;he had never had
            an opportunity to speak with him. One evening returning home after
            work he noticed a line of vehicles parked outside AJ&rsquo;s
            premises. As Nahil got home, Avril informed him that AJ had
            personally invited them for dinner at his home that evening. Now,
            this was an event he did not wish to miss. They went all dressed up.
            Curious to know of the occasion being celebrated, Nahil inquired
            about it from AJ and he answered back saying, &ldquo;Don&rsquo;t you
            know, I am going to Canada to join the High Commission of Sri
            Lanka.&rdquo; or something to that effect. &ldquo;Wow!&rdquo;
            thought Nahil. AJ was the manager of the Tower Hall at the time when
            he had a heart attack. Mr. Premadasa was responsible for calling an
            ambulance to take him to hospital after which he made arrangements
            to fly AJ overseas where a heart by-pass surgery was performed on
            him by Dr. Don Michael.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/24-meeting-tuula-in-sweden/nahil-avril-wedding.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>
                  Nahil and vril wedding: L-R standing: Ken, Dan, Vishu, Nahil,
                  Faleel, Mark. Seated L-R: Ranjan Kum, Minoli, Baba and Sonia
                </small>
              </p>
            </div>
          </div>
          <p>
            As the evening went by, getting merrily high on premium whiskey,
            Nahil cheekily asked him what his function in Canada would be.
            &lsquo;&lsquo;I need to get my heart by-pass checked every month and
            also my sons are studying in the USA, I can go to see them&rdquo;
            was the answer. Nahil thought AJ had misunderstood him and repeated
            his question, to which he got the same answer. He was thinking that
            he was &lsquo;taking the mickey&rsquo; of AJ and realized later that
            AJ was doing the same to him. They had a good time boozing that
            evening.
          </p>
          <p>
            Still at Reid Avenue, Nahil recalls driving home one evening to find
            a line of yellow barricades and security at both ends of Reid
            Avenue. Questioning the security he was informed that the barricades
            were up, on the instructions of AJ. He called AJ the next morning
            and conveyed to him that their neighbours, the Lalvanis, and he did
            not have any security problems. AJ had his reply ready:
            &ldquo;Okkoma hithenawa mē pare harima important aya innawa
            kiyala&rdquo; (everyone thinks there are very important residents
            living down this road). By now Nahil figured this guy was honest and
            forthright; he counted Nahil among the &lsquo;very important&rsquo;
            too.
          </p>
        </Col>
      </Row>
    </div>
  );
};
