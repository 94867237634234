import { Button, Form, Input, Modal, message } from "antd";
import axios from "axios";
import { useState } from "react";
// import axios from "axios";

export const AddComments = ({ title }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const postSlackMessage = ({ params }) => {
    return axios
      .post(".netlify/functions/postSlackMessageChannel", {
        params,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function error(error) {
        const errorMessage = error.response.data;
        console.log(errorMessage);
      });
  };

  const onFinish = (values) => {
    form.validateFields().then((values) => {
      const success = () => {
        message.success({
          style: { marginTop: "20vh" },
          content: "Thanks, will reach out soon..!",
        });
      };
      const slackMessageData = {
        //enter slack url here
        params: {
          url: "",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Contact Message from storage.lk!",
                emoji: true,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `Name: ${values.name} \n\n Email: ${values.email} \n\n Contact Number: ${values.tel} \n\n Comments: ${values.message}  `,
              },
            },
          ],
        },
      };
      postSlackMessage(slackMessageData);
      handleOk();
      success();
      form.resetFields();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const CommentsForm = () => {
    return (
      <div className="fontStyleButton">
        <Button
          style={{
            backgroundColor: "salmon",
            color: "white",
          }}
          shape="round"
          onClick={showModal}
          type="link"
        >
          Add Comments
        </Button>
        <Modal
          title={title ? title : "Add Comments"}
          centered
          okText="Send"
          okButtonProps={{
            type: "primary",
            style: { backgroundColor: "salmon" },
          }}
          open={isModalOpen}
          onOk={onFinish}
          onCancel={handleCancel}
        >
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ message: "" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
          >
            {/* <Row gutter={18}>
              <Col xs={24} sm={24} md={12} lg={24}>
                <Form.Item
                  style={{ margin: "5px" }}
                  label=""
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name" },
                  ]}
                >
                  <Input placeholder="name" autoComplete="name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={24}>
                <Form.Item
                  style={{ margin: "5px" }}
                  label=""
                  name="tel"
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact number!",
                    },
                  ]}
                >
                  <Input placeholder="Contact Number" autoComplete="tel" />
                </Form.Item>
              </Col>
            </Row> */}
            {/* <Form.Item
              style={{ margin: "5px" }}
              label=""
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input placeholder="email" autoComplete="email" />
            </Form.Item> */}
            <Form.Item
              style={{ margin: "5px" }}
              label=""
              name="message"
              rules={[{ required: false, message: "Please input your email!" }]}
            >
              <TextArea placeholder="Add comments / notes here" rows={5} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  };

  return <CommentsForm />;
};
