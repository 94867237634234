import { Row, Col } from "antd";

export const NahilVsJohnKeells = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="nahil-visits-john-keells-holdings">
          Nahil visits John Keells Holdings
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Ken was a family friend from his days as a planter in Ratnapura,
            during the period Nahil’s sisters were working as doctors, at the
            Ratnapura hospital. Being very straightforward with his offer, he
            wasn’t too bothered about a dialogue with Ken. Responding to Nahil’s
            offer, Ken says, “Nahil there are too many TV stations and all of
            you will go broke.” He indicated that they would be interested in
            ETV if there was a means of shutting down two or three of the
            existing TV stations.
          </p>
          <p>
            Ken requested Nahil to join him at a discussion with the JK Board at
            their Glennie Street Head Office. On his arrival at Glennie Street,
            Nahil noticed that the entire Board, including six Directors not
            known to him, was present in the boardroom.
          </p>
          <p>
            Peering down at them from the walls of the boardroom were
            photographs of a set of bearded men. Ken pointed to the photographs,
            informing Nahil that the men in the photographs were all in the
            produce trade, at loggerheads with each other, until one fine day
            they joined forces and majored the trade monopoly at the time.
            Uncontrollably laughing since the present Board was looking for
            monopolies like their predecessors did, Nahil asked Ken if he liked
            to play cricket without anyone bowling at him – “No competition,” he
            said jokingly, so no one was offended. They discussed many other
            matters, the most important which he vividly remembers is how they
            wanted all competition eliminated as a way to grow.
          </p>
          <p>
            A few days later it was the opening of the first Pizza Hut at Union
            Place. He congratulated them while asking in jest if they intended
            getting all the ‘thosai kades’ in the vicinity to shut down in wake
            of the Pizza Hut opening!
          </p>
        </Col>
      </Row>
    </div>
  );
};
