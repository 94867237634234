import { Row, Col } from "antd";

export const BlomendhalRoad = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="168-blomendhal-road">168 Blomendhal Road</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Before the Peliyagoda development was initiated, the original
            project started by East West was at 168, Bloemendhal Road, - a Dutch
            name that means &lsquo;&lsquo; Valley of Flowers&rdquo;. The third
            guy in their business was an old Royal College buddy of
            Nahil&rsquo;s, Jothy Godage. At the time, EWE was operated from the
            Ceylinco building and Jothy worked for EWE as an accountant, though
            not qualified as one. &ldquo;He was the ultimate party guy with zero
            business acumen or anything of consequence,&rdquo; says Nahil. There
            was a property on Bloemendhal Road, owned by Jothy, which he
            invested in East West. Nahil, being the go-to guy when it concerned
            a challenge, took it upon himself to clear out and relocate the
            40-odd shanty dwellers occupying this land.
          </p>
          <p>
            Gathering them all for a meeting, he informed them that each family
            would be paid a sum of Rs. 75,000, which will be paid after they
            find a place and move out lock, stock and barrel. Part of the deal
            was for each family to dismantle their dwelling, pack it up and take
            it with them, and letting Nahil know where they were moving to, so
            he could provide transport to move the bits and pieces. In this
            manner he moved away 40 dwellers very peacefully.
          </p>
          <p>
            Indubitably in these situations, there is always a bad apple: this
            time it was a man named Marshall known as &lsquo;Well Kārayā&rsquo;
            who occupied a stretch on the front edge of the land that had a
            well, which was quite a large section.
          </p>
          <p>
            The guy pumped water from a road side well and sold it to people in
            the area, at 25 cents a bath, making good money. He also carried out
            a roadside car wash in the vicinity of the well. It was mentioned to
            Nahil that Marshall was a thug in the area. &ldquo;I think I was the
            bigger thug of the two, perhaps a &lsquo;peaceful thug,&rsquo; if
            there&rsquo;s anything like that,&rdquo; says Nahil. Doing something
            like this takes courage because it is not necessarily the accepted,
            safe or practical thing anyone will do. His intrinsic belief in his
            ability motivated him. He said to Marshal &ndash;&ldquo;Balanna api
            methana develop karanne, anith kattiya okkama giya oya witharai
            othana inne&rdquo; &ndash; (&ldquo;we are developing this land,
            everyone has vacated, you are the only person staying on&rdquo;). He
            says, &ldquo;Hungak kal indala, kohe yannada?&rdquo; &ndash;
            (&ldquo;I&rsquo;ve lived here so long, where do I go?&rdquo;) Nahil
            offered to buy Marshall two or three three-wheelers worth around
            hundred thousand rupees each, also relating to him an interesting
            tale, that he was in the process of donating this land to the
            Municipality, to provide free bathing and toilet facilities to the
            public. To make it sound more dramatic, Nahil had mentioned to him
            that he&rsquo;d already spoken to the area Municipal Council member,
            who besides taking credit for all this, was elated with this gesture
            and had instructed the council to dismantle all the unauthorised
            structures. Two weeks later Marshall says, &ldquo;Ah ehenang Sir,
            mung yannang&rdquo; &ndash; (&ldquo;Ah then Sir, I will
            leave.&rdquo;)
          </p>
          <p>
            Finally, they paid him off and he moved. Next to the &lsquo;Well
            Kārayā&rsquo; further down the road, on the same stretch of land,
            was a motor repair shop owned by Mr. Vythiyalingam, a humble gent,
            who was also paid off and moved away at their request.
          </p>
        </Col>
      </Row>
    </div>
  );
};
