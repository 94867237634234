import { Row, Col } from "antd";

export const HisTypicalDay = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="his-typical-day">His Typical Day</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Nahil is up by 5 a.m. most mornings, around the time the Asian
            markets open. New Zealand opens at 8 a.m. NZ time, which is around
            3:30 a.m. SL time. He gets to bed each night waiting for sunrise so
            he can get to the market place and do something constructive.
            Sitting at his computer, he does some gold trading and if he makes
            over a million bucks in that day&rsquo;s trading, he takes a shot of
            his spreadsheet and sends it to the three children, and his two
            sisters, who keep advising him against sending it to the kids,
            saying, &ldquo;They will think this is an easy way to make
            money.&rdquo; He counters the claim by telling them that he makes a
            million in three hours because he is right, even though it&rsquo;s a
            bit of a gamble. This is not a daily occurrence and losses are
            inevitable. His daily objective is to be doing something useful to
            keep the ticker going and the grey cells active. He is not a
            &lsquo;gym-goer.&rsquo; He says he has never worked out in a
            gymnasium per se. He has a treadmill in his room on which he works
            out for ten minutes, three times a week &ndash; trying to emulate
            Lee Kwan Yew, a hero. He believes his routine of keeping himself
            occupied by doing something useful beats gym time any day. Each time
            the adventure of the journey gets him to his destination with the
            job well done. His big issue is delegating. Each time he delegates,
            he loses money; whereas some people are experts at delegating and
            getting things done. With him, it&rsquo;s hands-on from start to
            finish. With the hotel recently sold, he works from home, which he
            seems to enjoy, cutting time spent on the road, thus channelling
            that time into doing something productive.
          </p>
          <p>
            He kick-starts his day with a cuppa and fruit followed by a light
            Sri Lankan breakfast bathala or kiribath with pol and lunumiris is
            at the top of his list, checking his day&rsquo;s schedule on his
            computer while having his breakfast. This time is also spent
            answering the many WhatsApp messages from his portfolio of
            &lsquo;baes&rsquo; in the Philippines, who appeal for financial
            help. He faithfully makes his way to Orchard road to send them the
            money through Kabayan, one of the many Philipino money transfer
            agencies located at Lucky Plaza. Reaching out to them, he is helping
            some of the hard pressed people in a third world country live with
            some dignity. He says, while he was not in a position to solve the
            problems of the world, he believes in helping those he comes across
            in daily life. &lsquo;&lsquo;If they need my help, I don&rsquo;t
            hesitate to help them financially.&rsquo;&rsquo;
          </p>
          <p>
            He generally requests those he has appointments with to call him an
            hour before their meeting since scheduling it on his phone is a
            wasted exercise, considering someone has to remind him to check his
            phone! By the way, he insists that it has been researched and proven
            that &lsquo;forgetfulness is a sign of intelligence&rsquo;. He
            forgets who said this...
          </p>
          <p>
            Being a citizen of Singapore, Nahil spends his days dividing his
            time between his homes in Singapore and Colombo, but with C-19
            rampant in Singapore, he now lives in Colombo.
          </p>
          <p>
            Life has come full circle. His three children are all settled and
            well provided for, while he is once again a true blue bachelor.
            Interestingly, he is technologically on ‘fast-forward,’ always
            online, looking up new and exciting developments and keeping abreast
            of the happenings around the world. All this helps him keep the grey
            cells buzzing and his mind active, which he emphasises is important
            as one grows older.
          </p>
        </Col>
      </Row>
    </div>
  );
};
