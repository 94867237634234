import { Row, Col } from "antd";

export const FriendsIndraniAndAnarkali = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="friends-indrani-and-anarkali">Friends Indrani and Anarkali</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Indrani Jayatilake, Anarkali&rsquo;s mom, was originally a friend of
            Roshanne at the time Vijitha and Anika were studying at CIS. Anika
            and Anarkali were classmates. This was when they were seven or eight
            years old. Roshanne and Indrani would often meet at the school
            whilst picking up the kids, forging a friendship with each other
            since their little daughters were best friends.
          </p>
          <p>
            Vijitha and Anika were in their early teens when Roshanne moved on
            with her life, living in her home off Dickmans Road. She still
            maintained her friendship with Indrani until she realised that
            Indrani was in a dalliance with Nahil.
          </p>
          <p>
            Indrani and Anarkali would visit Anika often at Nahil&rsquo;s
            apartment atop the IBM building. Indrani&rsquo;s husband had moved
            to the UK, leaving Indrani and a young Anarkali behind due to family
            issues between him and Indrani, leaving mother and daughter living
            alone in the suburbs. When Nahil discovered the rather remote
            habitat, distraught, he immediately rented out a small first floor
            apartment that belonged to Sri Sagadevan&rsquo;s family down Rosmead
            place and instated mother and daughter in the apartment where they
            lived in comparative safety thereafter for five years with Nahil
            taking care of the rent.
          </p>
          <p>
            Subsequently, Roshanne found out about a dalliance between the two
            and was extremely annoyed, though she never brought the subject up
            with Nahil, instead, it was Anika who was angry and voiced her
            opinion in no uncertain terms saying, &ldquo;This is ammi&rsquo;s
            friend and you are in a relationship with her.&rdquo; Very soon,
            Anarkali became Anika&rsquo;s &lsquo;enemy number one&rsquo;.
          </p>
          <p>
            A month into the dalliance, Indrani and Nahil mutually uncoupled,
            though he made it his mission to continue taking care of mother and
            daughter who were in dire straits at the time. He attended to things
            like school fees for Anarkali and other sundry requirements.
          </p>
          <p>
            At the time Nahil purchased the Hotel Intercontinental, Anika was in
            the USA and Indrani and Anarkali moved into the General
            Manager&rsquo;s apartment in the hotel. On completion of her studies
            in the US, Nahil requested Anika to return and get involved in the
            operation of the hotel which she did. However the deal was that
            Indrani and Anarkali should vacate the hotel before her arrival. All
            this was virtually overnight.
          </p>
          <p>
            Feeling responsible for the mother and daughter, he bought them an
            apartment at the Iceland Building, where they settled down.
          </p>
          <p>
            On a photo-shoot or something related to it in Los Angeles, Anarkali
            met her future husband, Dishan, son of Dr.Walter Jayasinghe. Dr.
            Jayasinghe was one of the wealthiest Sri Lankans in the USA, and
            also owned a few hospitals. Anarkali married Dishan and is now
            settled in Los Angeles, while Indrani lives in Colombo. It must be
            noted that the Jayasinghe family are ardent adherents of the Church
            of Scientology in Los Angeles, with Anarkali a willing follower.
            Nahil and Indrani meet occasionally and are still friends. &ldquo;I
            am really pleased things turned around for the two of them,&rdquo;
            says Nahil.
          </p>
        </Col>
      </Row>
    </div>
  );
};
