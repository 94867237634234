import { Row, Col, Image } from "antd";

export const MohanShayam = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="mohan-sahayam">Mohan Sahayam</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/29-mohan-shayam/nahil-mohan.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Nahil and Mohan Sahayam</small>
              </p>
            </div>
          </div>
          <p>
            During the period his closest buddy Mohan Sahayam was the Manager of
            Maritime Agencies, which was a part of Hayleys, many years ago,
            Mohan Pandithage, the present Chairman of Hayleys, worked under him.
            Mohan Pandithage&rsquo;s father was quite a guy who, with his
            influence at Hayleys, sent Mohan P. to work there as a trainee,
            under Mohan S., in the shipping business without pay and perks.
            Notwithstanding his circumstances, Mohan P. had his own car and
            would drive to the port visiting ships. The guy though very
            enthusiastic, had a set back in communicating in English, especially
            with the ships&rsquo; crew, which was a hindrance to his progress.
            Thus Mohan S, in consultation with the Chairman of Hayleys, L.
            Jayasundara, decided to reward Mohan P. by sending him for English
            Language classes to the Wendy Whatmore Academy, thereby helping him
            to sharpen his English language communication skills. After a while,
            Mohan S, feeling sorry for the guy, put him on a monthly stipend.
            One thing led to the other and M.Pandithage got a firm foot into
            Hayleys. &ldquo;I remember telling Mohan Sahayam&rsquo;s wife,
            Penny, &lsquo;&lsquo;If you hadn&rsquo;t taken this guy to PNG and
            then to Australia, away from Hayleys, he would have been
            Chairman.&rsquo;&rsquo; Being a smart lady, she averred,
            &lsquo;&lsquo;If he remained in Sri Lanka, he would have died of
            cyrosiss, because he was clubbing so much.&rsquo;&rsquo; No argument
            &ndash; guess she is right.&rdquo;
          </p>
          <p>
            While in college, Mohan&rsquo;s dad, Mr. Sahayam, the Vice Principal
            of Trinity College, was one of the best maths teachers at Trinity.
            During his senior year, Nahil felt he required maths tutoring and
            approached Mr. Sahayam, to discuss the possibility of after school
            maths classes, which he readily agreed to. He generously offered
            free tuition to him, on condition he brought Mohan along for the
            classes, which he did...
          </p>
        </Col>
      </Row>
    </div>
  );
};
