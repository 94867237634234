import { Row, Col, Image } from "antd";

export const Nd2EngineeronLankaKalyani = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="2nd-engineer-on-lanka-kalyani">
          2nd Engineer on ‘Lanka Kalyani’
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            On his return from training in Switzerland, he re-joined P&O Lines,
            sailed a few voyages and then resigned and returned to Sri Lanka,
            joining Ceylon Shipping Corporation as Second Engineer on the ship
            ‘Lanka Kalyani’ captained by Baba Virasinghe, the first Sri Lankan
            Captain, whose predecessors were all British
          </p>
          <p>
            Their first voyage together was to Rangoon via Singapore, their
            first port of call. Baba had made the news as the first local to
            captain a Sri Lankan ship. They had just taken the ship over from
            the Chinese with absolutely no prior knowledge of the functioning of
            the vessel – things like where exactly to trace the pipelines or any
            function in the area of engineering. They sailed two hours after
            taking over the ship, with Nahil leaving it to chance.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/10-2nd-engineer-on-lanka-kalyani/training-certificate-sulzer-mde.jpg"
              }
            />
            <div style={{ textAlign: "center" }}>
              <p>
                <small>Training Course for Sulzer Marine Diesel Engines</small>
              </p>
            </div>
          </div>
          <p>
            While they were sailing on a river in Rangoon that looked like a
            sea, a pilot had to guide them through a channel deep enough for the
            ship. “Suddenly,” says Nahil, “I heard the engine slowing down
            because the diesel tanks were running out of fuel.” He was in his
            cabin when he heard the engines slowing down and was flustered as he
            had had no time to trace the pipelines before leaving Singapore. He
            knocked the tanks to pump up by which time the generator was
            exhausted and the engines slowed down further. Finally, he messed
            around with the pipes to help the trapped air to escape and realised
            the tanks were filling up, which helped him prime the engines and
            get it started.
          </p>
          <p>
            Without the engines, the ship drifts and it can’t be controlled with
            the rudder; controlling the vessel with the rudder is possible only
            when the engine is on full throttle. To his chagrin, the navigator
            was requesting full speed, unaware the engine was not functioning
            properly. Nahil took about five minutes to get it all sorted and
            thankfully they were on their way. This was when he told Baba,
            “First Sri Lankan Captain of a ship, but you could have also been
            the first Sri Lankan Captain to run the ship aground.” They are
            still great friends and famously contradict each other’s version of
            the incident. Sadly after a stroke a few months back, Baba passed
            away.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/10-2nd-engineer-on-lanka-kalyani/nahil-cabin-mv-chakdhara.jpg"
              }
            />
            <div style={{ textAlign: "center" }}>
              <p>
                <small>Nahil in his cabin on MV. Chakdhara</small>
              </p>
            </div>
          </div>
          <p>
            Towards the latter part of 1975, his father had heard about his
            dalliance with a divorcee with two kids, whom Nahil would shack up
            with whenever his ship came into Sri Lanka. As you can imagine, once
            his father heard about this through the ‘grapevine’ he was livid.
            One fine day, on being informed that Nahil was at the Wijemanne
            flats in Green Path with her, he made his way there and sent up a
            message to Nahil. “I came down to meet him and he says, ‘What you
            are doing is bloody disgraceful. If your mother was alive you would
            have never done something like this.’ Saying this, he started
            crying.’’ In a moment of self pity, Nahil blurted out, ‘‘It seems
            everything I do is wrong. Why don’t you find someone for me? If I
            can get on with 30 hairy seafarers for months on end on a ship, I
            can get on with anyone.”
          </p>
        </Col>
      </Row>
    </div>
  );
};
