import { Row, Col } from "antd";

export const LesterAndNahilEndTheirPartnership = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="lester-and-nahil-end-their-partnership-at-east-west">
          Lester and Nahil End Their Partnership at East West
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Consequent to the family drama, with Lester and Nahil&rsquo;s
            business relationship on a downward spiral and things awkward
            between them, Nahil decided to end the partnership so that both
            parties could move on without too much bad blood between them.
            Incidentally, when Lester sold Sri Lanka Shipping to Hayleys, he
            called Nahil just to keep him in the loop. In cognisance of their
            successful business relationship over the years, they continue to
            keep things casual and cordial between each other, occasionally
            meeting up for a beer and chat.
          </p>
          <p>
            Initially while going in for the legal separation of the company,
            Lester took Nahil to court. After the initial decision to part at
            50-50 each down the line, Nahil realised he was handing it over
            without contention and shifted the majority shares to himself,
            increasing it by two shares so he could take the Chairmanship of the
            company. His strategy was to issue new shares and dilute
            Lester&rsquo;s shares to the brink. Predictably they ended up with a
            day in court.
          </p>
          <p>
            His buddy Anthony Page (Baba), Chairman of Cargills, was present
            each time he had a legal muddle in this case and he told Nahil,
            &ldquo;A bad settlement is better than a good judgment. Just
            negotiate this muddle and settle the issue without wasting your time
            hanging around in court.&rdquo; Adhering to his advice, he settled
            with Lester, handing him the shipping company while he took over the
            TV station, property and other businesses.
          </p>
          <p>
            Amidst this entire hullabaloo, Sonya, Lester&rsquo;s second wife,
            obviously shattered by Lester&rsquo;s indiscretion, left him. Nahil
            was now a part of the injured party of two. Sonya was taking this
            betrayal rather badly when Nahil asked Sonya to put all this drama
            aside and get back to Lester, because she has passed her &lsquo;best
            before date.)
          </p>
          <p>
            Funnily, this was the second time Nahil had faced a similar
            situation with one of Lester&rsquo;s wives. Years earlier, while
            Lester and Sonya were doing the &lsquo;Me and Mrs. Jones&rsquo;
            thing with each other, she had journaled in detail all her trysts
            with Lester and left the journal somewhere conspicuous only for her
            husband to find it and...
          </p>
          <p>
            Lester was married to Minoli, who was absolutely mad with both Sonya
            and Lester. Before this, all four spouses were close friends,
            travelling around the island and overseas together. Lester and
            Minoli lived in a nice house down Kynsey Road when this drama took
            place. Devastated, Minoli had requested Nahil to find her a place to
            stay. He advised her against moving out, insisting that she remains
            there and gets the house transferred in her name. Nahil says this
            was the best decision Minoli made, otherwise she may have ended up
            at an elders&rsquo; home or similar. In the days that followed, Baba
            and Minoli the victims, were comforting each other, thankfully
            ending up marrying each other. A few months later they bought
            Nahil&rsquo;s house on Reid Avenue. They still live there and it all
            ended happily.
          </p>
        </Col>
      </Row>
    </div>
  );
};
