import { Row, Col, Image } from "antd";

export const PatentForMethodOfManufactureFixedDishAnetennas = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="patent-for-method-of-manufacture-fixed-dish-anetennas">
          Patent for Method of Manufacture – Fixed Dish Antennas
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/64-patent-for-method-of-manufacture-fixed-dish-anetennas/etv-dish-peliyagoda.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>15 meter satellite recieving dish</small>
              </p>
            </div>
          </div>
          <p>
            When Asia Sat 1, the first broadcast satellite, was launched to
            cover Asia, the beam centre was in Pakistan and the signal was very
            weak by the time it reached Sri Lanka.
          </p>
          <p>
            The only stations using this satellite were ETV and Maharaja for
            MTV, who had bought a 50&rsquo; diameter NEC receiving antenna for a
            few million dollars.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "200px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/64-patent-for-method-of-manufacture-fixed-dish-anetennas/patent-grant.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Patent Granted</small>
              </p>
            </div>
          </div>
          <p>
            ETV required only one signal from Asia Sat which was quite stable in
            its station allocation at 108 degrees. They decided to experiment
            with a fixed dish of 50&rsquo; diameter pointing at the Asia Sat 1
            built out of concrete. Nahil and his crew knew approximately where
            the signal was and constructed the antenna nearby. To fine-tune the
            exact location, they had an axis of this semi parabolic template
            that was to be cut to the shape of the reflecting surface. The axis
            was moved around till the pilot dish showed a faint signal from Asia
            Sat 1. This confirmed what was required to cut the parabolic shape
            for the 50&rsquo; diameter. Once the shape was cast, they lined the
            50&rsquo; surface with reflecting kitchen foil stuck to the concrete
            with fiberglass resin and finished it at the pick-up point for the
            broadcast. They were receiving broadcast quality signals on all the
            channels. This dish cost just 3.5 million rupees akin to the MTV
            dish which was around US$30 million. Nahil patented for the
            alignment of fixed dishes. Shortly after, when he met Maha at a
            function, he inquired of him as to why he spent such a colossal sum
            of money on the NEC antenna for MTV.
          </p>

          <p>
            Nahil&rsquo;s buddy, Jim Sow, was keen to introduce the then,
            recently appointed CEO of MTV, Robert Barton to Nahil who was quite
            happy to meet him, the only condition being that Barton pays for the
            beers at the Hilton Sports Bar. During their conversation, Barton
            questioned Nahil as to why he was taking on Maharajas, which had
            very deep pockets; Nahil was quick to respond that this was the kind
            of challenge he liked, concluding with &ldquo;Game on, MTV!&rdquo;
          </p>
        </Col>
      </Row>
    </div>
  );
};
