import { Row, Col, Image } from "antd";

export const EastWestHaulage = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="east-west-haulage">East West Haulage</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            It was during this era that under the accelerated Mahaweli
            development programme the Victoria Dam project which was originally
            proposed in 1961, was speeded up by the newly-elected J.R.
            Jayewardene Government in 1977 prepared with the assistance of the
            United Nations Development Programme (UNDP) and the Food and
            Agriculture Organization (FAO), after a study of the project. The
            purpose of the proposed project was to ease economic difficulties
            within the country. The project was under the purview of the UNP
            Government’s Minister of Mahaweli Development, Mr. Gamini
            Dissanayake. The plan was implemented to irrigate 365,000ha of land
            and provide 470 MW of electricity. The construction of the project
            was inaugurated in 1978, with the implementation of the main
            structure in 1980 and completed in 1985 The construction of the dam
            tunnel was a joint venture between two British firms, Balfour Beatty
            and Edmund Nuttall, while the Constain group, a British technology
            based construction and engineering company carried out the
            construction of the power stations.
          </p>
          <p>
            After the project was sanctioned there was a fleet of local haulage
            companies, including East West Haulage, Cargo Boat Dispatch co,
            Renuka Transport and D.P. Jayasinghe – to name a few – with their
            sights set on clinching the transport for this project including
            similar projects being developed simultaneously. During this era,
            there were no 40 foot trailers available for road transport, with
            the only available trailer working inside the port maintained by
            Colombo Dockyard. The Kotmale project was being handled by a Swedish
            firm, Skanska.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/21-east-west-haulage/trailer-prime-cargo.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Transporting</small>
              </p>
            </div>
          </div>
          <p>
            It must be pointed out and highlighted that Mr. Gamini Dissanayake
            maintained a very professional approach to awarding the transport
            contracts. There was no way he could be influenced or pressured into
            channelling any of the contracts to family or friends in the
            business. The contracts were awarded strictly based on how well
            equipped and experienced the contractor was. East West Haulage was
            very well equipped, owning a fleet of sophisticated haulage
            equipment they had invested in, thus enabling it to clinch a good
            part of the Victoria Dam haulage, which eventually extended to
            Kotmale, Randenigala, Madhuru Oya and Kelanitissa. These were
            purpose-built haulers designed by East West. It was obvious to all
            that East West Haulage was transporting really large and difficult
            cargo to the dam sites, while the other contractors were hauling
            stuff like cement bags and steel reinforcement beams, among a host
            of other simple cargo. “It seemed that all the complicated cargo was
            directed for haulage only by us,” says Nahil.
          </p>
          <p>
            Among the ‘melting pot’ of foreign nationals involved in these
            projects was a German, Mr. Koslowski, a freight forwarder,
            representing the German shipper to whom East West Haulage was a
            subcontractor, responsible for hauling its freight from the Colombo
            Port to the dam site.
          </p>
          <p>
            Working directly for the German freight forwarder and not the
            locally-based contractor was an important advantage. Usually before
            a large piece of machinery was transported, the size of it in a box
            – length, breadth, height and a ‘3D’ image, pointing to the centre
            of gravity is sent with the pre-shipment details, for the hauler to
            plan out the transport accordingly.
          </p>
          <p>
            On receiving the specifications of a particularly large package, to
            which a quotation was forwarded according to the specifications
            received by East West, including the transport cost based on
            transporting the package along the shortest route to Kotmale, which
            was via Gampola. To their dismay the package, once it arrived posed
            a problem as it was a Swedish Koni Gantry crane which was a foot
            wider than the Gampola steel bridge. The hindrance was the catwalk
            on the gantry. Sourcing further route options they found the road
            via Wellawaya to be the next best option, though it was a major
            circuitous and dangerous route. A quote was forwarded to the shipper
            based accordingly, which ran into a colossal amount of money.
          </p>
          <p>
            The gantry crane is built like a trolley that moves vertically and
            functions as a lift for the turbines of the powerhouse during
            maintenance. Nahil suggested to Mr. Koslowski that they should cut
            off the catwalk on the crane, enabling it to be hauled on the
            original route, via Gampola. Immediately Kos wanted the price quoted
            to be reduced but Nahil insisted the price remains the same but if
            it made him happy, they could haul it along the circuitous route.
            Then Kos insisted the price should be reduced since they were going
            to cut off the catwalk of the crane. Nahil refused to reduce the
            price, requesting a letter from Kos to the effect that, if anything
            should happen to the equipment in transit since he insisted they
            take the circuitous route, even though a workable solution had been
            found, he would be responsible. Kos caved in and acceded to plan B,
            requesting that they weld the catwalk back by X-ray super first
            class welding. Nahil agreed to the request saying, “No worries!” He
            made a huge profit, considering the welder signed up to do the job
            was a former welder from Colombo Dockyard, who did it at no charge.
          </p>
          <p>
            It seemed that Mr. Koslowski a.k.a. Kos was a mini legend at EW. He
            had nothing to do with his time, thus wasting Nahil’s time as well
            by being a regular visitor to the East West office, while they were
            operating from the Cargills building in Fort. Whenever Nahil came up
            with a good idea, he’d say ‘Hey Nahil that’s a great idea,’and then
            he would return a couple of days later trying to sell Nahil’s idea
            back to him until Nahil would gently remind him that it was his idea
            to begin with. Subsequently, ‘doing a Koslowski’ was a term used at
            East West on anyone trying the same lark.
          </p>
          <p>
            As the Victoria project was coming to a close, they bought a 56-
            wheel trailer from Costain. This trailer was used to transport the
            penstock, a structure of big steel tubes that take the water down.
            This is the biggest trailer available on the island. The trailer is
            an all-wheel steer and the bed can be lifted about two feet off the
            ground. This trailer was used by East West Haulage for the
            Kelanitissa turbines since there were no cranes at the time capable
            of lifting these machines. The heavy load is put down by the ship
            which has the gear to load it on to this trailer, after which they
            would drive over the foundation, put bars across and lower the
            trailer. Once the turbines are placed on the steel bars, the trailer
            moves off. The turbine is lowered into the foundation using
            pneumatic jacks. Nahil found this extremely thrilling. He loved
            handling these operations personally, never letting anyone steal his
            joy. In retrospect, he says, ‘‘this was really simple stuff.’’
          </p>
        </Col>
      </Row>
    </div>
  );
};
