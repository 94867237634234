import { Row, Col, Image } from "antd";

export const NahilMovesToTheUK = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="nahil-moves-to-the-uk">Nahil Moves to the UK</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            After all the drama, Nahil and the family moved to London and Nahil
            decided to continue staying in the UK, living at College Gardens in
            Dulwich. The children were 10 and 12 years old and the family
            enjoyed living here.
          </p>
          <p>
            The real estate market in the UK was at an interesting paradigm. The
            regulations for rates were based on the square footage of a property
            with the taxable rate being any area above five feet. The basement
            areas under the regulated height were used for storage, considering
            this space was not taxable. Over the years the rules changed with
            taxes based on the regions, etc. Nahil took advantage of the changes
            in tax regulations, adding an extra floor by digging into the
            basement of his property up to foundation level, creating a
            seven-foot basement which was the regular height and space,
            equivalent to an extra floor thus adding more value to his property.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/46-nahil-moves-to-the-uk/hsmbeldon-place.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>
                  11, Hambeldon Place - Margaret Thatcher's old house
                </small>
              </p>
            </div>
          </div>
          <p>
            He re-modeled and sold around 20+ gated properties, one of which
            belonged to Margaret Thatcher, who served as the Prime Minister of
            Britain from 1979 to 1990, situated at 11 Hambeldon Place, built by
            Barret Homes. This was an exclusive gated community, a one of its
            kind built by Barret Homes. The other property belonged to Samantha
            Fox, - an English singer, a song writer, actress and former glamour
            model -which he bought at a concessionary rate from Martin, a
            well-known property developer, during a slump in the market. On a
            sales trip to Hong Kong, Martin took his portfolio which included
            these two properties, selling both almost immediately upon his
            arrival to two parties in Hong Kong who snapped up the properties at
            a premium price.
          </p>
          <p>
            Nahil lived in most of these houses before they were sold. The
            properties were scattered around London in areas like College
            Gardens,Coniston Place and Central London.
          </p>
          <p>
            Plaza Estate in Marble Arch was the largest estate agency in Central
            London, headed by an extremely pleasant Jewish boy, Ethan Fox,
            through whom Nahil was buying and selling properties. Generally, as
            an exclusive agent, you get 1.5% of the sale value whereas a
            non-exclusive agent gets 2%. Nahil mentioned to Ethan that getting
            just 1.5% on sales was too low, he should take a look at his
            portfolio listings where there is always a 5-7-10 percent discount
            offered on the list price of a property. Nahil made a deal with him.
            He challenged Ethan that If he could convince a property owner to
            discount a further 10%, Ethan would receive 30% of that reduction.
            Here he had a conflict of interest, with the deal offered to him by
            Nahil stumping him. He is supposed to be looking after the interest
            of the seller, who expects a maximum price, driving up his 1.5%
            commission margin. Nahil was offering him a higher margin at a lower
            point of the sale price, basically generating a higher percentage at
            a lower price. This was a reverse incentive on the price.
          </p>
          <p>
            This scheme was derived by Nahil only for Ethan and not the company
            he worked for. Nahil bought several properties through Ethan who
            followed his advice.
          </p>
          <p>
            After a few deals, Nahil would ask him how things were, inviting him
            for a beer. While sipping on their beers, they never discussed the
            sale. After the beer, Nahil would leave a packet of cash for him on
            the bar counter, and leave, just like in the movies!
          </p>
          <p>
            During his property development era in the UK, Vijitha attended
            Dulwich College and Anika St. James&rsquo;s Public School. Three of
            the houses developed by him were next to Dulwich College. As he sold
            one, the family would move to his younger sister Kanthi&rsquo;s home
            &ndash; she lived in the vicinity too.
          </p>
          <p>
            While they were living in#11, Hambeldon Place, Roshanne&rsquo;s
            mother May would stay with them periodically. One morning going
            through his mail, Nahil found a letter from a law firm enclosing an
            invoice for a consultation addressed to May, mailed to his address.
            Nahil opened the letter from Messers Pike, legal consultants
            forRoshanne, and found it pertained to Roshanne and May seeking
            advice on what rights she had to the custody of the kids and
            Nahil&rsquo;s properties.
          </p>
          <p>
            The first thing that crossed his mind was that the British Law was
            much tougher than Sri Lankan, especially in instances of child
            custody. With the alarm bells ringing in his head, he called his
            sister, gave her a brief overview pertaining to the seriousness of
            the situation and told her he was heading back home on the first
            available flight with Vijitha, since Anika had gone out of town with
            May and Roshanne. Owing to the urgency of the situation, his sister
            booked them on a business class flight due to the unavailability of
            economy class seats. He says, &ldquo;This kind of behaviour in
            Roshanne was always instigated by May.&rdquo;
          </p>
          <p>
            Nahil was on his way to the airport with Vijitha, when he realized
            that he was in his Bata slippers worn because it was a hot summer
            day.That&rsquo;s how fast he wanted to leave the UK. Once he boarded
            the flight, he found himself sitting next to Sarath Amunugama, a
            friend who was a fellow Trinitian, who spotted his Bata slippers and
            figured that he must have left in a hurry. He says, &ldquo;Machang,
            what&rsquo;s the hurry?&rdquo;
          </p>
          <p>
            Sarath had just been appointed Minister of Finance. As Nahil
            congratulated him on his recent appointment, he requested that
            Nahildrop in at his office the following day.
          </p>
          <p>
            Nahil was there at 7.a.m. as requested not knowing that it was
            Sarath&rsquo;s first day in office. They had a great chat relating
            funny stories, to each other and reminiscing about their college
            days at the &lsquo;best school of all,&rsquo; when he realised he
            had spent over an hour-and-a-half chatting. As Nahil left
            Sarath&rsquo;s office, he noticed people queued up, waiting to see
            Sarath. He was sure most of them would have assumedthat his meeting
            with Sarath was very productive, considering the time spent.
          </p>
          <p>
            During the time Roshanne was pregnant by him and the saga was going
            on, &ndash; even though she was of the consenting age of 18, he was
            considered a lecher, in getting his friend&rsquo;s daughter
            pregnant. Feeling guilty, at her request he bought May a house which
            she had viewed in Maida Vale, London, which she later rented out and
            then sold. The proceeds from the sale of the house was the cash she
            brought back to Sri Lanka, to make a fresh start considering she was
            broke when he helped the family get to the UK. She bought a house in
            Hantanne Kandy, and with the balance money, built up her capital.
            After he got back to Colombo from the UK, Roshanne and Anika stayed
            back in London for a brief period. Roshanne had a close friend in
            London named Jonathan Rix. Together they had purchased a flat
            somewhere in London and were renting it out.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/46-nahil-moves-to-the-uk/orphanage-details-roshanne.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Register with Roshanne’s details dated 19/12/1966</small>
              </p>
            </div>
          </div>
          <p>
            As a three months old infant Roshanne was handed over by her mother
            into the care of a Catholic childrens home in Gampola where she was
            cared for by Nuns from 1966 upto 1972, when her mother who was
            residing overseas, returned to Sri Lanka and took Roshanne back with
            her. Finally after six years Roshanne was re-united with her family.
          </p>
          <p>
            In 2018, Nahil managed to trace the address of the childrens home
            which he visited on Roshannes birthday in order to make a donation
            in her memory. While there he was shown the register pertaining to
            the year 1966 which had details of Roshanne&rsquo;s tenure at the
            home.
          </p>
          <p>
            After Roshanne&rsquo;s demise in 2002, Nahil had a great deal of
            correspondencewith Jonathan concerning the flat, which was now up
            for sale. This was to inquire from Jonathan how he intended
            splitting the proceeds from the sale between Nahil&rsquo;s kids and
            himself. Finally, Nahil&rsquo;s lawyer in Golders Green sorted out
            Roshannes part of the proceeds from the sale of the flat.
          </p>
        </Col>
      </Row>
    </div>
  );
};
