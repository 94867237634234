import { Row, Col } from "antd";

export const SouthAfricanMarine = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="south-african-marine">South African Marine</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Aliti Traders in South Africa had set up a factory to manufacture VW
            cars for the African market that predominantly manufactured
            right-hand drive Volkswagen cars. East West imported around fifty
            cars and sold them in Sri Lanka through East West Enterprises.
          </p>
          <p>
            What is significant here is, the shipping agency, EWE was growing
            very fast, rising to be the largest operation just three years from
            its launch, with considerable turnover. This was Lester&rsquo;s
            forte and he excelled in it.&nbsp;
          </p>
          <p>
            Unicorn Shipping was a&nbsp;&nbsp;company that was launched in 1966
            when African Coasters merged with Thesen&rsquo;s Steamship Company
            to form Unicorn Shipping. They started by operating 13 owned and
            long term chartered medium-range small product tankers with one
            medium tanker on order.&nbsp;&nbsp;In 2001 the company changed its
            name to Grindrod Limited.
          </p>
          <p>
            Unicorn Shipping was operating a subsidiary, Unicorn Shipping Lines,
            which handled coastal shipping. Mandela was in jail at the time and
            due to apartheid, there was an embargo stopping Unicorn Shipping
            Lines from sailing to Pakistan, India and a few other countries.
          </p>
          <p>
            Lion Shipping partnered by Malin Abeyratne was the agent for this
            line only on paper, not having handled any of the Unicorn ships or
            promoted any other business in Colombo up to that time.
          </p>
          <p>
            Lester forwarded a proposal to Unicorn Shipping with a feasibility
            report requesting to act as their agents in Sri Lanka. Once they
            accepted the proposal they did some media marketing in South Africa
            where they outlined East West as their SL agents. This was in the
            &rsquo;80s. &ldquo;With our appointment, there was fresh excitement
            every day,&rdquo; says Nahil.
          </p>
          <p>
            When Lion Shipping was informed by Unicorn Shipping regarding the
            change of agents, Malin protested saying, &ldquo;Sorry, you
            can&rsquo;t change agencies without Government approval&rdquo;. This
            is something that goes on all the time in Colombo. The ship left
            South Africa without an agent being appointed. With Malin&rsquo;s
            protest, though the then Minister of Shipping was lobbying for the
            line to be given to Lion Shipping since he was Malin&rsquo;s friend.
            East West was adamant that the Unicorn Shipping Agency should not go
            to Lion Shipping but to Ceylon Shipping Corporation, where they,
            Lester and Nahil, had their roots.&nbsp;
          </p>
          <p>
            Unicorn Shipping appointed EWE as owners&rsquo; representatives.
            After playing their role as owners reps for Unicorn Shipping, after
            a few voyages, they informed Unicorn Shipping that they were not in
            a position to serve &nbsp;their interest adequately and gracefully
            bowed out.&ldquo;Our office was like the South African Embassy with
            people visiting, requesting the recommendation of trade partners,
            etc., in South Africa.&rdquo;
          </p>
        </Col>
      </Row>
    </div>
  );
};
