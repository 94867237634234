import { Row, Col } from "antd";

export const SriLankaShippingRobertSenanayakeBuilding = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="sri-lanka-shipping-robert-senanayake-building">
          Sri Lanka Shipping – Robert Senanayake Building
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            The IBM building was almost complete when EWE started construction
            on the Sri Lanka Shipping building at the back of IBM. They had made
            an amazing sum of money through Sri Lanka Shipping; the first
            company to own ships even before the Ceylon Shipping Corporation.
          </p>
          <p>
            This was during the time Freudenberg&rsquo;s owned by Robert
            Senanayake was going into liquidation and since the Senanayakes were
            family friends, Robert gave Lester and Nahil first refusal to buy
            shares in his company, which they did, purchasing 45% of the shares
            at a dismal cost.
          </p>
          <p>
            While the share transfer was going on, Freudenberg&rsquo;s had a
            4,000-tonne ship on charter called the St. George. Lester and Nahil,
            not happy to charter, instead opted to buy the ship outright. The
            Greek owners of the ship were well known to the Senanayakes, which
            would help the transaction to go through without a hitch. Then came
            the question of how they would finance the purchase. It so happened
            that Nihal Sri Amarasekera, the EWE Public Interest Litigator, was
            married into the Robert Senanayake family. In fact Ranjith and Nihal
            were married to two sisters. Nihal was determined to help both
            parties, trying to source a loan of nine million rupees to purchase
            the ship. Though the company had no proper audited accounts for five
            years, using his tenacity, Nihal Sri sourced the loan through
            Grindlays Bank.
          </p>
          <p>
            Nahil and Lester were able to settle the loan&nbsp;&nbsp;in one
            year, a record time because the ship made big bucks for them. At the
            time they had lump-sum depreciation since there were no taxes and
            the equipment could be written off at the end of that year.
          </p>
          <p>
            In the following years, Sri Lanka Shipping was managed by Ranjith
            Senanayake &ndash; father of Wasantha &ndash; whose family were the
            majority shareholders. They were also agents for Hapag-Lloyd and a
            few other agencies. Lester and Nahil found out that Ranjith was
            running huge overheads at Sri Lanka Shipping and as shareholders,
            they couldn&rsquo;t let this happen. Deciding to take over the
            management, they shifted the operation to their office at Navam
            Mawatha, at no management cost.
          </p>
          <p>
            It was during this era that Ranjith wanted them to buy him out,
            though Devinda Senanayake was the face of the agency in the public
            eye. The shareholders, some of whom were friends and family of the
            Senanayakes, were willing to sell in totality if Devinda agreed to
            it.
          </p>
          <p>
            Initially, East West Enterprises bought the majority of the shares
            and finally 100%. It was after this acquisition that they
            constructed the Sri Lanka Shipping building behind IBM. As an act of
            appreciation to Robert Senanayake, for giving them a start into
            big-league shipping, they named the building &lsquo;The Robert
            Senanayake Building.&rsquo;
          </p>
          <p>
            Simultaneous to the construction of the Robert Senanayake building,
            the four-story Forbes and Walker building being built by Ajith
            Jayaratne, the Chairman of Forbes &amp; Walker - was progressing
            quite smoothly opposite the IBM building. Nahil would sometimes
            meet&nbsp;&nbsp;Ajith Jayaratne, during site visits where they would
            casually acknowledge each other.
          </p>
          <p>
            One morning Nahil noticed construction had stopped on the third
            floor of the F&amp;W building, with the scaffolding being taken down
            by the workers. On inquiring, the reason cited by Ajith for the
            abrupt secession of work was a shortage of cash and there was no
            immediate requirement for an extra floor. He maintained that they
            would probably build the extra floor in five years when the need
            arose. Here is an edited recap of the conversation:
          </p>
          <p>
            Nahil: &ldquo;Hey Ajith, look at all the masonry scattered around;
            bricks, planks, etc. In five years, building the extension will be
            difficult and hazardous, once the area is developed. Just do it
            now.&rdquo;
          </p>
          <p>Ajith: &ldquo;No money.&rdquo;</p>
          <p>Nahil: &ldquo;Alright, you need it in five years right?&rdquo;</p>
          <p>Ajith: &ldquo;Yes, five years.&rdquo;</p>
          <p>
            Nahil: &ldquo;Okay, I will build it for you on the condition that I
            can occupy&nbsp;&nbsp;the floor rent-free for the next five
            years.&rdquo;
          </p>
          <p>Ajith: &lsquo;That&rsquo;s a fantastic idea.&rdquo;</p>
          <p>
            This conversation took place while standing on the road opposite the
            building site.
          </p>
          <p>
            F&amp;W had an impressive Legal Department. Therefore Nahil
            requested Ajith to get his Legal Department to draw up an agreement.
            The request included that all that was discussed would be noted down
            on paper anticipating that action would be taken without delay on
            the discussed plan. During this period the Attorney General&rsquo;s
            wife Priyanthi also worked for Ajith at F&amp;W.
          </p>
          <p>
            A month or two later, all his construction bills were being settled
            by Nahil, the scaffolding was back on and the building was
            progressing but still, no agreement had been handed over. By this
            time Nahil was rather irked with him, drawing his attention to their
            conversation on the subject and his excuse of being short of cash to
            continue the building. Nahil reminded Ajith that he had found him a
            favourable solution and kept his side of the bargain, whereas Ajith
            had not drafted the requested agreement. Nahil wanted to know what
            was going on. He gave Ajith the option of completing the building
            himself, which he accepted with delight saying something on the
            lines of &ldquo;would you mind?&rdquo; Nahil was all for it, telling
            him that he could now shout it out from the rooftop that he pushed
            Ajith to complete the building. He was settling Ajith&rsquo;s
            contractor&rsquo;s bills and eventually, Ajith settled Nahil in
            full. All this happened on the site. The operation was successful.
            Nahil had his curb appeal, while Ajith got his fourth floor.
          </p>
          <p>
            East West Enterprises owned a few tugs, one of which was around four
            years old that sank off the coast of Panadura. A tug is a small
            powerful boat used for towing larger&nbsp;&nbsp;boats and ships,
            especially in harbour. It functions as a vessel that maneuvers other
            vessels by pushing or pulling them either by direct contact or by
            means of a tow line. Thankfully, though the weather was bad, they
            sent another pick-up, avoiding the loss of lives. Right after this,
            they were faced with a tax issue. Their books showed zero, due to a
            30% depreciation across three years and the insurance claim they
            received was 30-40% taxable unless it was replaced by an asset of
            higher value. The case in point was that this way they did not have
            to pay their capital gains on the insurance payout. They were
            contemplating on this route.
          </p>
          <p>
            Nahil was in India visiting a major petroleum and offshore drilling
            company managed by a few old seafarers. The company was winding down
            their operations and selling off two huge barges similar to the East
            West barges, since the Inland Revenue expected a similar asset or
            more expensive ones to replace their barges. He replaced their tug
            with these two barges, with the Indian company extending a loan to
            East West for the purchase of the two barges, which EW leased to
            them as a charter with a monthly charter fee where the monthly
            payment was the same as the charter hire. EW was given the
            registration certificates to authenticate the sale. There was no
            cash flow since EW&rsquo;s monthly repayment for the loan was the
            same as the charter hire for the two barges. Technically there was
            no money changing hands, though it appeared so in their books. Each
            month the Indian company would send East West an invoice for the
            loan repayment and EW would send them an invoice for the charter
            hire.
          </p>
          <p>
            This rhythm was kept going by both parties, with the Inland Revenue
            guys up in arms aware of this brilliant concept but unable to fix
            them since they were within the law, which Nahil used to appropriate
            the EW end of the deal. At the end of the three-year charter, the
            Inland Revenue Department requested the barges to be brought to Sri
            Lanka. By this time though the Indian guys had scrapped it and there
            were no barges.
          </p>
          <p>
            Needing some kind of excuse for the Tax Department, he opened up a
            file and started writing letters to the Indian shipping agent, with
            no reply from them. This practice continued for several months after
            which they wrote to the Registrar of Companies India, who informed
            them that the company in question had gone into liquidation. He knew
            well in advance about the liquidation. Notwithstanding Nahil sent a
            letter to the Registrar so that they&rsquo;d have an authentic
            document from the Registrar of Companies India, confirming the same.
            Nahil asks &lsquo;&lsquo;can any tax consultant ever come up with a
            custom-made solution like this?&rdquo;
          </p>
        </Col>
      </Row>
    </div>
  );
};
