import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

export const NavBar = () => {
  return (
    <Sticky top={0} innerZ={99998}>
      <Row justify="left" style={{ backgroundColor: "white" }}>
        <Col span={24} style={{ margin: "10px" }}>
          <div>
            <Link to="/">
              <Button type="link" className="fontStyleNav">
                <p>Simply Nahil</p>
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Sticky>
  );
};
