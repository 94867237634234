import { Row, Col } from "antd";

export const CrescatResidenciesJohnKeellesHoldings = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="crescat-residencies-john-keells-holdings">
          Crescat Residencies - John Keells Holdings
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Mr. Perera, a great gentleman, who formerly worked for the UN, was
            the Chairman of the Crescat Council. During Mr. Perera&rsquo;s
            tenure, as Chairperson, Nahil bought the entire eighth floor of
            Crescat Residencies. The apartments were bought by him as rental
            properties. A month later while being refurbished, right down to the
            new coffee cups, with advances already paid to various suppliers, he
            resold the apartments back to Crescat at a higher price. Initially,
            while the transfer process of the apartments to him was in progress,
            he wanted to know where the designated parking for each unit was
            located, only to be informed that it was in the Oberoi (now Cinnamon
            Grand) car park. He found this outrageous. In the condominium
            business, the apartment parking should be within the condominium
            footprint, according to him, in the same area he presently parks.
            There were six to eight parking slots here, apparently given to the
            penthouse owners. He was more than irked and got it conveyed to the
            condominium owners that he needed the parking within the building
            footprint. He was frustrated that they were so disorganized and
            unpredictable. The way it was all going, he was sure they would next
            tell him the parking spaces were in Galle!
          </p>
          <p>
            Asia Hotels and Properties PLC, the developer, turned a blind eye,
            refusing to do anything about it, at which point, he got them to
            agree to buy back the entire floor at a rate which covered his unit
            upgrade expenses, plus 10%, including a fee for his sweat equity.
            The owners bought it all back. This was the beginning of his
            adventure with John Keells.
          </p>
          <p>
            Prior to JK, Crescat was owned by Asian Hotels and Properties, a Sri
            Lankan based company engaged in hoteliering and property
            development/management. They were the owners of Lanka Oberoi (now
            Cinnamon Grand) at the time Daniel Roche, a French national was the
            GM. Besides the apartments he had resold, Nahil had invested in a
            few more apartments in the same building. His big concern was the
            Condominium Management Association, which was broke due to the
            tenants not paying their rates, leaving the association with hardly
            any money, even for maintenance of the building.
          </p>
          <p>
            He learned that from the time the condominium was built, the
            developer owned 3,000sq feet of space on the ground floor.
          </p>
          <p>
            This area was leased to Lanka Bell, used as its switching centre.
            Lanka Bell was paying one million rupees a month to AHP, for the use
            of the ground floor, which belonged to Crescat Residencies, with the
            roof used for the Lanka Bell antennas. By right, the ground floor
            should have been the car park allocated to the residents. He pitched
            into the owning company, telling them they had no business to be
            renting space to outsiders since the building once developed was
            owned by the association members who had bought the apartments. With
            that he told the developer/owner AHP, it was time for them to leave
            it all to the association members and get out! He berated the
            developer for renting out bits and pieces of space, making tons of
            cash while their association had hardly any cash even for
            maintenance. Amidst this rumpus, John Keells came into the picture
            as the new owners. Back to square one, he turned his attention to
            JK, airing his grievances with a tad more vehemence.
          </p>
          <p>
            He remembers Ajith telling him, &ldquo;We acquired the building like
            this. You have to take the rough with the smooth. We can take this
            to court and see what happens.&rdquo; It seems that court cases are
            in their DNA. Experts at court cases, they file a case and drag the
            case on forever. Nahil obviously said, &ldquo;Alright, let&rsquo;s
            go to court.&rdquo; He argued that at the point of the sale, all the
            perks of the complex were presented to the buyers in a brochure that
            mentioned the ground floor was set apart for public utilitiessuch as
            a mini-mart, laundry, etc., which never materialised. The previous
            owners had carved out three more apartments on the ground floor and
            had already sold two, which he let go, but the unsold apartment was
            being used by various people for their private practice. Together
            with the Crescat Association, he sent all the relevant documents to
            the Condominium Authority, a Government body, outlining their
            grievances and pointing out that JK was holding on to an apartment
            that belonged to the Crescat Condominium Association.
          </p>
          <p>
            After the CA inspected the documents, they ordered JK to hand over
            the remaining space to the Crescat Condominium Association, the
            rightful owners of the space at the end of the corridor where the
            office is located. JK was ready to go to court again. Mickey
            Wickramasinghe, the Chairman of the council, was reluctant to upset
            JK because his biscuits were going onto the JK supermarket shelves.
            He held on to the letter for five to six weeks doing nothing about
            it and only spoke about filing a case.
          </p>
          <p>
            Nahil informed Micky that in property matters, possession was 99%
            ownership, and therefore they should take possession of it, to which
            he reluctantly agreed, along with the rest of the council. Nahil
            excused himself from the meeting. Having already made arrangements
            for a carpenter and locksmith, together they opened the door of the
            apartment in question, changed the lock and emptied all the JK stuff
            into the basement, and then transferred some of his stuff into the
            apartment. He returned to the meeting informing Micky and the rest,
            &ldquo;Possession taken &ndash; here are the keys.&rdquo; As
            expected, by 6 o&rsquo;clock that evening, the Colpetty Police were
            there accusing the council of breaking and entering. Before it all
            went south, he had to come up with something to water-down the
            situation, Viola! He asked the cops how they could even imagine that
            all the nice ladies in the council would stoop down to breaking and
            entering the apartment. He further stated that if the council had
            done something illegal, JK could take them to courts and repossess
            the apartment. Since delays were of no consequence, he put forward a
            timeframe that they agreed to, signing the space over to the Crescat
            Association. Finally, the association had physical possession of the
            disputed space, sanctioned by the Condominium Authority that the
            rightful owner of the apartment was the Crescat Association. Done!
          </p>
          <p>
            The next challenge was the sixty million rupees JK had earned from
            Lanka Bell for five years. The Crescat Association explained the
            scenario to Lanka Bell and informed them that the association being
            the rightful owners the rental cash should be paid into the
            association account. He pointed out that they were paying the wrong
            guys, resulting in the association always being stuck due to the
            lack of funds. By this time Harry Jayawardena, owner of Lanka Bell,
            stepped in to help by deciding not to make any payments until the
            dispute was settled.
          </p>
          <p>
            Finally, the car park was open but the apartment owners were still
            parking all over the Oberoi car park, since obstructing yellow
            barriers had been put in place by JK. Determined to do something
            about this and take possession, the CA photocopied a note informing
            the owners that a designated parking space within the condominium
            footprint was available to each apartment from the next day. They
            slipped the note under the door of each residence, progressing into
            a war on the barricades, along with the Brave Guard security guys;
            who took the chains and padlocks he had bought, bunched the
            barricades together, tied them with the chains and padlocked the
            chains, assuming if the cops turned up they would need wire cutters
            and other paraphernalia to dismantle the lot. That evening the JK
            security was embarrassed by their failure to avert the Brave Guard
            security guards from securing the barricades. They got drunk and
            tried challenging the Brave Guard guys who managed to calm them
            down. Later they said to the JK security guards &ldquo;The car park
            had been open for a while but you still held on to the barricades.
            There&rsquo;s no point in arguing or fighting.&rdquo; They
            surrendered and that was that.The car park matter was done.
          </p>
          <p>
            While the Emperor Residencies was being built, right next to
            Crescat, the workmen would go on working through the night, in spite
            of being aware of the general law concerning building sites and
            noise pollution. According to the law, it is mandatory that all
            building work has to shut down at 7.p.m. to 7.a.m. every day, a rule
            at the time, that was never adhered to by these construction
            workers. The working of the vibrator almost throughout the night had
            become a nightmare to all the residents of Crescat, compelling a
            team of residents to visit the building site, requesting the
            contractors to acknowledge the downtime rule, this included the
            cops.
          </p>
          <p>
            They nonchalantly carried on until Nahil decided to intervene.
            Taking off the sun film on his bedroom window, he fixed a web camera
            facing the construction site, connected it to the internet with half
            the screen displaying the permit from 7.p.m -7.a.m and the other
            half a slogan that read JK was breaking the law in real-time and a
            web site named &lsquo;otherside&rsquo; of JK.com&rsquo;, created by
            him. There was an immediate cessation of construction at 7.p.m each
            evening. He had bought his present residence from the ex-Chairman of
            JK, Vivendra Lintotawela. He has no idea if the penthouse was built
            especially for Vivendra. At the time the purchase was finalised and
            the deed of the property was transferred to him, the deed clearly
            stated that Crescat was a joint development of three condominiums
            &ndash; Emperor, Monarch and Crescat &ndash; all three buildings
            with a maximum of 24 floors each. Despite this affirmation by the
            developer, the hoardings advertising the Emperor, situated opposite
            the construction site, displayed 30 floors. Nahil called Ajith
            Gunawardena to let him know they were violating the 24 levels
            affirmed on the deeds, and also letting him know that the number of
            floors was too high. He tried to challenge Nahil, who offered to
            send him acopy of the deed. Ajith was adamant it was not so and
            said, &ldquo;What nonsense.&rdquo; Nahil reckons Ajith tries to back
            off when he can&rsquo;t stand his ground. Ajith says, &ldquo;File a
            case,&rdquo; and that&rsquo;s exactly what he did. De Sarams, his
            lawyers, handled this. What Ajith did not know was that the Crescat
            Association had lodged this complaint in the land registry.
            Therefore this way whenever there was a buyer whose lawyers were
            doing a title search, they would come across this caution and advise
            their clients not to buy. A &lsquo;caveat emptor&rsquo; had been set
            that stated all the apartments in the new building above the 24th
            floor were an issue. This jeopardised the sale of the floors above
            the 24th level since the lawyers and banks always check the deeds.
            This was not anything personal. Nahil was just strong-arming John
            Keells, using this as leverage to get JK to settle the 60 million
            owed to the Crescat Association. Eventually, John Keells relented.
          </p>
          <p>
            Once the money was paid, Ajith wanted Nahil to visit him at his
            office, a room full of female lawyers. They placed a multitude of
            documents in front of him, which he signed without any questions,
            withdrawing the case and pulling down the website which was -
            &lsquo;the other side of jk.com.&rsquo; The next day Ajith called
            him and wants to know why the website has not been taken down. He
            says, &ldquo;I am standing in front of my computer and this site is
            still on,&rdquo; to which Nahil replies: that he too is in front of
            his computer and the site has been taken down, enlightening Ajith
            that it&rsquo;s in his computer&rsquo;s cache memory, a problem that
            will immediately resolve with a re-boot of his machine.
          </p>
          <p>
            Recently while Ajith and Nahil sat sipping a beer, Ajith told him,
            his wife and peers say that he should be Nahil&rsquo;s No.1 enemy.
          </p>
          <p>
            Nahil informed him that he takes pride in being a campaigner for the
            underdog which was nothing personal. He recalls inviting Ajith and
            his family to be his guests for a weekend at the time the Marriot
            hotel in Weligama, was owned by him.
          </p>
        </Col>
      </Row>
    </div>
  );
};
