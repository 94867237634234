import { Row, Col } from "antd";

export const KavanAndAnneRambukwella = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="kavan-and-anne-rambukwella">Kavan and Anne Rambukwella</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Kavan Rambukwella was a dear friend of Nahil&rsquo;s, in addition to
            being a faithful old boy who was highly involved in the affairs of
            the Trinity OBA.
          </p>
          <p>
            Kavan and his wife Anne lived in a housing complex situated on the
            road that cuts across from Bullers Road, joining Thimbirigasaya
            Road, spanned by a small bridge over the canal halfway down the
            thoroughfare.
          </p>
          <p>
            One evening due to an emergency the normal traffic on Bullers Road
            was diverted through this thoroughfare. Unfortunately, a truck was
            transporting a bulldozer which was over and above the rating of the
            bridge. While crossing, the bridge collapsed with the truck tilting
            the dozer into the water. The next morning the owners of the dozer
            lifted the dozer out of the canal and continued on their way with no
            thought of even volunteering to do something about the mangled
            bridge.
          </p>
          <p>
            Once the Municipality was notified about the collapsed bridge, they
            unhesitatingly informed the community that it was not their problem,
            they said the bridge was the responsibility of the house
            owners&rsquo; community. The main management committee of the
            community, who,lived on the opposite side of the bridge at the
            Longden Place/Borella endwere elated with this situation, on account
            of the road instantly becoming a cul-de-sac with no traffic after
            the collapse of the bridge, They in typically selfish fashion,
            decided not to do anything about.
          </p>
          <p>
            Unfortunately, Kavan and Anne lived on the Thimbirigasaya side.
            Usually, whenever Nahil visited Kavan he would approach their
            residence through the Bullers Road entrance; through habit, he
            continued to do so, only to be inconvenienced, turning back at the
            broken bridge, and driving full circle to enter through the
            Thimbirigasaya entrance.
          </p>
          <p>
            After many more complaints, the Municipality realizing the
            inconvenience the folk living on Kavan&rsquo;s side of the bridge
            were subject to, decided to reconstruct the bridge, only for the
            management committee to discourage the Municipality from doing so.
            One Friday morning, on a Poya day, Nahil visited Kavan and was
            exasperated to find the bridge still in disrepair, which made him
            inquire from Kavan regarding the current situation of the bridge.
            Consequent to the negative update, always being an advocate for the
            mistreated, especially a close friend, Nahil volunteered to repair
            the bridge.
          </p>
          <p>
            The next day, he got the bridge measured, and got the steel and
            concrete framing in place at his own expense, making sure that by
            Monday the bridge would be back better and stronger than before.
            What Nahil felt was that his friends were being harassed and this
            was a challenge to take on and see it to an end. Kavan appealed to
            the management committee as to why Nahil had to pick up the tab on
            this repair which rightfully was the community&rsquo;s
            responsibility. They relented and paid him for the steel and
            concrete although they made a last ditch attempt to hinder the
            progress by parking a 20&rsquo; container across the road after the
            bridge was repaired, which was unceremoniously dismissed by a
            majority of the good and unselfish folk living in the community. The
            road is still a thoroughfare
          </p>
        </Col>
      </Row>
    </div>
  );
};
