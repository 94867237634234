import { Row, Col } from "antd";

export const SalvagingoftheRavidas = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="salvaging-of-the-ravidas">Salvaging of the ‘Ravidas’</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Marine Salvage in layman terms is the process of recovering a ship
            and its cargo after a shipwreck or any other maritime casualty.
            Salvage may encompass towing, re-floating a vessel, effecting
            repairs to a ship or recovering a vessel that is in trouble. The
            increasingly complex nature of salvage operations requires
            multidisciplinary, experienced teams.
          </p>
          <p>
            Off Shore Marine Services, an East West company would take advantage
            of the dry salvage season by using its large feeder tugs to deliver
            oil to ships out of the harbour. This operation generated a sizeable
            income to cover its overheads, which otherwise would have been a
            loss-making enterprise.
          </p>
          <p>
            At the time, Off Shore Marine Services (OSMS) along with Master
            Divers, which was owned and operated by Wicky Wickramanayake, was at
            the top of the salvage and tug operation in Sri Lanka. Up until
            then, it was a Dutch company, SMIT Salvage, that hovered around with
            a tug waiting for an exigency that would earn them some serious
            bucks. SMIT Salvage is one of the largest salvage companies in the
            world. The SMIT name is synonymous with many of the most challenging
            and complex salvage operations that have taken place over the last
            century. The company maintains specialised equipment and expert
            personnel in a state of round-the-clock readiness to respond to
            incidents. Just one ship’s calamity could keep them afloat for a
            good few years. SMIT’s term in Sri Lanka ended with the emergence of
            the two local companies, Off Shore Marine Services and Master
            Divers.
          </p>
          <p>
            The first ship salvaged by OSMS was the ‘Ravidas’ owned by an Indian
            company and handled by East West. How this operation works is, when
            a ship is in trouble, the captain sends an SOS, obtaining a partial
            nod from the operator’s senior management. He then signs what is
            called a ‘Lloyd’s Open Form’, commonly known as LOF, determining the
            amount of remuneration to be awarded to salvors in a proposed marine
            salvage operation governed by Lloyds London, a practice that has
            been around the past 150 years or so. What the captain means by this
            declaration is that it is an open contract because no amount of
            money is being stipulated for the salvage job. The 1910 Brussels
            Convention had established the principle known as ‘‘no cure, no
            pay,’’ meaning that a salvor is rewarded only if the salvage
            operation successfully rescues the ship or its cargo. If the
            operation goes south, ‘no marks, no bucks!’ Not for the lack of
            trying, the dividend on sweat equity and effort ride south too. On
            the flip side, if the ship is saved, depending on the effort and
            sweat equity invested, the service provider gets 15-25% of the
            salvaged value of the ship and cargo. The math is decided by Lloyds
            and some arbitrators in the UK once an explanation is given
            regarding the circumstances of the dilemma. Generally, the salvage
            operator consults a law firm in London and briefs the legal team
            which argues the case on their behalf, while the operator’s insurers
            get their lawyers to downplay the situation, in a bid to cut down
            the pay-out. This goes back and forth while the arbitrators are
            caught in the middle, forced to look at the situation and the effort
            that has gone into the entire operation, before deciding what
            percentage the operator should be paid.
          </p>
          <p>
            The salvage and towing of ‘Ravidas,’ a ship that was in trouble in
            the high seas just off Galle, is interesting. OSMS was called upon
            to handle the ‘Ravidas’ operation since the Dutch salvage was
            unavailable. Vicky from Master Divers also sent his tugs and
            requested help from OSMS to provide its tugs for the operation,
            since they were the only local companies that did salvage work.
          </p>
          <p>
            This operation was about to be Nahil’s first big hands-on salvage
            and tug operation. Excited and very eger to join in, sadly,
            according to him, he was ‘tactfully turned down’ due to the fierce
            weather conditions... He did the next best thing, he moved into the
            Closenberg Hotel in Galle to be in communication with the action by
            wireless radio, with his camera also in tow. Nahil sat listening to
            all the goings-on while sipping a beer. He could hear the panic on
            site, since the Captain, Chief Engineer and their wives were still
            on board. The Captain was agitated and keen to get them ashore,
            adding to the pressure of the chaotic situation. Thankfully the
            Salvage Master brought them ashore on a launch, and subsequently,
            the salvage and tug rescue exercise was a successful operation. A
            few months later it was arbitration time in London with the
            arbitrators questioning the OSMS invoice –why were they charging so
            much?
          </p>
          <p>
            Around the same time, for some reason, Nahil had taken his camera
            out to do some recording, when he realised there was a video
            recording that showed a continuous picture of a beer bottle on film
            – the beer he had at the Closenberg.
          </p>
          <p>
            His camera had accidentally been on, recording the beer bottle on
            the table, while the conversations of the rescue operation were
            caught on the camera audio from the wireless radio. He sent the
            recording along with a transcription of this unique piece of audio
            to London. Their lawyers played this back to the arbitrators. No
            further questions were asked and the case was closed and the entire
            amount they had invoiced for was paid. No doubt it was one more
            blessing.
          </p>
          <p>
            Electromotion was the largest trader in used machine tools based in
            Nahil’s college turf in Leicestershire, an area which he was
            familiar with. He would visit Electromotion quite frequently to
            inspect machines like lathes, milling and drilling machines and
            such, buy the lot, put them into a container and get it shipped to
            Colombo. Once the machines reached their workshop, he refurbish the
            machines to be retailed in the open market. One incident he vividly
            remembers is the time he shipped three containers aboard a Ceylon
            Shipping Corporation vessel that carried general cargo. Container
            ships were not around at the time; therefore everything shipped into
            the country was on general cargo ships like this.
          </p>
          <p>
            Due to an oversight, the crew had stacked these containers on the
            deck in a lackadaisical manner, causing the binds to come apart,
            during a spell of bad weather en-route to Colombo. With the securing
            loosened, the containers were moving around the deck in slow motion
            while the crew looked on helplessly at the unfolding scene. After a
            few minutes, the doors of the containers opened up with a bang and
            all the tools were thrown all over the deck, causing terrible
            wreckage. Once the ship docked, the deck was found to be shining
            like stainless steel due to the movement of the three containers.
            Sadly, where the cargo was concerned it was a total loss. The
            immediate plan of action was to get the insurance sorted out.
            Ceylinco Insurance was the local representative for their insurers
            in the UK and the claim was settled in a week. It was at that moment
            that Nahil promised himself that he would pay more personal
            attention to minor details in the future which was most important.
            If not for the insurance, they would have gone from being heroes to
            zeros. This was a lesson learned the hard way and from then on his
            personal mantra was ‘‘Always see things to the end.”
          </p>
        </Col>
      </Row>
    </div>
  );
};
