import { Row, Col, Image } from "antd";

export const AGreatBusinessPartnership = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="a-great-business-partnership">A Great Business Partnership</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            When Nahil was working as the Technical Manager of the Ceylon
            Shipping Corporation in 1976, responsible for all the technical
            aspects of the fleet under his purview, Lester Weinman, a ship’s
            captain, was the Operations Manager looking after the port and
            commercial activities. Nahil instinctively felt that given their
            experience, together they should start up a shipping company. With
            the the advent of the open economy in Sri Lanka 1977, together they
            decided this was the opportune moment to operate a proper agency.
            The prime motivator in launching out was the lack of good shipping
            and cargo handling agents; something Nahil and Lester experienced
            first-hand as sailors in the various ports they would sail through,
            including Sri Lanka, the lack of good shipping and cargo handling
            agents. With the asvent of the open economy in Sri Lanka in 1977,
            Nahil, together with his friend Jothy Godage, launced East West in
            1977, operating from Jothy’s home, No.10, Pereira Lane, Wellawatte,
            which was their first registered office. Subsequently, in 1978 they
            were joined by Capt. Lester Weinman and Mrs. Vinitha Jayasinghe, who
            were appointed to the Board by invitation. Mrs Jayasinghe was
            invited to join, since her husband, Mr. Upatissa Jayasinghe, who was
            the Commercial Manager of Ceylon Shippimg Cooparation, has planned
            to leave CSC with Nahil in order to join the Board of East West
            Enterprises but was unable to do so.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/14-a-great-business-partnership/certificate-competency-first-class.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>First Class Engineer</small>
              </p>
            </div>
          </div>
          <p>
            With Lester joining the company, they became the first and only
            seafarers to operate a shipping agency at the time. They needed
            financing to pay rent and fit out an office with essentials like a
            fax machine and furniture. Therefore they both decided to sell the
            brand new cars they owned which they had purchased with their
            foreign earnings. Nahil owned a Toyota Corolla Mark 11–2000, a fancy
            car. The only road trip in it was his honeymoon to Bentota with
            Indrani, his first wife. He sold it to Ceylon Tobacco Company,
            investing the proceeds in East West, buying himself a little Fiat
            600 for his use. However Lester was dilly-dallying on whether he
            should sell his Peugeot 404 and as by this time the business was on
            an upward curve, Lester’s car was never sold.
          </p>
          <p>
            At the onset, Lester suggested that they start up East West
            Enterprises on the first floor of his home on Alvis Terrace, Colombo
            03, owned by F.X.R. Pereira. Their primary need was to obtain a post
            office box number for the new enterprise. On applying to the General
            Post Office (GPO) they were lucky to get a three-digit abandoned
            number, 678. Usually before the issuance of a P.O. Box number, an
            officer from the GPO visits the business premises for an inspection
            and a mandatory requirement was a proper signage of the business.
            Nahil rushed to Walkers, got the name East West engraved on a sheet
            of Formica, and fixed it on the balcony of Lester’s home so that
            everything was in place for the inspector’s visit and licensing.
          </p>
          <p>
            To commence operations on a professional note, the letterheads and
            relevant documents had already been given for printing, when Nahil
            suddenly realised the next day that this space at Lester’s was not
            going to be adequate. Coincidentally, a few days later he met a
            gentleman, Mr. Hector Silva, a namesake and a good friend of his
            father-in-law who was an executive in the top rung of Ceylon
            Insurance Co., who had an office at Ceylinco House. Nahil mentioned
            to him that he was meeting with Mr. Pathmanathan, the person to
            approach at Ceylinco regarding rental of space. To his amazement Mr.
            Silva, who was a senior officer, informed him that he was retiring
            in six months, and therefore they could rent out the office he
            occupied at Ceylinco House and he was willing to sit outside the
            office till his retirement in a few months. Nahil took him up on his
            offer; a room on the fourth floor of Ceylinco House. That was how
            East West Enterprises, the parent company of the diverse portfolio
            of East West Group of Companies, came to fruition.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/14-a-great-business-partnership/first-class-engineer-certificate.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>First Class Engineer Certificate</small>
              </p>
            </div>
          </div>
          <p>
            The company was registered towards the latter part of 1977, with
            Nahil and Jothy Godage as Directors, its principal business being
            ship handling and haulage. In a few years, they grew to be the
            largest shipping and haulage agents in the country. “All this was
            made possible with enthusiasm, hard work, focus, persistence and
            dedication in everything we did,” says Nahil.
          </p>
          <p>
            There was a time during the initial stage of their shipping
            operations when they would drive to the airport with a typewriter,
            letterheads and seals in the boot of Lester’s car and, pretending to
            be clerks, they’d enter the Customs arrival area. It is a Customs
            requirement that a letter of discharge is furnished by the handling
            agents of a particular vessel to clear each sailor on arrival before
            assigning them on-board another ship. He says one of them would run
            to the car park, type out the letter, sign, set the seal and rushed
            it back to the Customs officers, who would always question as to how
            they were able to get the clearance letters so fast. They spun a
            little fib about a clerk who rushes to the office, collects the
            letter and rushes back, or some such story. Fast-tracking an
            otherwise time-consuming operation was one more little thing that
            contributed towards their success.
          </p>
          <p>
            Towards the middle of September 1977, Indrani and Nahil were blessed
            with their son, Vajira. They were living in the Gower Street
            residence at the time of his birth. The first Wijesuriya of the
            third generation had arrived and Nahil was thrilled. After the
            initial excitement, things settled down and they got into a routine.
            Indrani was a stay-at-home mom. Vajira celebrated his 40th birthday
            a couple of years back. I marvel at how fast the years have rolled
            by.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/14-a-great-business-partnership/etv-opening-nahil-lester-anton.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Nahil and Lester with Anton Alwis – ETV opening</small>
              </p>
            </div>
          </div>
          <p>
            We reverted to our discussion on Nahil’s most endearing topic, his
            business empire – East West. It was the 1980s and the company at the
            time was on its way to ‘greater things’. As they expanded,
            diversifying into bunkering services, salvage and towage, a
            container freight station, haulage, a travel agency and a customs
            clearing house, each of these agencies, though singular in
            designated operations and with a three-way directorate, were steered
            through the shipping agency, their flagship company East West
            Enterprises.
          </p>
          <p>
            In the years leading up to the present, when Nahil took over as
            Chairman of the Group, he diversified into other areas such as real
            estate, hospitality, media and information technology, among a host
            of other minor operations. With the real estate boom in the mid to
            late ’80s, he foresaw the long-term potential of the investment
            property market and divested most of the larger operations, going
            head on into real estate. Nahil is a citizen of Singapore and
            requires an annual visa to live in Sri Lanka.
          </p>
          <p>
            In the sphere of business, like his dad, he has always thought out
            of the box, at most times looked at the big picture, been bullish in
            business, and is extremely forward-thinking. All of these attributes
            have contributed tremendously to his business acumen. Many people
            have told Nahil that he has been very ‘lucky’ in business, to which
            he vehemently protests saying that it’s utter nonsense. He says he
            has always been ready for a challenge and always looked at the
            downside in any venture, intelligently working through tough
            situations, thankfully ending up with excellent results. He is a
            major sceptic but believes it is divine intervention that has
            brought him to where he is today. ‘‘Deep down I know I am blessed,”
            says Nahil.
          </p>
        </Col>
      </Row>
    </div>
  );
};
