import { Row, Col, Image } from "antd";

export const DonatingABuildingtotheMarineFraternity = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="donating-a-building-to-the-marine-fraternity">
          Donating A Building to the Marine Fraternity
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "200px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/58-donating-a-building-to-the-marine-fraternity/institute-of-marine-engineers.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Apartment at Arcadia</small>
              </p>
            </div>
          </div>
          <p>
            As a member of the SOCEM &ndash; Society of Chief Engineers Marine
            &ndash; and the Institute of Marine Engineers Sri Lanka, Nahil
            realised the two associations never had a permanent address. It was
            customary that the address of the associations would be the current
            secretary&rsquo;s address, changing each time a new secretary was
            appointed. Finally, they moved into a small building in Crows&rsquo;
            Island &ndash; &lsquo;a half-past-two-thirty place&rsquo; &ndash; in
            proximity to Ferguson Road, Aluthmawatha Kotahena. To Nahil this
            address was a disgrace and didn&rsquo;t do justice to the dignity of
            the two associations, thus compelling him to offer the institutes a
            space at the IBM building, from where his office operated. With this
            move, both associations had an air-conditioned proper office space,
            rent-free. The members were elated. Whenever foreign mariners
            visited the office, they were thrilled at the prospect of the office
            being owned by a fellow marine engineer.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/58-donating-a-building-to-the-marine-fraternity/marine-house-opening.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Opening ceremony</small>
              </p>
            </div>
          </div>

          <p>
            Though SOCEM occupied the space for a few years, there was no valid
            tenancy agreement signed between East West and the associations,
            thus on the sale of the IBM building, Akbar Brothers, the buyer,
            realized there was no rental income generated from the
            associations&rsquo; office space for six months and virtually kicked
            them out.
          </p>
          <p>
            Realising the associations were back to square one with nowhere to
            go, Nahil regretted not signing an agreement concerning the
            associations when the building was sold. He felt he had got caught
            up in the sale with the rest of the tenants automatically
            transferred, causing him to, unfortunately, overlook the position of
            the associations. At the time Nahil heard about the plight of the
            associations, he was overseas. The associations&rsquo; dire
            situation saddened Nahil since there was nothing he could do from
            that distance.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/58-donating-a-building-to-the-marine-fraternity/marine-house-building.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Marine House</small>
              </p>
            </div>
          </div>
          <p>
            One evening while conversing with his sisters and discussing how
            they had helped many in the medical field, especially academically
            brilliant medical students who were struggling to survive through
            medical school, some of whom were now held in high regard in Europe
            and the US, something triggered in him. His thought was that his
            sisters had been benevolent and empathetic towards the hardship of
            youngsters in their profession and he asked himself while being so
            blessed, what had he done long term for the less fortunate? Deciding
            to help ten medical students, Nahil got in touch with the Dean of
            the Faculty of Engineering at Peradeniya University, who affirmed to
            him many brilliant studentslived in such dire circumstances that
            they had no idea where their next meal would come from. Nahil
            volunteered to pay, on a standing order, 50,000 rupees a month, to
            be divided between the ten neediest students
          </p>
        </Col>
      </Row>
    </div>
  );
};
