import { Row, Col, Image } from "antd";

export const Anecdotes = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="anecdotes">Anecdotes</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            <strong>
              <em>Bunnis Race </em>
            </strong>
            &ndash; A little story Nahil remembers about E.L. Senanayake. Old EL
            was a lousy athlete or no athlete at all. He was invited as Chief
            Guest to one of the Trinity College Junior Athletics meets. While
            giving his speech he says, &ldquo;I never failed to participate in
            these meets and I always won the &lsquo;bunnis&rsquo; race, because
            he ate the &lsquo;bunnis&rsquo; the fastest.&rdquo;
          </p>
          <p>
            <strong>
              <em>Typewriter</em>
            </strong>{" "}
            &ndash; There was a teacher at Trinity, one Mr. Daniel, who was a
            legend, fondly referred to with a not-so-flattering name
            &lsquo;Surppadaya Daniel&rsquo; by the students. Once he corrected a
            grammatical mistake of a colleague. The sentence read: &lsquo;This
            man was a good typer,&rsquo; and was corrected by Daniel with much
            aplomb with his version &ldquo;This man was a good
            typewriter&hellip;&rdquo;
          </p>
          <p>
            Most of the other teachers, especially in the Junior School, were
            females. One of whom he remembers well was Mrs. Weerasekera,
            whileCannon Ratnayake aka &lsquo;Gabuwa Ratnayake&rsquo;, was the
            Headmaster of the Junior School.
          </p>
          <p>
            <strong>
              <em>Letter Writing Skills</em>
            </strong>{" "}
            &ndash; When Nahil was the Technical Director of the CSC, P.B.
            Karandawela, the Chairman of the Ceylon Shipping Corporation (CSC),
            once told Nahil that each time he wrote a letter to a ministry or
            any persons in authority requesting a decision on something, he
            would write a little note at the bottom of the letter that read,
            &ldquo;If I don&rsquo;t hear from you in ten days, I assume that you
            have no objection to me taking action on my request.&rdquo; He
            hardly received any replies within ten days...
          </p>
          <p>
            <strong>
              <em>Game Fishing </em>
            </strong>
            &ndash; Lester being very involved in fishing, bought a tournament
            fishing boat. To Nahil, this was a big deal, a roaring machine with
            a 250 horsepower engine imported from Australia. Its hull was made
            of Kevlar, a material used to make bullet proof garments. Each time
            Nahil took the boat into the harbour to dock it at the Walkers pier,
            it would cause a rumpus in its wake, causing the big ships to rock
            and hit the sides of the pier. By the time the station calls out on
            the VHS &ndash; &lsquo;who is this idiot causing mayhem?&rsquo; he
            would dock the boat in their pier, lock up and leave before they
            came chasing after him. One sunny day he decided to go fishing with
            Lester and his cousin Phillip, a guy big into fishing himself, who
            together with Lester caught a huge fish. Generally as the rules of
            &lsquo;game fishing&rsquo; go, the ethical thing to do is to put the
            fish back into the water, instead they got a pole and beat the fish
            to death. Nahil was so disgusted, he told them this was a
            disgraceful and nasty sport, categorically stating that he would
            never, ever join them to go &lsquo;game&rsquo; fishing in the
            future. He says &lsquo;&lsquo;no game at all - plain murder!
          </p>
          <p>
            <strong>
              <em>Thunder Boxes</em>
            </strong>{" "}
            &ndash; At the time Rajan Kadirgamar, Lakshman&rsquo;s brother, was
            the Navy Commander, and also the Chairman of Ceylon Shipping
            Corporation, due to the communal riots in Colombo, Mr. Amirthalingam
            requested him to provide ships to transport 2000 people from Colombo
            to Jaffna. Mr. Kadirgamar called for a meeting with all the heads of
            department of the Ceylon Shipping Co. requesting them to prepare the
            ships to transport the 2000 people to Jaffna, including making
            arrangements for their food, sleeping and toilets by the next day.
          </p>
          <p>
            Ceylon Shipping Corp., had four ships loading and discharging cargo
            in the port when the management was ordered to prepare the ships to
            carry human cargo. The following day while the preparations were on,
            Nahil called Singapore to check the cost of chartering a 707
            aircraft that could transport 200 persons per flight in an hour,
            since they had a fleet of aircrafts parked in Cebu ready for
            charters. On inquiring he found this to be a much cheaper option
            with minimum inconvenience to the people, also not disturbing the
            CSC cargo operations. When the powers in charge heard of this, they
            were shocked and embarrassed in particular, because they had failed
            to think of this option. To save face, they insisted it had to be
            ships and not aircrafts and their request was adhered to. The ships
            were outfitted with toilets known as &lsquo;Thunder Boxes&rsquo;
            fixed outside the sides of the ships, suspended over the ocean along
            with the additions that included the plates, tumblers, mattresses
            and other paraphernalia needed to accommodate 2000 people, . If 200
            persons per trip were transported by air, they could have completed
            their journey within one and a half days.As it was, they were
            subject to 26 hours of voyage by ship from Colombo to KKS a total of
            430 nautical miles..
          </p>
          <p>
            <strong>
              <em>Fights and Surays</em>
            </strong>{" "}
            &ndash; A short time before he was introduced to Indrani, his first
            wife, he was in a dalliance with a female, a divorcee, who lived in
            Green Path. Her father was a famous rugby referee who owned a snack
            bar and her brother was also a rugby player who played fullback for
            the STC rugby team. Together, they were devoted members of the
            CR&amp;FC club. Nahil, also a member, attended a social held at the
            club after a Havies match, with the lass. He danced the first few
            tunes with her, later on moving to the bar with her for a drink.
            They were perched on high stools at the bar chatting to friends,
            when a Havies &lsquo;man about town&rsquo; Suren Thuraisingham,
            walked up to Nahil, seeking his consent to dance with the lass.
            Nahil gave the go ahead. The girl told him that the man&rsquo;s
            hands were all over her. Obviously feeling uncomfortable, she
            refused a second dance with him, came over to the bar and sat beside
            Nahil. Unable to handle the refusal, this man followed her, getting
            nasty and insulting her.
          </p>
          <p>
            Nahil immediately jumped to her defense shouting at him saying,
            &ldquo;How dare you?&rdquo; and took one swipe at his jaw. The guy
            who was perched on a high bar stool lost his balance and crased to
            the floor, after which Nahil didn&rsquo;t have to do anything. The
            whole of the CR&amp;FC membership present thrashed the guy. He was
            from Havies and there was a bit of rivalry between the clubs, with
            tension building up on the grounds during the match. When this
            affray triggered off at the shindig, he suddenly became a spectator
            to the rumpus, until Percy advised him to take his daughter and head
            home, which they did.
          </p>
          <p>
            While on their way to the car park, he heard footsteps behind him,
            only to glance over his shoulder and realize a man was rushing
            towards them. On impact, Nahil turned around and simultaneously both
            grabbed each other&rsquo;s shirt with the other guy yelling. The
            yelling went on between them for thirty seconds, by which time the
            CR members came over and stopped the fight. Once he got home he
            realised the chain around his neck had snapped, displacing his
            valuable &lsquo;suray&rsquo;. The next morning he mentioned this to
            Percy who went to the car park at 6:30 a.m. in search of the chain
            and &lsquo;suray,&rsquo; found it and returned it to him. Nahil
            maintains he has been blessed a couple of times by three priests. He
            still has two &lsquo;surays&rsquo; round his neck and wonders which
            one works.
          </p>
          <p>
            <strong>
              <em>The Andares</em>
            </strong>{" "}
            &ndash; While Nahil and Avril lived at Siripa Road, in 1981, there
            was a shortage of sugar in the market due to some bungling of
            shipments. Nahil, being the guy who thinks on his feet, got down
            bags of sugar from India on one of their ships and started to sell
            sugar to the public from their residence, lower than the market
            price. He bought two sealing machines and rolls of polythene,
            gathered a band of helpers, which included a young Jayantha who has
            remained his faithful driver for the past 48 years. There was also
            his sister Kanthi and her boys who were down on holiday from Kandy.
            The sugar was packeted and soldin one pound packets from a rear room
            window of their home, situated adjacent to a private road that
            served two houses at the back. He cut thewindow grill and made a
            little hatch to serve the long queue of customers. The house, dog
            and all present were covered in sugar for days. Imagine the scenario
            of &lsquo;Andare&hellip;&rsquo;
          </p>
          <p>
            <div
              style={{
                float: "left",
                margin: "10px",
              }}
            >
              <Image
                style={{
                  maxWidth: "400px",
                }}
                src={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/53-anecdotes/elkaduwa-tea-factory.jpg"
                }
              />
              <div style={{ textAlign: "center", maxWidth: "400px" }}>
                <p>
                  <small>Elkaduwa Tea Factory</small>
                </p>
              </div>
            </div>
            <strong>
              <em>The Happy Million $ Note</em>
            </strong>{" "}
            &ndash; During the privatisation of tea estates, Elkaduwa plantation
            in the Matale District was up for sale. Nahil&rsquo;s buddy N.U.G.
            Silva, a planter, was anxious to make a bid for Elkaduwa Plantations
            along with a foreign partner. To put up the bid, a bid bond of ten
            million rupees had to be furnished to the Government and NUG
            appealed to Nahil for a loan of this amount, to be replaced once the
            foreign investor came in with his funds. Time passed by and there
            was no sign of N.U.G.&rsquo;s foreign investor. The date of the
            privatisation was drawing close and failure to make a bid would be a
            loss of ten million. In the meanwhile Mr. Handunnetti of the JVP,
            quite vocal against privatisation of these plantations, was seen on
            TV slandering the Government&rsquo;s policy of privatisation, using
            Elkaduwa plantation as an example, while waving a copy of the bid in
            front of the TV camera with Nahil&rsquo;s name prominently displayed
            on it.
          </p>
          <p>
            Shortly after, the Government changed, with the JVP being allied to
            the new Government.
          </p>
          <p>
            A few days later, N.U.G. informed him by telephone from
            Queen&rsquo;s Hotel Kandy that he had found a buyer with US$ 1
            million in cash to which Nahil said, &ldquo;Okay, cash is fine; see
            you in Colombo.&rdquo; At the end of the conversation, he heard
            N.U.G. say something to the effect that he would &ldquo;send a
            photocopy of the cash,&rdquo; which did not register with Nahil at
            the time, as he was busy and rushing through the call. NUG turned up
            the next day as promised at the East West World Trade Centre office.
            Nahil was expecting to see a box of currency, but instead was shown
            a US$ 1 million note which was about 18&rsquo;&rsquo; long. He was
            flabbergasted, not knowing where to start. Nahil was itching to take
            a photograph of N.U.G holding the note, but since he pleaded with
            him, he refained from doing so. There have never been US$ 1 million
            notes in circulation, which he proved to N.U.G. by showing this to
            him on the internet.
          </p>
          <p>
            On a closer examination of the note, he found that it was a
            &lsquo;Happy Million Dollar&rsquo; note, issued at random to kids
            visiting Disneyland! It was then that N.U.G. realised it was a joke.
            Nahil was yearning to take a picture of him holding the note.
            Unfortunately it never happened.
          </p>
          <p>
            Nahil&rsquo;s immediate quest was to recover his ten million rupees.
            He contacted the Treasury Secretary and told him that, at the time
            the bid was made, privatisation was accepted, and asked the Treasury
            Secretary whether, given the JVP&rsquo;s anti-privatisation stance,
            there was a possibility he could return the cheque to him. The
            Treasury Secretary informed him that he was not in a position to
            return it to him directly, but he would let the bid bond lapse, thus
            ensuring the validity of the cheque emerging as null and void.
            Thankfully the 10 million was salvaged.
          </p>
          <p>
            <strong>
              <em>Ex-Wives Club</em>
            </strong>{" "}
            &ndash; The divorce case of Indrani and Nahil was heard at the
            Family Courts in Bambalapitiya. On the advice of his sister, and
            other family members, he gave up all his rights: legal and physical
            custody of his son Vajira to Indrani since the child was three years
            old and should be with his mother, even though she had left Nahil
            for someone else. In hindsight, he says his decision on the
            child&rsquo;s custody was a big mistake.
          </p>
          <p>
            The divorce case had just commenced. That morning as he got to the
            office, his secretary had reminded him the previous day that his
            case hearing was on at 9 a.m. that morning. He rushed to the courts,
            though late, and was relieved to find the Judge himself had not
            arrived yet.
          </p>
          <p>
            Gathered there were Indrani&rsquo;s lawyer, Mr. Wickremanayake,
            Haritha&rsquo;s father, in suit and tie, her brother Manik who had
            been Nahil&rsquo;s college mate and her whole team, all dressed up
            in suits. Nahil who was in his work clothes kept apologizing for
            getting late even though the Judge hadn&rsquo;t turned up. . He was
            given a proxy form; a document that relieves him from hanging around
            on court dates in the hearings to follow. On receipt, he realised he
            had to make a payment for stamps and had no cash to do so since he
            had left his wallet in office. Noticing Nahil in a fluster,
            Indrani&rsquo;s lawyer&rsquo;s assistant opened up an envelope and
            pulled out his stamps worth 200 rupees, which he gave Nahil for the
            proxy form. While waiting for the Judge, he recalls Mrs. Gamage who
            sat at a desk in a corner of the courtroom, was retained to look
            after Nahil&rsquo;s interest and she was not going to her seat since
            she had to be paid. This payment too was forked out by
            Indrani&rsquo;s Lawyers assistant He left the courts embarrassed,
            mumbling a thank you, promising to reimburse the Rs. 400 to her
            lawyer.
          </p>
          <p>
            After a few more hearings, before the &lsquo;decree nisi&rsquo; was
            granted, the Court took a final shot at the possibility of
            reconciliation between the two parties. Indrani and Avril were
            getting on very well and she was there sitting on Indrani&rsquo;s
            side. The Judge questions: &ldquo;Indrani, is there a possibility
            for you and Nahil to reconcile, or are you in a relationship with
            someone else?&rdquo; She affirms, &ldquo;Yes, I am involved with so
            and so,&rdquo;. Thereafter the judge posed the same question to
            Nahil, which he affirmed with a &ldquo;Yes, I am involved
            too.&rdquo; The Judge wanted to know if she was present. He pointed
            out to Avril and said, &ldquo;She is the one.&rdquo; &ldquo;Here was
            my girl sitting with Indrani and the opposition,&rdquo; recalls
            Nahil. The Judge was so taken aback he says, &ldquo;Normally they
            are trying to kill each other and here is something new.&rdquo; He
            was so confused, he voiced his frustration with &ldquo;I don&rsquo;t
            know what&rsquo;s happening here,&rdquo; pushed back his chair and
            left. Case closed, divorce granted!
          </p>
          <p>
            <strong>
              <em>Uncle Norman</em>
            </strong>{" "}
            - Nahil&rsquo;s uncle Norman, his mother&rsquo;s brother, was very
            dear to their family and lived with them for a while at their home
            in Watarantanne. He was in the Postal service and went on to be the
            Postal Suprintendant of Kandy. He had a wide circle of friends and
            enjoyed meeting with his buddies with whom he had an occasional shot
            or two. He owned a Morris Minor and set off one evening in the car
            to meet up with his buddies after which he got back home quite late
            that night sans his car. He explained to the family that the car had
            broken down due to the clutch plate giving away.
          </p>
          <p>
            The next morning when they went along with him to tow the car to a
            garage, what they saw was that his car had climbed atop a mound of
            gravel by the roadside with all four wheels suspended in the air.
            Being sufficiently intoxicated he had thought his clutch plate had
            given away!
          </p>
          <p>
            <strong>
              <em>Half a name</em>
            </strong>{" "}
            - When Nahil took up citizenship in Singapore, the immigration
            office at their discretion shortened his name to Muthukuda Nahil
            Wijesuriya informing him that the system could not handle his full
            name. He says &lsquo;&rsquo;I was quite taken aback when I saw my
            name halved in my new passport. Unfortunately, I am addressed as
            Muthukuda in Singapore, which is now my first name according to my
            passport!&rsquo;
          </p>
          <p>
            <strong>
              <em>Neither here nor there</em>
            </strong>{" "}
            - Once they obtained their British Passports, Nahil&rsquo;s
            brother-in-law, Lakshman, applied for dual citizenship. On receipt
            of the five passports with dual citizenship, he proudly laid them
            all down on the dining table and announced&lsquo;&lsquo;we got our
            dual citizenship&rsquo;&rsquo;. Nahil who happened to be there at
            that moment said to Lakshman, &lsquo;&lsquo; all of you were always
            neither here nor there. Now you all are officially neither here nor
            there!
          </p>
          <p>
            <strong>
              <em>Love Trinity</em>
            </strong>{" "}
            -While he was working at the Ceylon Shipping Corp. as the Technical
            Manager, he overhearda board member of the CSC at a cocktail party
            talking about his great days at Trinity College. Nahil was certain
            that he was not a Trinitian and to avoid embarrassing him, Nahil
            called him aside and asked him when he was at TCK. He confessed that
            he was never at TCK but at Dharmaraja college but he wished he was
            at Trinity, and hoped Nahil didn&rsquo;t mind him spinning his
            story. Nahil told him to go ahead and enjoy himself.
          </p>
          <p>
            <strong>
              <em>&lsquo;Gunaratna &lsquo;anduwa genna&rsquo;</em>
            </strong>{" "}
            - Dushantha Abeysinghe&rsquo;s sister Kaushal, was married to
            Chandima Karunaratne, a Trinitian. Sri Saga&rsquo;s home in Colombo
            was SS Kumar&rsquo;s house at Thimbirigasaya. Sri Saga owned a BSA
            Bantam motorcycle and was a friend of Dushantha. As Dushantha lived
            around the corner and considered himself as a great mechanic, he
            volunteered to repair Saga&rsquo;s bike. Owning only one tool
            &ndash; a spanner - his slogan was &lsquo; Gunaratna anduwa
            genna.&rsquo; Gunaratna was his batman. Thirty minutes later he had
            dismantled the carburetor with this one &lsquo;anduwa&rsquo; and
            hurriedly put it back together. Saga realising Dushantha had messed
            it up, figured this was a waste of time and ordered Dushantha to
            wheel the bike to a garage down 9th lane, Colombo 3, where his
            regular &lsquo;bass unahe&rsquo; worked from. The bass unahe was
            excellent at repair and tuning all these fast and furious machines.
            As soon as &lsquo;Wale&rsquo; bass saw the bike he shouted
            &lsquo;mona ***buruwek meka galleuwada&rsquo; &ndash;( which ***
            donkey pulled this apart) Nahil and the rest of the gang were
            delighted and amused that their &lsquo;anduwa man&rsquo; was put in
            his place. They all turned away pretending not to have heard this
            until they left the place, after which it was one big laugh!
          </p>
        </Col>
      </Row>
    </div>
  );
};
