import { Row, Col, Image } from "antd";

export const TheAgnesWijesuriyaCharity = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="the-agnes-wijesuriya-charity-fund">
          The Agnes Wijesuriya Charity Fund
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/59-the-agnes-wijesuriya-charity-fund/agnes-wijesuriya-trust.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Opening ceremony</small>
              </p>
            </div>
          </div>
          <p>
            While contributing monthly towards the welfare of ten medical
            students, he asked himself what he had done for those in his
            profession. He decided instantly to donate to the associations a
            five-level office building he owned in Colpetty. He got the building
            refurbished inside and out, using cladding for the exterior and
            modernising it. The income generated from the rented spaces used to
            go directly into the recently initiated &lsquo;Agnes Wijesuriya
            Charity Trust Fund.&rsquo; The fund comprises two trustees chosen
            from the association and two of Nahil&rsquo;s family members. This
            was formulated as an irrevocable trust fund, allowing the Trustees
            to appoint/dismiss, etc. whenever the need arises.
          </p>
          <p>
            The building generated around one million rupees a month from the
            tenants, to be used through the trust by the association for their
            purpose. An entire floor was designated for the Institute of Marine
            Engineers and still remains so even under the new ownership. Dialog
            towers are also situated atop the building, with the balance four
            levels tenanted to a mix of companies. One of the tenants using two
            floors operating &lsquo;My Little Island&rsquo; - a youth hostel for
            budget travellers, operated by Nahil&rsquo;s elder son, Vajira and
            his wife. Sadly, they recently closed up and sold off all the
            furniture and fittings of the hostel due to the slump in tourism,
            thanks to Covid-19.
          </p>
          <p>
            In terms of value, this building is situated adjacent to the Damro
            Hotel/Marino Mall, just off Galle Road, behind the petrol station,
            opposite the Dasa building in Colpetty. He bought it 10 years back
            for a hundred million rupees andrecently sold it to Damro for 300
            million rupees.
          </p>
          <p>
            There was a time during the 30 years of civil war when the LTTE
            surrounded 600 policemen and gruesomely murdered them in the eastern
            jungles of Tirukkovil in June of 1990 when the then government after
            negotiating with the LTTE, trusting them, ordered the cops to
            surrender to the LTTE.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/59-the-agnes-wijesuriya-charity-fund/agnes-wijesuriya-trust-awards.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Awards Ceremony</small>
              </p>
            </div>
          </div>
          <p>
            The Air Force in retaliation to the atrocity started aerial bombings
            of LTTE hideouts with helicopters, which was an expensive and
            time-consuming exercise, considering the logistics of fuelling in
            Colombo and flying to the east, thus halving the fuel content,
            giving the helicopter pilots only a small window of time and fuel to
            carry out their combat, since they had to return to Colombo
            utilising the same fuel. When Nahil heard about this situation from
            a college mate who was an official in the Navy, he offered to help
            solve the fuel situation and the transfer of ammunition by providing
            the Navy his tug, to be anchored on the east coast to enable the
            choppers to refuel and return to their operations without delays.
            The Commander in charge, Mohan Jayamaha, had previous experience
            during his training period overseas in this kind of operation where
            helicopters can land on decks of small ships that are moving and was
            keen to facilitate it without delay. They planned to layer the tug
            with six containers packed with ammo and fuel.
          </p>
          <p>
            It was further planned to fix guns on the front and rear of the tug,
            which would be well-shielded with the containers of ammo. It was to
            be a proper bunker. They had made a platform as a landing area for
            the choppers on top of the containers. In choppy weather you lower a
            rope down from the helicopter and anchor it down coupled to a winch,
            keeping it steady. The helicopter then tries to fly out keeping the
            wire taut. With the wire taut the tug and the helicopter start
            moving together in unison, at which point the fuel and ammo are
            loaded and the chopper is ready to go again.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "300px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/59-the-agnes-wijesuriya-charity-fund/imarest-certificate.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>IMaREST Certifcate</small>
              </p>
            </div>
          </div>
          <p>
            When the Head of the Navy, Ananda Silva, took over, he realised
            everything was going great and wanted to know how long the Navy
            could have the tug. Nahil offered it to them free for two months
            instead the Commander wanted the operation for a longer period. He
            then intimated to the Commander that in that case, the Navy would
            have to pay him for the use of the tug. Though it was never his
            intention, they presumed he would charge them an exorbitant fee and
            told him so. He had no time or patience to haggle numbers with them,
            thus the operation was suspended. Every one was dissapointed, mainly
            Mohan Jayamaha.The tug never left Colombo.
          </p>
          <p>
            Unfortunately, during this period the Commander died in a LTTE bomb
            blast along with Denzil Kobbekaduwa, before the plan got underway.
            His role was taken ober by Ananda Silva who was the head of the
            Navy.
          </p>
        </Col>
      </Row>
    </div>
  );
};
