import { Row, Col, Image } from "antd";

export const ArcadiaandCompassHeights = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="arcadia-and-compass-heights-singapore">
          Arcadia and Compass Heights Singapore
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/55-arcadia-and-compass-heights-singapore/arcadia-living-room.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Apartment at Arcadia</small>
              </p>
            </div>
          </div>
          <p>
            During the SARS epidemic in Singapore, the property market plummeted
            to an all-time low, opening up endless purchase opportunities for
            those seeking to buy investment properties. Nahil was dealing with
            Deutsche Bank, whose management was willing to extend multiple
            facilities, as long as the borrower was known to the bank and the
            estate was good. Eunice Kong, a Singaporean realtor and her family,
            over time, became close friends with Nahil. Her husband was the
            maintenance engineer of the Arcadia, the largest condominium complex
            in Singapore built a while back by S.P. Tao, the owner of the
            Havelock City devolepment in Colombo. All the apartments at the
            Arcadia featurea minimum square footage of a whopping 4,000 sqft.
          </p>
          <p>
            Tao had built many malls and office complexes, but the Arcadia was
            the only condo he built. Shortly after building the Arcadia, since
            the apartments were not selling, Tao and his Indonesian partner
            bought two apartments at the Arcadia at their own expense to meet
            the cash flow of the Arcadia complex. Nahil was aware that Tao had
            bought two penthouses and living there when the SARS epidemic broke
            out and Singapore was deserted. During this period, Nahil emplaned
            into Singapore and once inside the arrival lounge he noticed the
            terminal was so deserted he thought he was in a new terminal at the
            airport. While walking through he could see on a TV screen in the
            terminal building, the URA graph pricing taking a massive hit. He
            figured this was the time to invest in an apartment at Arcadia which
            was being sold at a bargain due to the circumstances prevailing in
            the country at the time. The price was so attractive that shortly
            after he bought every apartment that came on the market a total of
            eight 4000sqft apartments and joined two of the eight apartments, to
            make up a penthouse totalling a massive 8,000 sqft of living
            area.&rsquo;&rsquo;
          </p>
          <p>
            Nahil rented out the penthouse to the manager of Air France
            Singapore, a French national, with a great sense of humour, whom he
            got to know quite well. A few months into his stay, considering Air
            France was taking austerity measures, with the staff instructed to
            cut corners wherever possible, he confessed to Nahil, that the
            apartment was far too big for him and he needed to move out. He was
            accommodating since the tenant had a genuine problem and thus
            cancelled the contract.
          </p>
          <p>
            The tenant mentioned to him how he watched his little seven year old
            daughter walk towards the pantry to get her milk from the fridge in
            the night, only to do a &lsquo;U&rsquo; turn and come back. When he
            inquired from her as to why she turned back, she had replied,
            &ldquo;The kitchen is too far!&rdquo;
          </p>
          <p>
            Nahil kept one of the eight apartments for himself, completely
            flipping it, remodelling the toilets, fixing new ACs, refurbishing
            it throughout and finally listing it for sale at a
            highly-unrealistic, inflated rate, considering this was his habitat.
            At this point, his dear friend and realtor, Eunice Kong entered the
            picture. She brought along an Indian lady in a saree for a viewing.
            The lady had a good look around and conveyed to Eunice that she
            liked the apartment. Meanwhile, Eunice had inflated Nahil&rsquo;s
            quoted price so much so that he looked nonplussed when he heard her
            quote the price to the buyer. She gave him a &lsquo;keep your mouth
            shut&rsquo; look and the deal was done.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/55-arcadia-and-compass-heights-singapore/arcadia-balcony.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Apartment at Arcadia</small>
              </p>
            </div>
          </div>
          <p>
            The background to this story was that the lady and her husband were
            Singaporeans who lived in the States. Her husband, a Financial
            Director of a bank in the US, had passed away and she was returning
            to Singapore. The bank generously offered to buy her whatever
            property she fancied in Singapore. She loved the property owned by
            Nahil so immensely that the bank bought it for her. This was a
            lottery for Nahil. Eunice knew all about this when she brought her
            client for the viewing, hence the &lsquo;shut up&rsquo; that came
            Nahil&rsquo;s way. He sent Vijitha, who was studying in London, a
            mail exulting on his good fortune and he replied saying,
            &ldquo;Thaththi, why did you sell it?&rdquo; &ldquo;That&rsquo;s
            your home.&rdquo; However, Nahil&rsquo;s thoughts on this were it is
            not often that someone makes a profit of two million in a day. That
            was so cool.
          </p>
          <p>
            After Nahil sold all his apartments at the Arcadia in Singapore, he
            bought a duplex at the Compass Heights in Sengkang, a penthouse with
            a roof top landscaped garden, where the condo was above the mega
            Compass mall, with the MRT below that. It was so convenient that
            once he closed his entrance door he could be down at the MRT in 45
            seconds.
          </p>
          <p>
            The distance to Dhoby Gaut from Senkang was 20 minutes, which was
            not too bad considering it was almost the end of the MRT line,
            therefore seats were available at any time of the day.
          </p>
          <p>
            After a few months, he decided to sell, pondering the fact that at
            his age, living alone in a duplex was personally a bad choice. In
            the event he had a fall climbing the stairs and broke his ankle or
            some other unforeseen disaster, no one would ever find him. He sold
            the apartment.
          </p>
        </Col>
      </Row>
    </div>
  );
};
