import { Row, Col } from "antd";

export const IBMSriLanka = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="ibm-sri-lanka">IBM Sri Lanka</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            In the 1880s technologies emerged that would ultimately form the
            core of International Business Machines (IBM). Julius Pitrap
            patented the computing scale in 1885; Alexander Dey invented the
            Dial Recorder, Herman Hollerith patented the Electric Tabulating
            Machine and Willard Bundy invented the time clock to record
            worker&rsquo;s arrival and departure.
          </p>
          <p>
            These four companies were amalgamated in New York on June 16th, 1911
            by Charles Ranlett Flint, forming a fifth company - The Computing -
            Tabulating - Recording (C-T-R) Company located in Endicott New York.
            The five companies employed 1,300 people with their offices spread
            around &nbsp;New York, Dayton Ohio, Detroit Michigan, Washington DC
            and Toronto Canada.
          </p>
          <p>
            They manufactured machinery for sale and lease, ranging from
            commercial scales and industrial time recorders, meat and cheese
            slicers to tabulators and punch cards. Thomas Watson Sr., who was
            fired from the National Cash Registry Company (NCR) by John Henry
            Patterson, called on Flint looking for a position at C-T-R. Watson
            joined C-T-R as General Manager and eleven months later he was made
            President of C-T-R once his court case relating to his time at NCR
            was resolved. Watson proceeded to put the stamp of NCR into C-T-R
            companies, having caught on to Patterson&rsquo;s pioneering business
            practices. He implemented sales conventions, &ldquo;generous sales
            incentives, a focus on customer service and insisted on
            well-groomed, dark-suited salesmen.
          </p>
          <p>
            He had fervor for instilling company pride and loyalty in every
            worker. His favourite slogan - THINK - became a mantra for each
            company&rsquo;s employees. During his initial four years, the
            company&rsquo;s revenue reached US$9 million with the
            company&rsquo;s operations expanding to Europe, Asia, South America
            and Australia. He was not in favour of the hyphenated company name
            and on February 14th, 1924, chose to replace it with a more
            expansive title - International Business Machines - IBM which is
            today an American Multinational Information Technology company, with
            its headquarters in Armonk, New York, with operations in over 170
            countries including Sri Lanka.&nbsp;
          </p>
          <p>
            &nbsp; The head of IBM Sri Lanka, Harish Vohra - the Country Manager
            and his team at IBM were housed at the ICC building. Meeting him at
            a function, Nahil suggested to him that a company like IBM should be
            in a better location than the present, citing the East West building
            which was under construction, as a superior alternative. Vohra,
            without hesitation, readily agreed to give a &lsquo;Letter of
            Intent&rsquo; to rent out the EW building once it was completed.
            Before their move into the second floor of the new East West
            building, IBM sent their property expert from Hong Kong to finalise
            the move. After the negotiations he wanted to know what the rental
            premium would be if they were to ask for the naming rights of the
            building as the &lsquo;IBM Building,&rsquo; this was quite normal in
            the high end building rentals. Nahil conveyed to him they were not
            interested in a &nbsp;premium for -&rsquo;naming rights&rsquo;
            &nbsp;and he was shocked. Later on, in a lighter moment, the IBM
            property expert expressed his surprise &nbsp;to which Nahil replied
            &lsquo;&lsquo;I&rsquo;d rather be known as the owner of the IBM
            building than the East West building!&rsquo;&rsquo; Nahil and Vohra
            got on very well. A few months later he informed Nahil, he was
            looking for a house to rent, his eye on the house Nahil and Avril
            were living in on Reid Avenue. He said, &ldquo;Aah, I&rsquo;ll take
            that.&rdquo; &ldquo;This worked out well for us too,&rdquo; says
            Nahil. He leased the house to Vohra fully furnished and moved with
            her into a room at the Hotel Lanka Oberoi (now Cinnamon Grand) for
            two months. The room had a great view of the building site, so he
            could overlook the construction of the building 24x7. By now Vohra
            had his office and his house. Then he says, &ldquo;Nahil you have a
            Volvo car, can I rent that too?&rdquo;
          </p>
          <p>
            This was the typewriter era and sadly, by default, Harish Vohra knew
            nothing about computers. IBM usually has an Annual General Meeting
            in the Asia Pacific region. This particular year the AGM was in
            Australia and the slogan of the meeting was that every IBM executive
            must have a PC on his desk.
          </p>
          <p>
            When Vohra got back, Nahil dropped in for a chat with Vohra
            &nbsp;when he noticed a PC on his table. He knew Vohra didn&rsquo;t
            have a clue about a &lsquo;PC from an A/C&rsquo;. Whilst sitting
            across from him, Vohra stands up and says, &ldquo;Nahil, can you
            please tell me how this bloody thing works?&rdquo; &ldquo;He had
            just unpacked the PC and in there was a disc &lsquo;learn your
            PC&rsquo;. I inserted the disc and the display said &lsquo;press
            letter A&rsquo; &ndash; the letter A comes on the screen.&rdquo;
          </p>
          <p>
            Vohra&rsquo;s job was to sell computer hardware and software,
            something which he excelled in, notably never defaulted on any
            payments. Nahil recalls how Vohra would embarrass Vajira
            Wijewardena, who never paid his bills on time. Whenever he met
            Vajira at a public place, he would shout across the room, &ldquo;Hey
            Vajira, you haven&rsquo;t settled my bill yet.&rdquo; Quite a
            personality!
          </p>
          <p>
            With the ground and first floors of the building already leased out
            to IBM, Lester and Nahil were occupying the top floor with a view.
            They had their desks and cubicles there; the entire floor fitted out
            with high-end office furniture.
          </p>
          <p>
            One morning, a few guys from Mitsubishi visited the IBM building
            intending to request Nahil to rent the top floor of the building to
            Mitsubishi. He was quite exasperated since this space was outfitted
            especially for Lester and himself. Hoping to turn the
            representatives from Mitsubishi off, he said alright and quoted an
            exceptionally high rent. They said, &ldquo;That&rsquo;s fine, we
            will take it.&rdquo; Trying to put them off he requested three
            months&rsquo; rent in advance. They countered by offering one
            year&rsquo;s advance if he could discount the rent. This was a
            smashing deal. Working out numbers in his head, he went with his
            head and agreed. Interest rates were very low and this was a winner.
            Later he called Lester and worked out the math for him. &ldquo;I
            told him this was a great deal and we could easily pay off our bank
            loans without hanging around on the top floor looking at the view.
            Do we really need a view?!&rdquo;
          </p>
          <p>
            After Harish Vohra was transferred out of Sri Lanka, the newly
            &nbsp; &nbsp;appointed Head of IBM was a dear friend of
            Nahil&rsquo;s, Lakshman Hettiarachchi. Amazingly Lakshman had a
            penchant for associations. Name an association and he was a part of
            it. One evening, as they met for a drink, Nahil was in a mood to
            pull his leg and said to him, &ldquo;Lakshman, you are holding all
            these responsible positions; there is this new association, The
            Association of Professional Office Bearers&rsquo;.&rdquo; Lakshman
            replies, &ldquo; Really Nahil&rsquo;&rsquo;?
            &lsquo;&lsquo;Appreciate if you can send me their contact.&rdquo;
            Nahil had made up all this stuff &nbsp;just for a laugh. He felt
            ragging his buddy a bit was allowed since their friendship went way
            back to his time in the UK when Nahil shared a room with him at
            Sunil Perera&rsquo;s and Laksman Umagiliya&rsquo;s, in Dr.
            Umagiliya&rsquo;s flat at No.14 Westbourne Terrace. Due to lack of
            space they had slept on the floor in sleeping bags. Sadly he is not
            in the best of health now.
          </p>
        </Col>
      </Row>
    </div>
  );
};
