import { Row, Col } from "antd";

export const Reflections = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="reflections">Reflections</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            While they were operating EWE on the fourth floor of Ceylinco House,
            their office overlooked the brand new Intercontinental Hotel, the
            first 5-star hotel in town with 5-star prices. Whenever they had
            guests from oversees visiting the office, especially closer to noon,
            the courteous thing to do was to offer them lunch, which they did,
            inquiring from the guest as to where they would like to dine, while
            holding their breath hoping they wouldn’t choose a restaurant at the
            Intercontinental. Being a small operation of five, Lester, his niece
            Anne, secretary Feroza, Man Friday - Farook and Nahil, their petty
            cash did not stretch enough to afford a meal at the
            Intercontinental. However, a few years later their petty cash
            stretched far enough to buy the hotel, which they did!
          </p>
        </Col>
      </Row>
    </div>
  );
};
