import { Row, Col, Image } from "antd";

export const TheForbesAndWalkerBuilding = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="the-forbes-&-walker-building">The Forbes & Walker Building</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/62-the-forbes-%26-walker-building/forbes-and-walker-building.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Forbes & Walker Building</small>
              </p>
            </div>
          </div>
          <p>
            Vanik, a financial securities company that issued bonds with very
            high interest rates was launched in 1980. Vanik was doing well and
            heading the company as Chairman was Justin Meegoda. Besides buying
            the bonds, Nahil also bought sufficient shares in Vanik that
            automatically made him a Board member of the company. Being a Board
            member helped him study how the system worked. The articles of
            incorporation of Vanik stated that no shareholder could buy more
            than 10% shares. Upsetting this rule, he bought 12% of the shares,
            so that as a member of the Board he could keep an eye on his bonds
            which were above Rs.300 million paying an interest of 15%.
          </p>
          <p>
            The first year it all went well and the interest was paid without a
            hitch. The next year on one occasion he found out that all the
            interest cheques had gone out to the investors while his interest
            cheque was held back. Inquiring as to why he was not paid, Justin
            Meegoda informed him that since he was a Director they would settle
            him sometime later. He vehemently protested, informing Justin that
            he got on the Board as a Director to find out how the system worked
            and to make sure he got his interest paid to him along with the
            other investors. He found out that there were various conditions in
            place pertaining to the payment ofinterest and that one such
            condition was the failure to make the interest payment permits the
            receiver to request the liquidation of the company. The reason for
            the Board panicking, was that they were short of finances. Nahil
            requested his bonds back and since they were short on cash, in place
            of his investment they offered him the Forbes and Walker building on
            Navam Mawatha, Lanka Tiles and a mix of shares in various companies
            to make up the difference. This was how he acquired the Forbes and
            Walker building.
          </p>
          <p>
            The members of the Vanik Board were persons who were really serious.
            He remembers Mr. Tilakaratne, ex-Governor of the Central Bank; Ajith
            Jayaratne, Chairman of Forbes and Walker; and Ricky Mendis, the
            Chairman of Delmege Forsythe. While things took their course, the
            final straw was the Vanik Board meetings which would go on for eight
            hours at a stretch.
          </p>
          <p>
            They also owned a soya bean company and a tile company. Though Vanik
            started as a very successful financial firm, its downward spiral was
            due to diversifying into unchartered territory especially with the
            purchase of Forbes &amp; Walker. Finally, it was decided at a Board
            meeting to liquidate all the subsidiaries and stick only to Vanik,
            which was their &lsquo;core&rsquo; business.
          </p>
          <p>
            The following month at a meeting, Justin Meegoda informed the
            members that he had got a new recruit, Mr. Smith, to head the travel
            company, Tour East Lanka. Nahil and the rest of the board were taken
            aback that Justin had found a new top manager from Emirates Holidays
            This was because the travel agency was in the throes of being shut
            down with the head of the agency migrating to Australia and this was
            the easiest manner to shut down the agency without retrenchment
            compensation. The board had decided to wind down all the
            non-financial businesses including the soya bean company, the tile
            company and the plantations to name a few along with the travel
            agency, and now Justin comes up with a new manager for the travel
            agency. It seemed a revival was taking place.
          </p>
          <p>
            Nahil questioned the Board on how they planned to meet the expenses
            of operating the travel agency, including a car for the manager.
            Justin was quite confident that a car could be bought for Mr. Smith
            with a loan arranged on a bank guarantee given by Vanik to the
            travel company. Nahil then informed the Board{" "}
            <strong>
              that giving a bank gaurantee was as good as giving cash
            </strong>{" "}
            and as discussed at a prior meeting, they were supposed to stick to
            the core business, telling them that if they carried on like this,
            they would soon be saying &lsquo;flda&rsquo; business!&rsquo;&rsquo;
          </p>
          <p>
            Vanik operated the first credit card company in Bangladesh. One
            weekday at the end of an eight hour meeting, as Ajith Jayaratne and
            Nahil stood waiting for the lift, Mr. Tilakaratne ambled towards the
            lift and informed him that Justin and he were travelling to
            Bangladesh for their Board meeting. Nahil was livid, questioning as
            to why two people had to travel for the meeting that could be
            chaired by one person. They were booked to fly business class and
            their per diem was US$ 600 per person for four days including a
            separate charge for hotel accommodation. He was able to extract
            these figures through the travel company Tour East Travels. Before
            their return, he did a full search into corporate travel and found
            that by way of expenses, they were far ahead of the more profitable
            agents in the market. At the next Board meeting, he voiced his
            concerns by suggesting they scale down, cutting down on spending and
            working within their means. Nahil was a person who always traveled
            coach (economy class) starting from his first trip to the UK as a
            student; and now in his 70&rsquo;s he always travels economy class
            and pays for the tickets. Whenever he has travelled business class
            it has been due to an upgrade using his points or his ticket being
            paid for if he is on a consultancy. Many people question him as to
            why he opts for coach when he can easily afford business class.His
            reply was &lsquo;&lsquo;I can interact with people of diverse
            backgrounds which I find extremely interesting&rsquo;&rsquo;.
          </p>
          <p>
            A few years later, no longer involved in Vanik, while boarding a
            flight he had to pass through business class to get to coach, he
            bumped into Justin Meegoda who was flying business class. He
            remarked to Justin, &ldquo;Here we go again, you are flying business
            class.&rdquo; They had a good laugh with him seizing the opportunity
            to make fun of the situation.
          </p>
          <p>
            Later on, whilst winding down his empire on Navam Mawatha, he sold
            the Forbes &amp; Walker building to Dilmah, who were willing to pay
            a premium price for the property since they already owned Forbes
            &amp; Walker plantations.
          </p>
        </Col>
      </Row>
    </div>
  );
};
