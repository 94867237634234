import { Row, Col, Image } from "antd";

export const Roshanne1966To2002 = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="roshanne-1966-2002">Roshanne 1966-2002</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/49-roshanne-1966-2002/roshanne.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Roshanne</small>
              </p>
            </div>
          </div>
          <p>
            It was the 29th of July 2002. Nahil received a call from Mahen at
            around 9:30 p.m. informing him that Roshanne had met with an
            accident while crossing the main road at Wattala after a party, and
            requested him to come to the Accident Service at the GHC. He rushed
            there with the kids assuming that it was nothing too serious, only
            to find out on arrival that the accident was really serious and even
            though a team of doctors had tried to resuscitate her, she had
            passed away at the hospital. This was another dark moment in his
            life. He was anxious for the two children, 14 and 15 at the time,
            absolutely empathising with their pain. His heart was broken for
            them since this was not an age to lose a mother in such a fashion,
            to see her lying on a hospital bed bleeding from her nose. The next
            few days were a whirlwind. Avril took charge of things, made all the
            funeral arrangements, and Roshanne was cremated two days later. Her
            ashes are interred in the family vault on the grounds of his family
            home in Galkanda road Aniwatte. He believes the Negambo road is like
            a motorway and the house where the party was held was down a lane on
            the seaside, whereas the car in which Roshanne intended to return to
            Colombo, was parked on the land side of the Negombo/Colombo road.
          </p>
        </Col>
      </Row>
    </div>
  );
};
