import { Row, Col, Image } from "antd";

export const GiftingtheClubHousetotheTCKOBA = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="gifting-the-club-house-to-the-tck-oba">
          Gifting the Club House to the TCK - OBA
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            The Asgiriya Grounds was too small and a large-scale revamp of the
            grounds was necessary for future cricket and rugby matches played
            there and to accommodate a large number of spectators. Thankfully,
            Gamini Dissanayake, an old boy of Trinity and the Minister of
            Mahaweli Development, took over the project, directing the
            contractors to lower the level of the ground by five to six feet
            with their bulldozers, thereby making the ground bigger bringing it
            up to international standards. Unfortunately, this exercise
            destroyed the area where the Club House was situated. Haleem Deen,
            the President of the OBA at the time, says, “Nahil, look at this
            place, can you please build this for us?” This was 25 years ago. A
            few years ago Nahil got a plaque commemorating the 25th year opening
            of the Club House.{" "}
          </p>
          <p>
            Rising up to the occasion, as usual, he offered to fund the club
            house project. The Club House was designed by an old boy, a
            chartered architect, Susil Wedikara, after which the OBA called for
            tenders for the building of the Club House. Some of the members
            informed him that the awarding of the tender was delayed due to some
            members accusing each other of having a proposed interest with the
            contractor. The President of the OBA, Haleem, looked for a way
            around this conundrum. He thought of Nahil’s father Gratian who was
            well-known for his construction prowess.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/15-donations-to-trinity-college-kandy/tck-oba-25-years.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Old Trinitians Sports Club – 25 years</small>
              </p>
            </div>
          </div>
          <p>
            Gratian, who was retired by then, was requested to accept the task
            to build the Club House for the OBA. It was a smart move by Haleem.
            He could rest assured there wouldn’t be any infighting or arguments
            among the members as it was Nahil’s money, with his father in charge
            of building it. Nahil’s father took up the challenge, really going
            to town on the project, saying to him: “If I am to do a proper job,
            over budget, that’s your problem.” Subsequently, the new Old
            Trinitians Sports Club turned out to be a great gathering point for
            the members.
          </p>
          <p>
            It is a tradition during the Kandy leg of the Bradby, that the TCK
            club bar is an ‘open bar’ for visiting Royalists untill one in the
            afternoon on match day. The dilapidated state of the Club House had
            always been an embarrassment to the old boys. With the new club in
            place, it was truly a pleasure hosting the RC supporters. Nahil says
            he too has had some good times at the club on many an occasion.
          </p>
          <p>
            Due to his multifaceted involvement in college projects, somehow one
            thing led to another and suddenly he was referred to as a trustee of
            the college OBA. Rather bemused, he had asked them if they knew the
            first thing about appointing trustees. To be eligible as a trustee,
            there has got to be a trust deed, conditions of appointment, etc. He
            had prior knowledge of how this works since he went through the
            motions putting together his mother’s Memorial Trust, including the
            mechanics of how complex it is to be a trustee. “These college guys
            think that if you trust a person, he’s a trustee!” They put his
            photograph right on top; in Nahil’s opinion, as a reminder to call
            him whenever there was anything to be done. He still dips into the
            old pocket and does his bit for Trinity.
          </p>
          <p>
            With the ‘trustee’ thing not working out, soon after they
            requisitioned him to join the Board of Governors and Nahil
            reiterated that he was not interested in positions that would
            reflect nicely on one’s CV. If there is a job to be done they should
            let him know, enabling him at the end of the year, together with the
            Board, to set up some targets for everybody. Whether he likes it or
            not as the President of the OBA, he automatically has a seat at the
            Board of Governors. He questioned the Board as to what targets
            should be achieved by the end of the year, so that they could
            prioritise, giving the urgent matters their immediate attention.
          </p>
          <p>
            He found the lease on the Asgiriya Grounds was ending, took the
            matter over and got the lease not only extended but with a bit of
            lobbying they got Chandrika Kumaratunge to sign the freehold as
            well.
          </p>
          <p>
            Next on the agenda was a website for the school; which was a simple
            matter but once again only talked about until he took it over. and
            the job was done. The next big project was to try and initiate an
            ISO 9000 rating certificate for the school which would rate the
            college performance, customer satisfaction, and everything that can
            be geared within that framework. It would also rate the management
            procedures and judge the management’s efficiency to enable the
            qualification and assessment of the management from the Principal
            downwards, which would be a first in Sri Lanka. They had the added
            advantage of having on board A.G. Ranasinghe, who was well versed in
            the setting up of ISO ratings, along with Mohan Samarakoone, who was
            based in Kandy. The British Principal Rod Gilbert, Nahil’s nemesis,
            was not happy since he would be monitored and under someone’s
            purview. Up to this time he only reported to the Bishop of
            Kurunegala, whereas with the ISO, his performance and that of the
            school would be assessed by a competent authority. He was up in
            arms, opposing the suggestion with a tantrum, demanding that Nahil
            should rein in since he had ‘never experienced this type of thing’
            in his career. Nahil gleefully took this opportunity to inform the
            Board including Rod G., that taking into consideration they had
            never done so many things before, this was the time to do so. This
            led to really heated arguments with the Board. “I can’t say I did
            not enjoy going for the jugular of some of the members, especially
            the administration, each time we met.” The ISO never came to
            fruition.
          </p>
          <p>
            Two or three years later it was found that Rs. 240 million of TCK
            funds could not be accounted for. While the inquiry into the
            misappropriation of the funds was underway, Nahil who had left the
            board by that time supported Nishantha De Alwis who, though he was
            not an old boy but his son was a Trinitian, had filed a case
            pertaining to the missing funds.
          </p>
          <p>
            It was unfortunate that the other side of the legal battle was being
            sponsored by some old boys who were Nahil’s friends. The courts
            ordered a forensic audit, not for just balancing the accounts, but
            to delve deeply into each transaction, without taking any document
            at face value, while questioning each of the documents.
            Consequently, the audit exposed discrepancies, showing many of these
            members were involved in a major controversy.
          </p>
        </Col>
      </Row>
    </div>
  );
};
