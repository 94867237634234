import { Row, Col } from "antd";

export const FamilyDrama = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="family-drama">Family Drama</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            A few days before Nahil sat for his Chief Engineer exam in London,
            Indrani dragged him into a serious argument over something trivial.
            Lying in bed, as she continued her tirade, he covered his head with
            a pillow to block out her incoherent ramblings. Just then he heard a
            loud thud. As he jumped out of bed, he realised that she, known for
            her bad temper, had harmed herself with a porcelain ashtray, hitting
            her head with it. This was a tricky situation; Nahil urgently called
            Doctor Leonard Kiriella, a doctor friend of Indrani’s family who
            lived about three miles away. He briefly explained the distressing
            scenario to the doctor, immediately driving her as fast as he
            possibly could to Leonard’s residence to get her examined and
            attended to.
          </p>
          <p>
            In urgent need to cover all his bases, he called her father, Hector,
            and explained the entire incident, making sure that Hector and the
            rest of her family knew he would never touch Indrani aggressively.
            Nahil also explained to Hector that Indrani was attended to by a
            doctor friend of his. Meanwhile, on hearing the news, the family was
            distraught and making a huge commotion about it. Thankfully, it was
            just a superficial wound that needed a few stitches. However, it was
            important to him to have it on record that the injury was
            self-inflicted. Nahil realised this kind of behaviour was very
            alarming. Determined to find a way to resolve further disaster, the
            couple left the UK almost immediately after his exam was over. His
            worst fear was that she would attempt to or jump overboard in a
            temper. Back in Colombo, he sailed a few more voyages while Indrani
            opted to stay behind, living in one of her family-owned flats in
            Gower Street.
          </p>
          <p>
            Thereafter, he sailed on MV Lanka Rani, Shanthi and Devi as a First
            Class Marine Engineer, qualified and certified by the Board of Trade
            UK to head any ship as Chief Engineer.
          </p>
        </Col>
      </Row>
    </div>
  );
};
