import { Col, Image, Row } from "antd";
import { ChapterFooter } from "../chapterFooter/chapterFooter";

export const LeicesterCollegeEngland = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="leicester-college-england">Leicester College England</h1>
      </div>
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={24} md={24}>
          <p>
            The year was late 1967 – Met on arrival at Heathrow by Dayantha
            Liyanage, Nahil was driven in his Mini on the highway to his family
            home at 48, Glenhill Close, Finchley. He remembers being overwhelmed
            at the speed on the highway, which made him earnestly request
            Dayantha to slow down. He stayed overnight, leaving for Leicester
            College the next day. An attack of food poisoning, probably due to
            something he ate on the flight, made his first night in the UK
            rather unpleasant. Dayantha’s mother was a wonderful lady, who made
            a fuss over him, going to the lengths of getting a doctor to the
            house to attend to him.
          </p>

          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/06-leicester-college-england/dayantha-liyanage.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Dayantha Liyanage</small>
              </p>
            </div>
          </div>
          <p>
            Setting aside his stomach issues, he had woken up early the
            following day and left to Leicester to start his HND in Mechanical
            and Production Engineering studies.
          </p>
          <p>
            Though he had been requested by the College to apply through the
            Ceylon High Commission, he had short-circuited the request by going
            directly to the College. It so happened that the day he arrived at
            the college was the day registrations were taking place. The
            Registrar reviewed his papers and informed him that he should have
            submitted his application via the High Commission. Nahil then
            informed the gentleman that the letter he received from the College
            indicated that he appeared to be qualified, although the originals
            of the certificates had not been submitted. He presented the
            certified copies of the originals to the Registrar. Glancing through
            the certified copies that were presented to him, the Registrar said:
            “Welcome! Your student life in the UK has just begun.”
          </p>

          <p>
            The start of University comprised exhilarating days. As a freshman
            and foreign student, he was given preference to stay at Glenfield,
            which was a part of the campus, for one year, after which he lived
            in a house shared by six students at Loughborough Road ‘‘Life was
            easy-going carefree and uncomplicated. As students, we lived it to
            the fullest. The Beatles were big and so was Elvis. Rock ‘n’ roll
            was in full swing and the flower power culture was taking over the
            youth in the West; life was good, the world our oyster,” says Nahil
            with a look of melancholy that speaks of good times, sad times and
            great memories.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/06-leicester-college-england/mechanical-engineering-certificate.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Certificate in Mechanical Engineering</small>
              </p>
            </div>
          </div>
          {/* <p>
            Two weeks into his stay in Glenfield, he received a letter from his
            girlfriend Maya, with the news that she was getting married to a
            Planter, fellow Trinitian and acquaintance, Leelananda Madawela. “I
            felt so insulted,” says Nahil. She had also mentioned in her letter
            that they could be together in their next birth, which made him mad,
            sad and further insulted. An emotional wreck, he headed to the
            coffee machine in the students’ hall at Glenfield, poured out a
            burning hot cup of coffee and tried committing ‘coffee-side’! His
            biggest issue was that Lee had won. Such were the rules of the
            Kandyan dating game.
          </p> */}
          <p>
            Nahil was the only foreigner in his class and the lecturer inquired
            if he could call him John, to which he replied, ‘‘Sorry Sir, you
            call me Nahil or nothing at all,’’ after which the lecturer
            questioned him on his ability to speak English, saying, ‘‘Your
            English is not bad for a foreigner. Where did you study it?’’ Nahil
            replied, ‘‘on the Colombo/London flight, Sir!’’ With this remark,
            Nahil fit into the class very well.
          </p>
          <p>
            While in college Nahil would drive down to London every weekend and
            spend time with his friends Lakshman Umagiliya and Sunil Perera who
            shared a fantastic apartment opposite the Royal Lancaster Hotel –
            14, Westbourne Street. Number 15 next to it was the Marsh House
            Hotel. The apartment belonged to Dr. Umagiliya, Lakshman’s father,
            who had left for Libya on receiving a lucrative offer he couldn’t
            turn down, handing over the flat to Lakshman. The flat turned out to
            be ‘party central,’ a bachelor hangout and party place every
            weekend. To encourage more ladies to join the party they put up a
            poster on the notice board at the nurse’s quarters of St. Mary’s
            Paddington, which was round the corner of Westbourne Street,
            inviting the nurses to join the fun. To their amazement, 20 or more
            nurses made their way to the parties on weekends, with two of the
            nurses eventually marrying two of his friends!
          </p>

          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/06-leicester-college-england/leicester-college-amma-kanthi.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Leicester College, England – Amma and Kanthi</small>
              </p>
            </div>
          </div>
          <p>
            It was during this period that he invited his mother and younger
            sister on holiday to the UK, intending to take them around Europe.
            They flew into Frankfurt where a family friend’s son, Lakshman
            Jayasekera, was working at the airport. With his help, Nahil bought
            a VW, a nice old Beetle which was the equivalent of 80 GBP in DMs.
            They travelled from Frankfurt to Vienna, Florence, Rome, Venice and
            past Lake Como through Switzerland to Paris. Lake Como in Nothern
            Italy in the Lombardy region is an upscale resort area known for its
            dramatic scenery, set against the foothills of the Alps. The lake is
            shaped like an upside down ‘Y’ with three slender tributaries that
            meet at the resort town of Bellagio. At the bottom of the south west
            branch lies the city of Como, home to Renaissance architecture and a
            funicular (a vehicle which uses cable traction for movement on
            steeply-inclined slopes) that travels up to the mountain town of
            Brunate. It is said that one should visit Lake Como at least once in
            their lifetime. Once they got to Paris they were joined by Bridget,
            Lakshman’s wife, and enjoyed excellent food in Paris since Bridget’s
            father was a chef at the Paris Hilton. While in Paris they drank no
            water, only wine.
          </p>

          <p>
            He remembers they had coffee at a sidewalk cafe on Des Champs
            elysees, surrounded by shops where the cost of a Jacket was more
            than the cost of his VW in which they had travelled a few thousand
            miles. En-route he realised that since it was summertime his mom and
            Kanthi had not seen any snow, therefore he took them to Austria that
            had a cable car going up into the mountains. Once they got up there
            they had a blast walking in the snow and messing around. Nahil
            recalls it was a great holiday, with lots of laughs, photographs and
            fun memories. In anticipation of his mother and sister’s visit, he
            had rented out an apartment in Earl’s Court for the duration of
            their stay in the UK. After their return from Europe, they spent a
            few more days sight seeing in and around London, before returning to
            Sri Lanka. Unfortunately, Mahes had just started at Medical College
            and couldn’t join them.
          </p>

          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/06-leicester-college-england/thaththa-trafalgar.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Thaththa Trafalgar Square</small>
              </p>
            </div>
          </div>
          <p>
            Incidentally, he did not pay duty for his VW Beetle since it had
            German export documents and number plates. He used the car for one
            year, paid the taxes of GBP 12 and sold it to his classmate, Ken
            Garner for GBP 80/-. Come the following summer, his father called
            him brimming with excitement, insisting he wanted to do the same
            tour around Europe his wife and daughter had done, the previous
            year. Father and son commenced their tour once again in Germany,
            this time buying a more expensive VW Variant hatchback, driving the
            same route as on the previous trip.
          </p>
          <p>
            While in college Nahil worked at the Ceylon Students Centre and
            later at Wimpy, a burger fast-food chain, where he worked in the
            very hot and steamy basement loading the dishwasher and dryer. There
            was a Sri Lankan female working there, with whom he kept all
            conversation restricted to Sinhalese and commended her for her
            ability to speak English, to which she replied, that she must have
            done some ‘merit’ in a previous life for this ability. Later, during
            a visit to the student centre, she had heard him speaking to his
            friends in English. Aghast, with her hands on her hips, she had
            virtually screamed at him saying, ‘‘You are the type my mother
            warned me about...’’
          </p>
          <p>
            He drove his VW Variant in the UK with the export plates intact,
            which was duty free. He says he never paid parking fines. Being lazy
            he used the windscreen wipers to flick the tickets off the screen.
            Finally, the parking wardens, by now wise to his little schemes,
            cello-taped the tickets to the side of his car, which he continued
            to ignore. His number was not traceable and to prosecute him they
            would have to trace his number in Germany, something that never
            happens. Loopholes like this were really abused by him. There used
            to be a parking meter close to Selfridges where he worked for a
            short time. One morning as he parked his car at this meter, on a
            hunch, he experimented by inserting an engineered paper clip into
            the parking meter and it worked out brilliantly. The system jammed,
            awarding him a full day of free parking each working day, throughout
            his tenure at Selfridges.
          </p>

          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/06-leicester-college-england/jeeves-nahil.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Ratnasiri de Silva (Jeeves) and Nahil</small>
              </p>
            </div>
          </div>
          <p>
            Ten years later Mithra Rodrigo and Nahil were passing by a similar
            meter and as a dare, he tried the trick again: Voila! It worked. The
            second VW Nahil bought in Germany was a left-hand drive car. After
            two years, just as he was about to leave the UK, he knew he would
            get much more value from the car if he converted it to a right-hand
            drive, which he did, outside on the street at 168 Holland park where
            they lived. This was the modus operandi. He had Sri Lankan friends
            working at the Swiss Cottage VW dealership. He got a list of parts
            from them and wherever there was a left-hand drive option, there was
            an asterisk that indicated the options of the parts and the date of
            manufacture. He got a list of about 18 items from pedals, to the
            gasoline tank with a different shape. Taking the list to a
            scrapyard, where many cars of this model had been scrapped, he
            picked up all the bits and pieces for a pittance.
          </p>
          <p>
            The only thing he purchased from the dealer was the dashboard which
            had the meters. He cut it just under the windscreen lip and did not
            weld it but pop-riveted it, placed the rubber beading across it and
            spray painted it to get the same colour. New parts are normally
            grey, therefore getting the correct colour was important. He bought
            a can of aerosol paint, sprayed the new parts, changed the meters by
            putting miles instead of kilometers, and did a test drive on the
            motorway because the safety aspect was very important. He drove full
            speed and tested the brakes and found it to be very steady and ready
            for sale.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/06-leicester-college-england/holland-park.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Holland Park</small>
              </p>
            </div>
          </div>

          <p>
            He advertized the car for sale, getting a positive response from an
            English guy and a Sri Lankan, who turned up simultaneously to check
            the vehicle out. Nahil was not too keen to sell it to the Sri Lankan
            – a minor fault and he would be at Nahil’s doorstep whining. The two
            buyers started arguing over who should get the car, till he cut them
            short advising both that the first to deposit the amount quoted into
            his account could have the car. The Sri Lankan offered him cash and
            the Englishman said, “Here’s my cheque. My cheque is as good as his
            cash.” Exasperated, Nahil tossed a coin, which fortunately the
            British guy won! It was a Friday and the guy wanted the car for the
            weekend, but Nahil was willing to hand over the car only when the
            cheque was cashed and the transaction finalised.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/06-leicester-college-england/nahil-with-friends.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Nahil and Friends</small>
              </p>
            </div>
          </div>
          <p>
            His friend Shantha de Silva, who was with him, took him to the
            Lloyds Bank on Lower Regents Street along with the Englishman who
            withdrew the amount in cash and paid him. They signed the transfer
            on the bonnet of the car. “As we were about to leave, he tells me
            the left-side view mirror of the car was missing. I had forgotten to
            fix it on the left,” says Nahil. He told him that’s the way it was
            when he got the car, followed by a quick “Sorry, I’ve got to go,”
            and took off, with Shantha driving him home. Even as a young student
            the affirmation of his entrepreneurship and money-making skills was
            evident. He recounts that this was the time when a large population
            of Indians arrived in the UK from East Africa due to Idi Amin’s
            idiosyncrasies. Most of the Indian students in his college had their
            homes in London. With all his friends based in London, he would
            drive the 100 miles to London and back every weekend. In response to
            an advertisement he had placed in the college bulletin, among the
            many Indian students living on campus, there were three who joined
            him to London each weekend, paying him one pound per person, per
            trip.
          </p>
          <p>
            A gallon of petrol cost 33 pence and this sweet deal, thanks to the
            Indians, guaranteed him a freebie both ways every weekend. A few
            weekends into this enterprise, one of them, a smart ass, lamented
            that one pound per person was far too much. Nahil then challenged
            the Indian to buy a gallon of petrol, place the can on the highway,
            sit on it and wish hard that it would magically take him to London.
            That shut the guy up and he continued his weekend trips to London
            with Nahil.
          </p>
        </Col>
      </Row>
      <ChapterFooter title={"Leicester College England"} />
    </div>
  );
};
