import { Drawer, FloatButton, Layout, Menu } from "antd";

import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useState } from "react";
import { TheBeginning } from "../components/chapters/01-TheBeginning";
import { AgnesDeSilvaMalalgodaAmma } from "../components/chapters/02-AgnesDeSilvaMalalgodaAmma";
import { Preface } from "../components/chapters/00-Preface";
import { GratianWijesuriyaThaththa } from "../components/chapters/03-GratianWijesuriyaThaththa";
import { chapterData } from "../components/chapters/000-chapterData";
import { JourneyToTrinity } from "../components/chapters/04-JourneyToTrinity";
import { PursuingAnEngineeringDegreeInTheUK } from "../components/chapters/05-PursuingAnEngineeringDegreeInTheUK";
import { LeicesterCollegeEngland } from "../components/chapters/06-LeicesterCollegeEngland";
import { TuulaRippati } from "../components/chapters/07-TuulaRippati";
import { AmmasSuddenDemise } from "../components/chapters/08-AmmasSuddenDemise";
import { Th5EngineerPOLines } from "../components/chapters/09-5thEngineerPOLines";
import { Nd2EngineeronLankaKalyani } from "../components/chapters/10-2ndEngineeronLankaKalyani";
import { FamilyDrama } from "../components/chapters/12-FamilyDrama";
import { StartingupofEastWestEnterprises } from "../components/chapters/13-StartingupofEastWestEnterprises";
import { AGreatBusinessPartnership } from "../components/chapters/14-AGreatBusinessPartnership";
import { DonationstoTrinityCollegeKandy } from "../components/chapters/15-DonationstoTrinityCollegeKandy";
import { GiftingtheClubHousetotheTCKOBA } from "../components/chapters/16-GiftingtheClubHousetotheTCKOBA";
import { Bunker1The37000TonneDeadWeightTanker } from "../components/chapters/17-Bunker1The37000TonneDeadWeightTanker";
import { SalvagingoftheRavidas } from "../components/chapters/18-SalvagingoftheRavidas";
import { SinglePointMooringBuoyColomboPort } from "../components/chapters/19-SinglePointMooringBuoyColomboPort";
import { AnaMalalgoda } from "../components/chapters/20-AnaMalalgoda";
import { EastWestHaulage } from "../components/chapters/21-EastWestHaulage";
import { MeetingIndrani } from "../components/chapters/11-MeetingIndrani";
import { DanMukundan } from "../components/chapters/22-DanMukundan";
import { NahilAndAvril } from "../components/chapters/23-NahilAndAvril";
import { MeetingTuulaInSweden } from "../components/chapters/24-MeetingTuulaInSweden";
import { TheIBMBuilding } from "../components/chapters/25-TheIBMBuilding";
import { ParakramaJayasekeraAndFamily } from "../components/chapters/26-ParakramaJayasekeraAndFamily";
import { IBMSriLanka } from "../components/chapters/27-IBMSriLanka";
import { TheNavamMawathaBusinessComplex } from "../components/chapters/28-TheNavamMawathaBusinessComplex";
import { MohanShayam } from "../components/chapters/29-MohanShayam";
import { EastWestProperties } from "../components/chapters/30-EastWestProperties";
import { BlomendhalRoad } from "../components/chapters/31-168BlomendhalRoad";
import { JothyGodage } from "../components/chapters/32-JothyGodage";
import { SriLankaShippingRobertSenanayakeBuilding } from "../components/chapters/33-SriLankaShippingRobertSenanayakeBuilding";
import { LankaMarineServicesNahilTakesOnJohnKeells } from "../components/chapters/34-LankaMarineServicesNahilTakesOnJohnKeells";
import { TheIBMAgency } from "../components/chapters/35-TheIBMAgency";
import { SouthAfricanMarine } from "../components/chapters/36-SouthAfricanMarine";
import { TheKirulaRoadYard } from "../components/chapters/37-TheKirulaRoad";
import { FamilyHolidayToEgypt } from "../components/chapters/38-FamilyHolidayToEgypt";
import { FriendsIndraniAndAnarkali } from "../components/chapters/39-FriendsIndraniAndAnarkali";
import { ETVExtraTerrestrialVision } from "../components/chapters/40-ETVExtraTerrestrialVision";
import { MrLakshmanKadirgamar } from "../components/chapters/41-MrLakshmanKadirgamar";
import { InMemorium } from "../components/chapters/42-InMemorium";
import { NahilVsJohnKeells } from "../components/chapters/43-NahilVsJohnKeells";
import { ETVSold } from "../components/chapters/44-ETVSold";
import { LesterAndNahilEndTheirPartnership } from "../components/chapters/45-LesterAndNahil";
import { NahilMovesToTheUK } from "../components/chapters/46-NahilMovesToTheUK";
import { CrescatResidenciesJohnKeellesHoldings } from "../components/chapters/47-CrescatResidencies";
import { No44FlowerRoad } from "../components/chapters/48-No44FlowerRoad";
import { Roshanne1966To2002 } from "../components/chapters/49-Roshanne1966to2002";
import { KavanAndAnneRambukwella } from "../components/chapters/50-KavanAndAnneRambukwella";
import { DrSLGunasekera } from "../components/chapters/51-DrSLGunasekera";
import { Donating15MRupees } from "../components/chapters/52-Donating15MRupees";
import { Anecdotes } from "../components/chapters/53-Anecdotes";
import { CleaningtheBeiraLake } from "../components/chapters/54-CleaningtheBeiraLake";
import { ArcadiaandCompassHeights } from "../components/chapters/55-ArcadiaandCompass";
import { AcquiringAndSaleOfHotelIntercontinentalColombo } from "../components/chapters/57-AcquiringAndSaleOfHotelIntercontinentalColombo";
import { DonatingABuildingtotheMarineFraternity } from "../components/chapters/58-DonatingABuildingtotheMarineFraternity";
import { TheAgnesWijesuriyaCharity } from "../components/chapters/59-TheAgnesWijesuriya";
import { ChairmanCWE } from "../components/chapters/60-ChairmanCWE";
import { ThisAndThat } from "../components/chapters/61-ThisAndThat";
import { TheForbesAndWalkerBuilding } from "../components/chapters/62-TheForbesAndWalkerBuilding";
import { AirlankaCargo } from "../components/chapters/63-AirlankaCargo";
import { PatentForMethodOfManufactureFixedDishAnetennas } from "../components/chapters/64-PatentForMethodOfManufactureFixedDishAnetennas";
import { MarriottResortAndSpa } from "../components/chapters/65-MarriottResortAndSpa";
import { HisTypicalDay } from "../components/chapters/66-HisTypicalDay";
import { AuthorsWords } from "../components/chapters/67-AuthorsWords";
import { Reflections } from "../components/chapters/56-Reflections";
import { LadyRidgewayHospital } from "../components/chapters/68-LadyRidgewayHospital";

export const TheBook = () => {
  const { Content, Sider } = Layout;

  const [showFloat, setShowFloat] = useState(false);
  const [open, setOpen] = useState(false);

  // const showDrawer = () => {
  //   setOpen(true);
  //   console.log("clicked");
  // };
  const onClose = () => {
    setOpen(false);
  };

  const listChapters = chapterData.map((data) => {
    return {
      key: data.key,
      icon: "",
      label: (
        <a
          style={{
            textDecoration: "none",
            color: "grey",
            height: "20px",
          }}
          href={`#${data.href}`}
        >
          {data.title}
        </a>
      ),
    };
  });
  const listChaptersResponsive = chapterData.map((data) => {
    return (
      <li onClick={onClose} style={{ listStyle: "none" }}>
        <a
          style={{
            textDecoration: "none",
            color: "grey",
            height: "20px",
          }}
          href={`#${data.href}`}
        >
          {data.key} - {data.title}
        </a>
      </li>
    );
  });

  const FloatIcon = () => (
    <FloatButton.Group
      icon={<MenuUnfoldOutlined />}
      type="primary"
      trigger="click"
      onClick={setOpen(true)}
    >
      <Drawer
        title="Chapters"
        placement="right"
        zIndex={99999}
        height={"250px"}
        onClose={onClose}
        open={open}
      >
        <ul style={{ padding: "5px" }}>{listChaptersResponsive}</ul>
      </Drawer>
    </FloatButton.Group>
  );

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <div
        style={{
          maxWidth: "1400px",
          width: "100%",
        }}
      >
        <Layout>
          <Sider
            width={250}
            style={{
              backgroundColor: "white",
              minHeight: "500px",
            }}
            breakpoint="md"
            collapsedWidth="0"
            trigger={null}
            zeroWidthTriggerStyle={<FloatButton />}
            onCollapse={() => {
              setShowFloat(!showFloat);
            }}
          >
            <Menu
              theme="white"
              mode="inline"
              items={listChapters}
              style={{
                wordWrap: "break-word",
                height: "90vh",
                overflow: "scroll",
              }}
              defaultSelectedKeys={["1"]}
            />
          </Sider>
          <Layout>
            <Content
              style={{ height: "90vh", overflow: "scroll", width: "100%" }}
              hasSider
            >
              <Preface />
              <TheBeginning />
              <AgnesDeSilvaMalalgodaAmma />
              <GratianWijesuriyaThaththa />
              <JourneyToTrinity />
              <PursuingAnEngineeringDegreeInTheUK />
              <LeicesterCollegeEngland />
              <TuulaRippati />
              <AmmasSuddenDemise />
              <Th5EngineerPOLines />
              <Nd2EngineeronLankaKalyani />
              <MeetingIndrani />
              <FamilyDrama />
              <StartingupofEastWestEnterprises />
              <AGreatBusinessPartnership />
              <DonationstoTrinityCollegeKandy />
              <GiftingtheClubHousetotheTCKOBA />
              <Bunker1The37000TonneDeadWeightTanker />
              <SalvagingoftheRavidas />
              <SinglePointMooringBuoyColomboPort />
              <AnaMalalgoda />
              <EastWestHaulage />
              <DanMukundan />
              <NahilAndAvril />
              <MeetingTuulaInSweden />
              <TheIBMBuilding />
              <ParakramaJayasekeraAndFamily />
              <IBMSriLanka />
              <TheNavamMawathaBusinessComplex />
              <MohanShayam />
              <EastWestProperties />
              <BlomendhalRoad />
              <JothyGodage />
              <SriLankaShippingRobertSenanayakeBuilding />
              <LankaMarineServicesNahilTakesOnJohnKeells />
              <TheIBMAgency />
              <SouthAfricanMarine />
              <TheKirulaRoadYard />
              <FamilyHolidayToEgypt />
              <FriendsIndraniAndAnarkali />
              <ETVExtraTerrestrialVision />
              <MrLakshmanKadirgamar />
              <InMemorium />
              <NahilVsJohnKeells />
              <ETVSold />
              <LesterAndNahilEndTheirPartnership />
              <NahilMovesToTheUK />
              <CrescatResidenciesJohnKeellesHoldings />
              <No44FlowerRoad />
              <Roshanne1966To2002 />
              <KavanAndAnneRambukwella />
              <DrSLGunasekera />
              <Donating15MRupees />
              <Anecdotes />
              <CleaningtheBeiraLake />
              <ArcadiaandCompassHeights />
              <Reflections />
              <AcquiringAndSaleOfHotelIntercontinentalColombo />
              <DonatingABuildingtotheMarineFraternity />
              <TheAgnesWijesuriyaCharity />
              <ChairmanCWE />
              <ThisAndThat />
              <TheForbesAndWalkerBuilding />
              <AirlankaCargo />
              <PatentForMethodOfManufactureFixedDishAnetennas />
              <MarriottResortAndSpa />
              <HisTypicalDay />
              <LadyRidgewayHospital />
              <AuthorsWords />
              {showFloat && <FloatIcon />}
            </Content>
          </Layout>
        </Layout>
      </div>
    </div>
  );
};
