import { Row, Col, Image } from "antd";

export const No44FlowerRoad = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="no44-flower-road">No.44, Flower Road</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            He bought No.44 Flower Road, formerly known as Nelun Court, from the
            owner of &lsquo;Glass House,&rsquo; a Trinitian, Dr. Willy
            Ratnavale. Willy had built this house for his two daughters, who
            were friends of Nahil&rsquo;s sister Mahes, while studying at Ladies
            College in her senior year. During this period Nahil recalls
            visiting this house and what he remembers is a large structure built
            in two sections with two rooms as dispensaries for the girls who
            were aspiring to be doctors. However, within a few years, the entire
            family ended up moving to the USA, subsequently deciding to live
            there permanently.
          </p>
          <p>
            Due to this move, Dr. Ratnavale converted the rooms in the house
            into 14 bedrooms with en-suite bathrooms, which were rented out to
            lodgers. After a short period, since none of the Ratnavale family
            intended to return to Colombo, the house was listed for sale. On the
            market for some time, though prime property, Dr. Ratnavale had made
            such a mess of the house, he was struggling to sell it, until Nahil
            intervened, and decided to buy it. He knew the house had good bones
            with the potential of being restored to something of significance
            since he had known what it was like before it was converted to Nelun
            Court. Nahil was confident that the house would look good after
            completion. Dr. Ratnavale shared a similar sentiment confident of
            Nahil&rsquo;s capabilities that the house will look so good after
            the flip, and the Ratnavale sisters would be proud of their old
            home.
          </p>
          <p>
            Once the sale was concluded, starting on the remodeling, he was
            slightly overwhelmed, finding the restoration of this house posing
            an interesting challenge to him.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/48-no44-flower-road/arches-replicated.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Arches replicated at No. 44 Flower Road</small>
              </p>
            </div>
          </div>
          <p>
            Around the same time Ana Malalgoda, Nawaz Rajabdeen, M.L.M Amin and
            Nahil were on holiday in Chiang Mai, Thailand. He noticed the hotel
            they were staying in had beautiful arches. Convinced the design
            would look great for #44, he took photographs of the arches,
            sketched the designs on paper and replicated the design throughout
            the side and front of the house, working alongside the basses. The
            arches had a design like a &lsquo;habarala&rsquo; leaf, with a light
            at the back that looked grand when lit up. While the house was being
            refurbished, Roshanne, Nahil and the kids moved to Guildford
            Crescent. It was during this time that May (Roshanne&rsquo;s
            mother)went on a joy ride halfway round the world with Roshanne, his
            kids and his credit card.
          </p>
          <p>
            While living at Guilford Crescent, one weekday afternoon when he got
            home for lunch he realized his two younger children, Vijitha and
            Anika, who were usually home from school before him, were still not
            home.Inquiring of the domestic where they were, she says
            &ldquo;Lamai nang avilla na thama, mama monawath danne na, eth rata
            yana monawahari kiwwa&rdquo; &ndash; (&ldquo;The kids are not back
            yet, I don&rsquo;t know anything, but there was talk about going
            abroad.&rdquo;) Then he realised, according to the information he
            garnered from his credit card, air tickets were purchased for May
            &ndash; Roshanne&rsquo;s mother &ndash;Roshanne, Vijitha and Anika,
            to Ravenna Italy,and they were on their way to the airport.
            Pondering and looking for a reason as to why Roshanne never told him
            she was taking the kids along with her to Italy, trying to narrow
            down the last two weeks, to remember wheather there were any
            incidents of any magnitude to spur her into taking such a drastic
            measure, he recalled there was nothing exceptional, except the usual
            arguments of no consequence, incidents present in any relationship.
            On the way to the airport he remembered Sonia&rsquo;s father was a
            senior civil servant. Getting through to him, Nahil requested him to
            inform Immigration to stop Roshanne and the kids from proceeding
            further. Unfortunately, it was too late, they&rsquo;d gotten past
            Immigration, leaving him with no possibility to stop them.
          </p>
          <p>
            Investigating how they got a hold of his credit card which was
            liberally used for all their transactions, air tickets, etc., he
            found out that a few days prior, Roshanne and May had met his
            secretary managing to persuade her and extract his credit card and
            passport from her, fabricating a story about getting him a surprise
            birthday gift from the duty free at the airport. Vijitha and
            Annika&rsquo;s details were on his passport which they got
            transferred on to Roshanne&rsquo;s passport.
          </p>
          <p>
            For Nahil, this was a serious breach of his rights as a parent,
            which forced him to raise an issue with the Department of
            Immigration for not bothering to seek his permission by consulting
            him before the children&rsquo;s names were transferred. This
            incident led to a fresh legislation at the Department of
            Immigration. It was made mandatory that a single parent taking their
            child/children aged under-sixteen overseas should furnish a letter
            of consent from the other parent, for changes from one passport to
            another. Due to his predicament the regulations changed, which is in
            effect to date.
          </p>
          <p>
            Once Roshanne got to Italy, he tried to track them down through
            May&rsquo;s sisters who live there. Unfortunately, by the time he
            got through to one of her sisters, he was informed that they had
            left to the UK for a few days, subsequently travelling to Australia.
            He refrained from stopping transactions on his credit card to avoid
            depriving the two children, in case they needed anything.
          </p>
          <p>
            On learning they were in Australia, he requested Maya, his
            ex-girlfriend whose father was the Sri Lankan High Commissioner to
            Australia, to make contact with Roshanne. Finally, Nahil managed to
            get a number in Australia and telephoned Roshanne. May answered the
            phone and pitched into him that he had not treated Roshanne properly
            and her future was not financially secure. She made a deal with him
            that she would bring the kids back on the promise that Roshanne
            would be paid a sum of Rs. 3 million. Meanwhile, May had got her
            lawyer in Sri Lanka to draw up an agreement to the effect that on
            their return to Colombo, Rs. 3 million would be transferred to
            Roshanne by Nahil, at the Colombo Airport Arrival Lounge.
          </p>
          <p>
            As instructed by May, he arranged the cash, taking it along to the
            airport, with Vipula Soyza. Meeting her on arrival, as agreed he
            gaveMay the money. While driving back together from the airport to
            Colombo, Roshanne returned the cash to Nahil, telling him that this
            was May&rsquo;s idea. That is when he told May &lsquo;how dare you
            think the children&rsquo;s value could be measured by a few million
            rupees.&rsquo; Nahil believes all this was orchestrated by May. He
            also mentioned going back in time, that he had always had a rocky
            relationship with May. Eventually, Roshanne and Nahil worked things
            out amicably, keeping in mind the welfare of their two children who
            were their highest priority.
          </p>
        </Col>
      </Row>
    </div>
  );
};
