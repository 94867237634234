import { Row, Col, Image } from "antd";

export const MarriottResortAndSpa = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="marriott-resort-and-spa-weligama">
          Marriott Resort and Spa Weligama
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            After the Intercontinental Hotel was sold, his two younger children
            Vijitha and Anika, who along with him were previously fully involved
            in the day-to-day operations of the Intercontinental, decided to
            continue in the hotel business. Nahil thought this was a great way
            to keep them occupied, even if they kept themselves busy by just
            hanging around the place. With the prevailing minimum rates in the
            Colombo hotels, which they absolutely disagreed with, and not too
            keen on acquiring anything in Colombo, their sights were set on
            acquiring a place out of Colombo, since the minimum rates did not
            apply to non-city hotels.
          </p>
          <p>
            Vijitha took it upon himself to source a suitable site, surveying
            almost 60 properties along the east and west coast of the island.
            Nahil requested Vijitha to bear in mind the property he was looking
            for should have main road frontage and beach frontage, void of a
            rail crossing approaching the site. He also stressed that the
            advantage ofmain road frontageis the free publicity considering the
            number of vehicles that ply the route daily, and all that is needed
            is excellent signage.
          </p>
          <p>
            In his research, he noticed that most of the bigger hotels along the
            coast had a rail crossing over the road or no proper beach frontage,
            one example being the Blue Water Hotel, which has beach frontage but
            one has to cross a railway line to get there, thanks to the British,
            who built the railroad adjacent to the beach.
          </p>
          <p>
            The site in Weligama was unique in the sense that the rail line was
            set on the east of the main road while the site was on the right
            side of the road, overlooking the beautiful Weligama Bay, right on
            the beach, a surfers&rsquo; paradise.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/65-marriot-resort-and-spa-weligama/anre-sorensen-nahil-anika-vijitha.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Anika, Nahil Arne, Vijitha</small>
              </p>
            </div>
          </div>
          <p>
            Site secured, they browsed the worldwide hotel networks to look for
            the largest chain of hotels; the potential manager of the property,
            a chain whose priority was good marketing. They selected the Marriot
            chain which is the largest hotel chain in the world - even bigger
            than the Hilton Chain- with over 7000 properties which include the
            Ritz Carlton, Westin and Sheraton hotels. It was plain to see that
            the Marriott chain was streets ahead in marketing and industry
            standards, especially after the purchase of the Sheraton chain. The
            Marriot has more than 5,700 hotels in 110 countries across the globe
            with their international headquarters located in Bethesda, Maryland,
            founded by J.Willard Marriot and his wife. It is presently headed by
            Bill Marriot the Executive Chairman andArne Sorenson is the
            President and CEO.
          </p>
          <p>
            Vijitha and Anika were hands-on dealing with the hotel project from
            day one &ndash; negotiating the management agreements with Marriott
            and following the 600 pages of Marriott design guidelines. Anika was
            also in charge of procurement and the interior decor for the hotel
            with a team based in their Colombo office at the WTC. Nahil says he
            can&rsquo;t think of anyone in Sri Lanka who has had this kind of
            experience in constructing a hotel to international brand standards
            and maintains all this was an educational trip for both kids. For
            him, it was mostly the sort of exposure they got for general
            business, something that both have picked up, considering they were
            just raw off university. Even though this work experience was
            different from their pursued degrees, he believes the &lsquo;on-
            the-job&rsquo; training acquired will stay with them always. As a
            team, they got the ball rolling by speaking to Marriott India, all
            the legal stuff from Delhi and the operational procedure from
            Bombay. Anika attended to the management agreement, researching on
            the net for all the various agreements and the variations available.
            One request put forward by Marriott and agreed upon was a minimum of
            200 rooms. According to Marriot, anything less than 200 rooms was
            not viable or worth it when it came to sending a Marriot-trained
            General Manager for the hotel. Marriott loved the view from the
            site, but raised the question of how East West Properties proposed
            to sell the rooms on the other side of the hotel overlooking the
            road since everyone would request a sea view.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/65-marriot-resort-and-spa-weligama/anre-sorensen-marriott-weligama.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Touring our Site</small>
              </p>
            </div>
          </div>
          <p>
            Nahil decided to make full use of the view, building a single loaded
            slab where all the rooms faced the ocean with a single corridor
            overlooking the road, serving the rooms. Originally the decision was
            five floors, which had to be doubled to ten floors to accommodate
            the 200 sea-facing rooms. It consisted of ten floors above ground
            with guest rooms, a service floor and mezzanine floor &ndash;
            altogether 13 floors. After the hotel was handed over to Marriott,
            Nahil as the owner, had the choice of appointing the General Manager
            and the Financial Director which can be refuted three times. In the
            event of a fourth time, he would have to agree to the Marriott
            choice. The present GM is an Indian, Elton Hurtis. All nine heads of
            the various departments are also Indians in charge of training and
            local recruitment.
          </p>
          <p>
            A month into the hotel&rsquo;s operation, while Nahil was testing
            all four restaurants, he invited Margu Murugeswaran to lunch with
            him. Before the meal, he ordered some beers. Upon receiving the
            bill, it read bill #xxx, owner&rsquo;s discount 50%. Then he knew
            Marriott had taken over. He was a guest now!
          </p>
          <p>
            Completing his three score and ten with few more years added on, he
            decided to hang up his boots and start taking things easy, thus when
            an offer for the hotel came in from HPL Singapore, owned by
            billionaire Singapore businessman, Mr.Ong Beng Seng. He had met him
            at the signing of the Singapore/Sri Lanka free trade agreement on
            the 23rd of January 2018, when Mr. Seng accompanied the Prime
            Minister of Singapore to the event. Mr.Ong Beng Seng who as a young
            man, started his career as an insurance underwriter is today the
            owner of HPL Hotel Properties Limited in Singapore. They are also
            the owning company of the Singapore Hilton and a multitude of luxe
            hotels across the globe, including the multi-franchise Komoco Group.
            In 2007, Mr. Ong clinched the deal to bring the F1 race to Singapore
            (Singapore Night Races) after a year of negotiations, due in large
            part to his friendship with F1 boss Berni Ecclestone. The F1 race
            and the accompanying glamour events saw Mr. Ong, top &lsquo;The
            Straits Times&rsquo; lifestyle power list.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "300px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/65-marriot-resort-and-spa-weligama/marriott-02.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>View from the road</small>
              </p>
            </div>
          </div>
          <p>
            Nahil figured it was the right time to sell. The negotiations went
            on for a few months with Mr. Stephen Lau, Chairman of HPL and
            finally, he sold the hotel to HPL in March of 2019 for US$57M. The
            final payment due to him was scheduled for the second week of April
            and due to the Sinhala and Tamil New Year holiday the payment was
            delayed, and finally paid to him on the 19th of April, and the bomb
            went off on Easter Sunday the 21st of April.
          </p>
          <p>
            While he has enjoyed all his years of of hard work he is now taking
            life easy and is bored with nothing to do. He has made sure his
            three children, Vajira, Vijitha and Anika have a financially secure
            future by gifting them shares he owned in East West Properties. He
            fondly refers to them as his &lsquo;very gifted family.&rsquo;
          </p>
          <p>
            While discussing what he hopes to do in the days to come with so
            much time on his hands, He has finally decided to pursue that
            &lsquo;pink gin&rsquo; that has been eluding him for so many years
            by escaping to some remote island in the pacific, sit under a
            swaying palm and sip on that pink gin with his current Phillipina
            babe but he says, after covid-19 is completely erradicated
            throughout the world. This could be a never ever.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "300px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/65-marriot-resort-and-spa-weligama/marriott-03.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Touring our Site</small>
              </p>
            </div>
          </div>
          <p>
            Lalith Coonghe, living in France, a seafarer friend of
            Nahil&rsquo;s, married to a French lady, was sent to Sri Lanka to
            set up the Walls Ice Cream factory under the Unilever brand. Though
            Nahil was not too keen, Lalith tried his best to cajole Nahil to buy
            the plant, with a promise that he was more than willing to stay in
            Sri Lanka and operate the factory for him. Nahil, realising the
            plant would be ideal for Cargills, kept bombarding his friend
            Anthony Page (Baba), Chairman of Cargills to buy it. Baba kept on
            resisting, haggling about the price, etc., until Unilever bought the
            price down very low due to an epic fail in its marketing strategy,
            especially the cycle vendors. The price quoted was a steal. Baba
            finally relented and bought the plant and &lsquo;Cargills
            Magic&rsquo; Ice Cream was launched.
          </p>
        </Col>
      </Row>
    </div>
  );
};
