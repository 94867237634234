import { Col, Image, Row } from "antd";
import { ChapterFooter } from "../chapterFooter/chapterFooter";

export const JourneyToTrinity = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="journey-to-trinity-respice-finem">
          Journey To Trinity – The Best School of All - ‘Respice Finem’
        </h1>
      </div>
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "500px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/04-journey-to-trinity-respice-finem/trinity-college.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Trinity College</small>
              </p>
            </div>
          </div>
          <p>
            Nahil started his schooling at Girl’s High School Kandy from nursery
            up to grade two. Incidentally, it was a necessity due to the lack of
            space at Trinity College, the school in which his mother insisted he
            should receive his education. Later, as a seven-year-old, he
            transferred to Kingswood College for a year and thereafter to the
            ‘best school of all,
          </p>

          <p>
            From what I know, he is a respected and loyal alumnus of Trinity
            College who has helped his Alma Mater for over 50 years. “I am a
            very proud Trinitian and a grateful old boy. I always felt that if I
            owed anyone anything, it was to my parents and the school,” says
            Nahil Recalling his many exciting escapades as a young adult, he
            vividly remembers his antics with the family car. He was around 16
            years, living at Piachaud Gardens in Kandy, the anchor house built
            by his dad, followed by many more houses built by him. Subsequently,
            this house was bought by Jeevaka Attapathu’s family, who were all
            Trinitians.
          </p>
          <p>
            His home was on a bend atop a hill and the family car which was an
            Opal Rekord, 3 Sri 2704, would normally be driven up this incline on
            second gear. But he was determined to challenge this norm, holding a
            bet with a buddy, given his ‘expertise,’ that this hill could be
            conquered on third gear if good speed could be mustered before the
            incline. The bet was placed. There he was, he says, virtually flying
            up Piachaud Gardens in his element on third. He missed negotiating
            the bend – obviously at that speed – and ended up hitting the side
            of the car on the gate of his house. Smashed car aside, he remembers
            reasoning with himself that if he hadn’t done this, he would have
            never known! The damaged car was just one problem, the major issue
            being how he would break the news to his dad. His expert driving had
            blindsided him! After much contemplation, he sheepishly confessed
            his folly to his dad. To his utter amazement, his dad was so amused
            that shortly after he bought him a Raleigh Humber sports push bike.
          </p>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <p>
            Even as a young adult Nahil had abounding energy and a fantastic
            sense of humour that he exudes even today. Perceived as a rascal by
            family and friends, he was a serial bully when it came to his
            sisters. He bullied his sisters so much that much later on – while
            living in Kandy – he was boarded at the college hostel – Collins
            House – even though the family lived at Wataranthenna in
            Katugastota, an easy 10-minute drive to school. During the three
            months, he spent in the hostel, the hostel food was so bad that his
            mother came by each day with food. “I’d sit in the car, eat, and go
            back to the hostel.” The punishment for being difficult and
            especially for bullying his sisters was for one term after which he
            was back in his home. He says, “The girls were very good. Whenever
            we had disagreements, I may have hit them or been mean, they’d cry
            and stop. However, when they heard the car turning into the
            driveway, it turned out to be a great drama, two feisty females
            pitted against lonesome me. They’d cry again as my parents walked
            into the house. My younger sister, gosh she was a great crier!”.
          </p>
          <p>
            Managing to keep his grades above average was something he did not
            take lightly. He was always second in class, thanks to Vajira
            Wimalasena, son of the Member of Parliament at the time, for Kandy
            and later on our High Commissioner in London – the boy whom he could
            never beat to first place. Vajira was in first place throughout his
            college career. In fact, Nahil named his first son Vajira after him.
          </p>

          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/04-journey-to-trinity-respice-finem/+buultjens-boys.jpg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>The Buultjens boys Johnny, Billy and Micky</small>
              </p>
            </div>
          </div>
          <p>
            In the third form he was very unpopular with his math teacher, Mr.
            Lekamge. Once, after the final exams, Mr. Lekamge was reading out
            the results to the class, sheet by sheet, ceremoniously handing over
            the examination sheets after correction to each boy, when he
            suddenly stopped the proceedings, observing five seconds of silence
            while staring at the sheet in his hands with an incredulous look on
            his face. He broke his silence announcing Nahil’s name. He had
            scored a perfect 100. In a thin and edgy voice, Lekamge declared,
            “Had I marked your paper, it would have been much less than 100.”
            Recalls Nahil: “I was smug as a bug!”
          </p>
          <p>
            During the period they lived in Piachaud Gardens, a middle-aged
            couple, Mr. and Mrs. Philip Buultjens and their sons Tony, Jeffry,
            Michael, Johnny and Billy were their neighbours in whose garden the
            kids down the lane would gather to play. They owned a Fort Prefect.
            Soon the bike his father bought him became the bane of the
            Buultjens’ lives. Just for a lark, he made it a point to ride in
            circles around the moving car almost daily on his way to school.
            Trying to recall why they were his quarry, he says they were both
            teachers at college.
          </p>

          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/04-journey-to-trinity-respice-finem/The-Piachaud-Gardens-House.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>The Piachaud Gardens House</small>
              </p>
            </div>
          </div>
          <p>
            He was under their jurisdiction during school hours and they were on
            his ‘turf’ after. Annoying them was his way of making a statement,
            letting them know that he was in control outside school hours. He
            had pushed them to the limit until they were convinced he would get
            himself killed. Mrs. Buultjens snitched to his dad, requesting that
            the bike be taken away from him, foretelling imminent death if not
            disposed of immediately – meaning the bike, not him!
          </p>
          <p>
            He says it still amazes him how his dad never missed a beat; on his
            18th birthday, he received a gift of a car. He remembers thinking,
            it was not bad at all, from a push bike to a car. Ten days later he
            had his license though he never went for a driving test. The license
            was gifted to him by the Examiner, who happened to be a family
            friend and was well aware that Nahil had been driving for a few
            years before his legal age. Driving to school and back was the
            highlight of his day. He often got the ‘look’ from his peers who
            were being dropped off by their parents as he drove into the parking
            lot in his Renault Gordini sports car. He recalls there was much
            talk among his peers, relative to man and machine. He never figured
            out if they were in awe or envious. Whatever, he was the only
            student who drove his car to school!
          </p>

          <p>
            In college, the ‘Thambi Kade’ situated next to the school was the
            hangout for Nahil and his gang, which included Mohan Sahayam,
            Gotabaya Dissanayake and Eardly Wadugodapitiya. Whenever they
            entered the ‘kade’ for a tea and cigarette, the owner would switch
            the dial on his Rediffusion radio and turn the dial to ‘Housewives’
            choice’ on Radio Ceylon specially for the boys to listen to for half
            an hour on Friday from 2:30 to 3.00 p.m. This was a weekly ritual.
            Generally, they had lunch at Green Cabin, after which five or six of
            them would traverse to the bar upstairs for a smoke – according to
            him, they all smoked. One fine day, unfortunately for him, he got
            caught while enjoying a smoke on his own. This was reported to the
            Head Prefect who passed on the information to C.J. Orloff, the
            Principal. The usual letter was dispatched to his parents that read
            ‘your son is a bad influence,’ and should be removed from college
            and was followed by the set ritual – parents trotting in to see the
            Principal; the Principal gruffly reprimanding the son in the
            presence of parents; parents assuring this will never happen again;
            the student getting six of the best; and student back in class after
            a few days of suspension.
          </p>
          <p>
            The college prefects were generally appointed based upon their good
            behaviour combined with academic excellence. These boys were liked
            by their teachers, who depended on their input when it came to
            matters of discipline. Nahil’s tale-bearer concerning his smoking
            was one of the Karunanayake brothers.
          </p>
          <p>
            One morning they met by chance in the school corridor. Siezing the
            opportunity, he passionately called Karunanayake a bastard. Once
            again Karunanayake snitched on him to the Head Prefect, M.V. Mushin,
            who ordered an immediate apology from him to his accuser, to which
            he agreed. Thankfully, on this occasion, the Principal C.J. was not
            involved.
          </p>
          <p>
            He hilariously recalls the scenario: The Class; Head Prefect
            occupying the teacher’s chair, the exit on his right; Nahil,
            standing between the Head Prefect and the exit; and Karunanayake
            sitting like a plum pudding opposite the HP.
            <ul>
              <li>Nahil: (asks aloud) “What am I to say?”</li>
              <li>HP: “Say you are sorry, boy.”</li>
              <li>
                Nahil: “Karunanayake, I’m very sorry you are a bastard” and he
                took off like lightning...
              </li>
            </ul>
          </p>

          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/04-journey-to-trinity-respice-finem/Nahil-with-buddies.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>
                  Nahil with buddies – Lakshman Wijetilake (M) and Luksri
                  Gunawardena (R)
                </small>
              </p>
            </div>
          </div>
          <p>
            He recalls an interesting incident concerning Ishan Bahar of
            ‘Jetliners’ fame. While in Colombo staying with Srinath, they were
            keen to party at the Coconut Grove night club. Realising they were
            sans dancing partners for the evening, they called upon Ishan to
            help out. He was very forthcoming setting up his two sisters as
            their partners and with all arrangments in place they drove to
            Ishan’s home in Wattala around 8 p.m. that evening to pick up the
            girls who were ready and waiting for them. Once they piled into the
            car and were on their merry way, one of the girls requested Srinath
            who was driving, to stop to pick up a friend. He did. She introduced
            the guy to Nahil and Srinath as her boyfriend. A few more miles
            later down the road, they stopped once again to pick up another
            individual who happened to be the other sister’s boyfriend.
            Thoroughly miffed, Nahil and Srinath got to the venue, dropped off
            the couples at the entrance after which they proceeded to Galle Face
            Green so they could strategise their next move. Deciding to just
            hang out at the club with a couple of beers, they parked the car and
            made their way to the club, only to notice the two couples still
            standing at the entrance. Quite perplexed, wondering what was up,
            they subtly inquired from one of the guys as to why they were still
            at the entrance, only to be informed casually by him that they were
            waiting for Nahil or Srinath to pay the entrance fee to the club for
            the two couples!
          </p>
        </Col>
        <Col span={24}>
          <h2 style={{ textAlign: "center" }}>The Importance Of Exams</h2>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "450px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/04-journey-to-trinity-respice-finem/examination-certificate.jpeg"
              }
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>General Certificate of Education</small>
              </p>
            </div>
          </div>
          <p>
            During this era getting into university was a highly-regarded
            achievement. The required results to qualify for sitting for the ‘A’
            Level was five credits at the ‘O’ Level, with university entry
            requiring three ‘A’Level credits. He aced the ‘O’ levels with 5
            credits, unfortunately, falling short by one credit at his ‘A’
            Level, even though he scored credits in maths and physics. Looking
            back he is quite proud of his results and subjects, since none of
            his friends got five credits and given the fact that most of the
            leaders in industries are sans ‘A’level certification and in the
            extreme, the required five credits for their ‘O’level certification
            as well.
          </p>
          <p>
            To him, his results at both exams were a ridiculously pleasant
            surprise, despite not having studied for these exams due to the many
            distractions, predominantly during his ALs. Still at college, with
            his car, he was attracting a legion of female attention. Never one
            to let an opportunity go by, he passionately pursued many.
          </p>
          <p>
            Besides his passionate pursuits, to the complete and utter
            embarrassment of his sisters, his hunting ground was Girls’ High
            School Kandy. Raising the ire of the GHSK administration by driving
            through the ‘in’ and ‘out’ gates of the school, with a car full of
            schoolmates, honking the trucker’s call as they drove through.
            “That,” he says, “was wicked fun!” There were days they did this
            multiple times.
          </p>
          <p>
            Nahil was quite the sportsman during his tenure in college. Besides
            cricket and athletics, he played 2nd XV rugby and was a member of
            the TCK 1st X1 cricket team in 1963. The most memorable moment in
            his cricketing career was a match played in Colombo against Royal
            College. Vijaya Malalasekera was the star batsman for RC. He had
            just commenced his batting, his score still in the low teens, when
            Vijaya hit the ball high in the air. Nahil, fielding at mid-off ran
            forward, managing to get a grip on the ball, only to let it slip
            through his fingers. Vijaya went on to score a century. Sadly, that
            was the (missed) catch that lost the match for Trinity. Needless to
            say, he was embarrassed and endlessly teased all of the season.
            “Hanging in and taking it all like a man was humbling.” As someone
            said ‘failure is not fatal and success is not final’! That was
            comforting. “My college days were remarkable and fun. Some of my
            teachers are responsible for moulding me into the man I am today.”
          </p>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "500px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/04-journey-to-trinity-respice-finem/cricket-team-1-xi.jpeg"
              }
            />
          </div>
          <p>
            Nahil captained and played cricket for both Gibson and Garret Houses
            on three occasions. He remembers holding a bet with Sunil Perera,
            the Captain of Alison House that he would never be able to get him
            out during any match played between Gibson House and Alison House, a
            bet he maintained until he left college. Interestingly Sunil Perera
            went on to be Nahil’s best man at his wedding when he married
            Indrani De Silva. Ironically, Sunil being a family friend of the
            Silvas’, the family had requested him to vouch for Nahil’s character
            before he married their daughter.
          </p>
          <p>
            Mohan Sahayam was the House President, Malin Goonetilake the
            Secretary and Nahil the Treasurer of Garret House, the ‘best’ house
            according to him. Garret House social evenings were held once in two
            months. Flushed with money collected from the members as
            subscription (subs), they served the best food and drink, played the
            most popular music and smoked the most expensive cigarettes
            available. He says, “Smoking was against the rules of the school,
            but as you are aware, rules are made to be broken.’’ The team saw to
            it that all house members invited to these socials had a blast. The
            subs to host these gala shindigs were a very important aspect, a
            chore Nahil took rather seriously. Maybe a tad too seriously, for
            failure by any member to pay the subs of 30 cents due at the end of
            each month was dealt with severely with ear-pulling and the
            occasional ‘toku’ meted out to defaulters.
          </p>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <p>
            The socials were held in the college hall and the guys who owned
            guitars brought them along while he contributed with the latest
            music tapes played on his Grundig TK34 tape recorder which he had
            purchased from the Grundig showroom on Peradeniya Road. Prathab
            Ramanujan, a bigwig at Central Bank was often a victim of Nahil’s
            ear-pulling. Now whenever they meet at gatherings, Ramanujan always
            says, “Nahil used to pull our ears. You all are the guys who made us
            men.’’ ‘‘I doubt our ear pulling made them men,’’ says Nahil. Rama
            is now enjoying his retirement.
          </p>
          <p>
            Russell Tennakoon, a real prim and proper guy, due to a sudden shift
            in personality, joined in rioting with Nahil and his crazy gang,
            something which was totally out of sync from Russell’s normal
            behaviour. This change was observed by a famous teacher at Trinity,
            Mr. Sinnathamby. One afternoon Sinnathamby summoned Russell and said
            to him, “Aay Russel, you better not join this gang of hooligans.
            They will get themselves into and out of trouble while you will
            remain in trouble. Listen to my advice and refrain from joining
            these hooligans.” Nahil has invested quite a bit of his time trying
            to analyse Russell’s change of personality. Maybe he was so bored
            with being proper he took a chance letting his ‘hair down’ and being
            crazy and loved it!
          </p>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "500px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/04-journey-to-trinity-respice-finem/school-leaving-certificate.jpeg"
              }
            />
          </div>
          <p>
            After their ALs, some of Nahil’s peers were getting jobs in Colombo,
            and others, especially the non-academic guys, were taking up jobs as
            planters. His school-leaving certificate had a remark made by
            Principal C.J. Orloff, which read ‘‘Nahil has more than average
            intelligence and an aptitude for things mechanical.’’ Nahil had
            basically, been destroying everything that was in the house: clocks,
            radios and other mechanical stuff. Word may have got round that he
            was mechanically inclined, which set in motion his leaning towards
            engineering.
          </p>
          <p>
            Taking his aptitude for mechanics into consideration, after much
            pondering, he felt an apprenticeship at Walkers, since they were
            famous, seemed like a good idea at the time. One of their tenants –
            his father had a few houses down the road given on rent – was John
            Loam, an Englishman who was the Leaf Director of Tobacco Company. He
            knew Johnny Walker, a third-generation Walker – based at the Colombo
            Office. John put in a word on Nahil’s behalf, enabling him to be
            recruited, kick-starting his Marine Engineering Special
            Apprenticeship at Walkers Dry Docks in 1964, based in Colombo.
          </p>
          <p>
            Walkers, the seventh oldest company in Sri Lanka and a pioneer in
            the engineering and infrastucture industry, was founded in Kandy in
            1854 as John Walker & Co. that designed and manufactured machinery
            for the once-dominant coffee industry.
          </p>
          <p>
            After the Ceylon coffee estates were all but extinguished by the
            leaf blight in the early 1870s, the brand was renamed in 1881 as
            Walker Sons & Company. Walkers expanded to other areas of
            engineering and was successful in repairs and dry docking of mail
            steamers into the expanded artificial harbour in Colombo. Walkers’
            engineering skills also branched out into training of apprentice
            Marine Engineers, the construction industry and the manufacture of
            machinery for tea factories. These machines are used by these
            factories to date.
          </p>
        </Col>
        <Col xs={24} sm={24} md={24}>
          {/* <p>
            During his apprentice days based in Colombo, he met and was
            romantically involved with Maya, daughter of the then Minister of
            Transport. “She was my ‘first love,’” he says with a smile. They had
            a superlative relationship, spending much time together whenever
            possible. One event he enjoyed was driving her around in his Willies
            Aerolark, a gas guzzler that he had purchased very cheap from a
            Director at Macwoods, which was great fun. During this period he
            lived at 3C, St. Kilda’s Lane, Colpetty, sharing a room with Premlal
            Galagoda. In a few weeks, the gossipmongers had done their bit,
            kindling rumours of Nahil and Maya’s romance which reached the ears
            of her parents, who were not pleased with the alliance. According to
            him, they were ‘hell-bent’ on tearing apart the romance, primarily
            due to him not being of ‘Kandyan stock’. One evening he was in his
            room at St. Kilda’s Lane when he heard a rumpus outside. Peering
            through the window he watched a group of cops knocking on his
            neighbour’s front door shouting out ‘Wijesuriya, open the door.’
            That moment for him was pure desperation. With a rush of adrenaline,
            he jumped out of the window shoes and socks in hand, jumped over two
            parapet walls and ran through the Lindsay College quadrangle onto
            Glen Aber Place. The Minister had sent his goons to give Nahil a
            ‘workout,’ and fortunately for him, they had the wrong address. He
            stayed around at a friend’s home in Glen Aber Place, later making
            his way to Perera Lane in Wellawatte to stay at Jothy Godage’s home.
            After his escape, he immediately deployed Jothy to St. Kilda’s Lane
            to stake out the action. On getting there, Jothy had found the place
            teeming with cops, and neighbours. Inquiring from a bystander what
            the din was about, he had got a sensationalised answer in the
            vernacular, “May gedara inna pirimi hadaya, ganu lamayek amaruwe
            dala panala gihilla.” (The lad who lives in this house has put a
            girl into trouble and run off.) I hear this was the conversation
            piece for weeks.
          </p>
          <p>
            Mrs. Hurulle, Maya’s mother, had just given birth to a son and was
            still in the hospital when the entire hullabaloo took place. A few
            days after she returned home, she heard about the rumpus. This was
            not good since Maya was at a marriageable age. To diffuse the
            situation she called Nahil to Stanmore Crescent, their official
            residence for a chat. During the conversation, she said to him,
            ‘‘Look how these men get all these things wrong. Maya was at a
            pharmacy buying a card for the birth of the baby and she came to
            your place to borrow a pen. See how these men get excited about
            nothing!’’ This was her damage control of sorts.
          </p> */}
          <p>
            He stayed at Jothy’s home for a few days till he left for Milapitiya
            Estate on an assignment for Walkers pertaining to the installation
            of a National generator that he was in charge of. The entire
            exercise was estimated to take around two weeks. By this time his
            father, who had heard of all the drama, advised him to stay on the
            estate until the completion of the installation. He also reassured
            Nahil that things would fizzle out once he returned to Colombo after
            the two weeks on the estate. Meanwhile, his father got word to the
            Minister that if he continued to get cops to handle this and
            anything should happen to Nahil, he would shoot each cop personally.
            The drama ended in an anti-climax.
          </p>
          <p>
            On his way to Milapitiya Estate by bus, each time the bus rounded a
            bend, he noticed a man on the bus willfully leaning and pushing
            himself on to the shoulder of a woman standing next to him. It was
            obvious that the woman was bothered by this. Nahil locked eyes with
            the conductor who had also noticed this inference. On the next bend,
            the conductor looked towards the man and shouted, “Mahathaya,
            wanguwa iwarai” – (Sir, the bend is over!)
          </p>
        </Col>
      </Row>
      <ChapterFooter title={"Journey To Trinity – The Best School of All"} />
    </div>
  );
};
