import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";
import { NavBar } from "./components/navigation/navBar";
import ScrollToTop from "./utils/ScrollToTop";
import { TheBook } from "./pages/TheBook";

function App() {
  return (
    <Router>
      <NavBar />
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<TheBook />} />
          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
