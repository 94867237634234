import { Row, Col, Image } from "antd";

export const InMemorium = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="in-memorium">In Memorium</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/42-in-memorium/lakshman-kadirgamar-statue.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Statue of Mr. Lakshman Kadirgamar</small>
              </p>
            </div>
          </div>
          <p>
            Sadly, as we all know, at the height of our civil war, LK was a
            victim of an LTTE sniper. Nahil was feeling guilty that he had
            encouraged LK to take up the National List offer and felt his
            redemption to a certain extent would be the erecting of a statue of
            LK in a prominent place in the city as a memorial to him. &ldquo;We
            sourced around for a place and found the Liberty Circle. Lakshman
            Sirimanne, who was a Director of East West, &lsquo;moved
            mountains&rsquo; to secure this site. Initially, when the idea was
            mooted it was suggested the statue be placed at the Parliament among
            the others there. Nahil vehemently refused. He did not want the
            statue of LK lined up in a rogue&rsquo;s gallery.
          </p>
          <p>
            At the start, Suganthi, LK&rsquo;s second wife, insisted that they
            should not commission a Chinese sculptor since CBK was fully
            involved with the Chinese and all the sculptors commissioned to
            sculpt various statues inclined towards Chinese features. She
            insisted either a Greek or Russian be commissioned who would do a
            better job on the features of the sculpture her argument being that
            LK had sharp features like the Russians.&nbsp;
          </p>
          <p>
            She informed Nahil that she knew someone at the Russian Embassy who
            promised to find the best sculptor in Russia.&nbsp;&nbsp;In the
            following weeks, a name was put forward along with a huge tab as an
            initial payment and final payment. Before the job was confirmed,
            Nahil visited the man&rsquo;s website, all in Russian, which he got
            translated by a friend, Mr. Chandraguptha Wijetilaka, who had
            studied in Russia and spoke fluent Russian. According to the
            website, the sculptor&rsquo;s father was the famous one, not him.
            The website stated that the son only restored statues and did not
            sculpt them. Nahil was freaking mad with Suganthi and pitched into
            her for the un-researched leads which would have ended up in
            disaster. She instantly became persona non grata to him, though he
            somehow managed to extract at least 50 photographs of LK from her
            before the communication shutdown between them.
          </p>
          <p>
            The task at hand was to find a good sculptor. While inquiring around
            and making no headway, he suddenly remembered Gamini
            Dissanayake&rsquo;s statue situated at Viharadevi park which was
            quite well sculpted. He called Srima, Gamini&rsquo;s wife who gave
            him the contact of an excellent local sculptor, Mr. Kurullegama from
            Nugegoda.
          </p>
          <p>
            Mr. Kurullegama was the gent Nahil commissioned to sculpt LK&rsquo;s
            statue. Nahil worked alongside him, providing him with all the
            photographs and also helped by giving it a technical twist, editing
            LK&rsquo;s side profile on camera and projecting the image onto the
            sculpture, thus helping Mr. Kurullegama tremendously in the task of
            achieving clear facial lines, which was an exciting project.
          </p>
          <p>
            The predominant reason for the statue was to ensure that LK would
            not be forgotten. During his tenure as FM, he did much for the
            country, including getting the LTTE banned. This was a sure-fire way
            to keep his memory alive in the hearts of the nation and the
            generations to follow.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/42-in-memorium/lakshman-kadirgamar-statue-sculptor.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Mr. Kurullegama – The Sculptor</small>
              </p>
            </div>
          </div>
          <p>
            Come inauguration day, Nahil was keen to stage it as a college event
            with the Trinity College Kandy OBA saluting Lakshman Kadirgamar and
            not as a &lsquo;political tamasha&rsquo;. The Chief Justice Sarath
            Silva, a Trinitian was invited as Chief Guest. Among the invitees
            was Suganthi.
          </p>
          <p>
            When Lakshman Sirimanne called Suganthi regarding a RSVP to the
            event, she intimated to him that she would not be attending.
            &ldquo;I predicted her presence on the day since not attending would
            have been a disgrace to her and not to us&rdquo; says Nahil. Two
            days before the event she informed Lakshman Sirimanne that she would
            be attending because of the Chief Justice.
          </p>
          <p>
            The statue was draped in the Trinity flag of red, yellow and blue
            and unveiled by the Chief Justice. The event was concluded with a
            speech by Cabinet Minister, Champika Ranawaka.
          </p>
          <p>
            Nahil pensively says, &ldquo;LK never wanted a security detail,
            especially since they frisked all his friends when they visited him.
            I guess to him it was a necessary evil&rsquo;&rsquo; The burning
            question for Nahil was &lsquo;&lsquo;where were they when he was
            shot?&rdquo;.
          </p>
          <p>
            After Suganthi got married to Lakshman Kadirgamar, Nahil and LK
            would sometimes have a drink together at LK&rsquo;s Wijerama home,
            as he quietly drifted away from socializing, preferring to be on his
            own. On one such visit, Suganthi informed Nahil that Charles
            Gnanakoon, his friend, the ship&rsquo;s captain, known to be a LTTE
            sympathizer, had visited Lakshman. During his visit, after some
            small talk, Suganthi had retired upstairs, after which she had heard
            raised voices and fists banging on the table.
          </p>
          <p>
            Being mariners, Charles Gnanakoon (Charly) and Nahil were casual
            friends. There were times in previous years in the shipping business
            when Nahil had had contact with Charles on matters related to
            shipping. In fact, many years ago it was while he was in
            Charles&rsquo; office at Robina House, Singapore, that Lester called
            Nahil to inform him that his first wife Indrani had left him!&nbsp;
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/42-in-memorium/lakshman-kadirgamar-statue-chief-guest.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Chief Guest Mr. Sarath Silva</small>
              </p>
            </div>
          </div>
          <p>
            Charles had visited Lakshman to find out where Karuna
            Amman&nbsp;&nbsp;was since Karuna had defected from the LTTE,
            letting Prabhakaran down by siding with the Government. It was then
            that Nahil realized she was trying to convey to him that his
            &lsquo;friend&rsquo; Charly, a hard-core &lsquo;LTTE bugger&rsquo;
            was giving LK a hard time.&nbsp;
          </p>
          <p>
            Vinyagamoorthy Muralitharan alias Karuna Amman is a Sri Lankan
            politician who rose to prominence as the leader of the Tamil
            Makkal&nbsp;&nbsp;Viduthalai Pulikal, a breakaway faction of the
            LTTE. Born in 1966, he was a former militant fighter of the LTTE -
            Liberation Tigers of Tamil Eelam for over 20 years. Since 1983 he
            was the top Commander of the LTTE in the Eastern Province and the
            former bodyguard to the LTTE chief, Velupillai Prabhakaran. In 2004
            he alleged the LTTE was ignoring the interest of the Eastern Tamil
            people and renounced violence, giving up arms to enter the political
            arena. This move by Karuna is claimed to be one of the major turning
            points that brought about the end of the two and a half decade
            conflict. He was appointed as a National List member of parliament
            for the ruling UPFA, and later sworn in as Minister of National
            Integration - March 9th, 2009 - after which he joined the Sri Lanka
            Freedom Party (SLFP).
          </p>
          <p>
            Trying to defend himself in this instance, Lakshman denied ever
            meeting Karuna. He had the power to call his security and get
            Charles thrown out into a drain if he wanted to, something that, if
            ever it took place, would never have been an issue. All this was
            Suganthi and her drama. Just like everything in his work life
            &ndash; companies, hotels and machinery &ndash; he was bored with
            ETV. It was time to sell and move on to the next exciting project.
            About this time, his act was trailing off when the scrambling of the
            ETV signals commenced, prompting him to import descramblers from
            India, which he went to great lengths to source.
          </p>
          <p>
            After much back and forth, he had managed to get through to a
            friend, Dr. Palitha Abeykoon who worked for the WHO, based in India,
            requesting him to apply for a de-scrambling unit for his home,
            because de-scramblers were sold only to those living in India.
            Palitha obliged, and air freighted the descrambler to Nahil. This
            mode of descrambling was not that effective in a manner since each
            de-scrambler has its own unique code that carries the serial number
            in the background. The decoder&rsquo;s serial number is used as a
            means of blocking anyone who has not paid their subscription. Nahil
            found all this to be rather tedious and decided to sell ETV.
          </p>
          <p>
            John Keells had got wind of his intention to sell and as a
            conglomerate, he believed a bit of media in the JK machine would not
            hurt. With that thought in mind, he approached Ken Balendra, who was
            the President of John Keells Group during that period.
          </p>
        </Col>
      </Row>
    </div>
  );
};
