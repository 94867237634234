import { Row, Col } from "antd";

export const ETVExtraTerrestrialVision = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="etv-extra-terrestrial-vision">
          ETV - Extra Terrestrial Vision
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Nahil says &ldquo;I live for unique extraordinary challenges,
            certainly operating within the law, still operating on the fringes
            of yet untested challenges where most fear to tread.&rdquo; Thus,
            when the powers that were, liberalised television licences, he made
            a bid and was awarded a fresh licence in order to operate a private
            TV station, being one among three, the other two being TNL owned by
            the Wickremesinghe family and MTV operated by Maharajas.&nbsp;
          </p>
          <p>
            ETV launched shortly after, determined to be the best &lsquo;new TV
            kids in town,&rsquo; broadcasting cable TV programmes on extra
            terrestrial&nbsp;&nbsp;channels just like the state TV stations,
            Rupavahini and ITN, free to&nbsp; &nbsp;&nbsp;the masses even though
            it was too many stations at the time. On the operations side, it was
            a choice between having your own studio or buying programmes since
            the software to run a TV station was far too expensive for these
            operators.
          </p>
          <p>
            Nahil was determined to beat the foreign lot at their own game: Asia
            Sat had just started broadcasting Star TV out of Hong Kong to Asia,
            where they were big. The usual business model used by broadcasters
            in satellite television is to broadcast &lsquo;free to air,&rsquo;
            for a period of time, &nbsp; &nbsp;&nbsp;thus getting everyone in
            any country in the region hooked. With the programmes being telecast
            &lsquo;free to air,&rsquo; any person from any country in the region
            who owned a satellite dish could watch programmes at no charge, with
            India and China being Asia Sat&rsquo;s target markets.
          </p>
          <p>
            They would broadcast free into India, China and the rest of Asia,
            opening a corridor of opportunity to the &lsquo;little
            mudalalis&rsquo; and the &lsquo;ganang kārayās&rsquo; to put up a
            satellite dish, cable the streets, and broadcast these programmes to
            their clients collecting a monthly fee. Spreading this form of
            viewing was a sort of business model by Asia Sat, while some
            politicians and thugs made &lsquo;summa&rsquo; money by just laying
            cables, with the plan to wire up the whole country so that people
            would get hooked on this. Asia Sat normally does this for around two
            years after which&nbsp;&nbsp;they start scrambling the programmes,
            which leads to no programmes. This way they start charging the
            distributors, assuring them by giving them a proper setup box to
            activate and deactivate from outside and charging them a monthly set
            fee with the distributors, in turn, charging their clients a monthly
            fee thus earning substantial profits from the venture. With this new
            twist, the business model changes from free to air to subscriptions.
          </p>
          <p>
            Nahil&rsquo;s mission was to make use of the two free years to
            download and broadcast the signal, free to the people on the normal
            extra-terrestrial lines similar to how Rupavahini and ITN broadcast
            their programmes. Here he was broadcasting good programming at no
            cost to ETV, while the public enjoyed free TV &ndash; a win-win
            situation.
          </p>
          <p>
            Meanwhile Maharajas were irked by all this since they were paying
            premium rates, as much as US$ 50K, for a half hour of BBC, which
            they broadcast as &lsquo;live&rsquo; to the public, though the
            programmes were in reality pre-recorded, their adverts inserted and
            broadcast what was live at 4 p.m. at 6 p.m., two hours behind. They
            made it their mission to look for ways to bungle Nahil and ETV. They
            did so by fabricating a fairy tale about him, which they passed on
            to Mr. Premadasa, who was the President at the time.
          </p>
        </Col>
      </Row>
    </div>
  );
};
