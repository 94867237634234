import { Row, Col } from "antd";

export const ThisAndThat = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="this-and-that">This and That</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            <strong>
              <em>Dan&rsquo;s Plan Actioned</em>
            </strong>{" "}
            &ndash; The strategy Nahil used for the sale of their East West
            Clearing &amp; Forwarding shares to Dan was his genius. Dan had to
            come up with a payment plan and they had agreed to 30 million
            rupees. Dan, unable to furnish this amount of money, looked to Nahil
            for some guidance on how to work around the situation. Determined to
            come up with a plan to help Dan, Nahil, who loves challenges,
            especially if someone says it is not possible, informed Dan that he
            could have all the East West Haulage equipment since there was no
            point in hanging onto the lot. Nahil transferred the entire Clearing
            &amp; Forwarding documentation and the haulage documents to Dan,
            with him going in for a lease with LOLC. EWH sold the equipment to
            LOLC, which they in turn leased to East West C&amp;F. LOLC perused
            Dan&rsquo;s history in the clearing and forwarding business, showing
            no interest in the equipment. Nahil used the equipment as a vehicle
            for Dan, inflating the cost of the equipment to cover his selling
            cost to Dan, who was happy for not being compelled to make any
            capital investment. The lease was signed based only on Dan&rsquo;s
            balance sheets of the previous year. He paid his lease and it was
            done. Like a Volkswagen, a simple machine.
          </p>
          <p>
            <strong>
              <em>Flower Drum</em>
            </strong>{" "}
            &ndash; Nahil was one of four investors in Flower Drum, a Chinese
            restaurant launched by David Gyi in the early &rsquo;80s. The other
            investors were Sohli Captain, Lester Weinman and Tony Arnolda. David
            offered the investors a nice deal where instead of a cash dividend
            on their investments they could dine at Flower Drum to the value of
            Rs. 4,000 a month, a princely sum at the time, which was agreed to
            by all concerned.
          </p>
          <p>
            David Gyi who was formally involved in Chop Sticks Restaurant
            migrated with his family to Australia in the late &lsquo;70s. Not
            finding their expectations met in Australia, the family returned to
            Sri Lanka. Lester&rsquo;s cousin Cheryl was David&rsquo;s wife.
            Lester being a good friend of David, decided to help David set up a
            new Chinese restaurant, being one of theinvestors in the restaurant.
            All four investors put down Rs.50K each and the restaurant, Flower
            Drum was launched in a building owned byDr. Nadesan, which was
            formerly a dispensary situated at the top of 5th lane, on Thurstan
            road.
          </p>
          <p>
            The restaurant was thriving, being more or less the best place for a
            good Chinese meal in Colombo expanding to Flower Lounge and Flower
            Song. On one occasion, during a &lsquo;take away,&rsquo; Nahil
            noticed the &lsquo;take away&rsquo; bill was issued through Flower
            Drum Catering Services. Puzzled, he showed the bill to Lester and
            inquired if he was aware of this, and how another company was being
            operated within the premises with another set of shareholders. They
            called David, questioning him on this new company; David told them
            this company was formed to avoid VAT, which did not apply to
            takeaway businesses promising he would regularize this sometime soon
            as a part of the investment of the four original shareholders. Nahil
            was not happy and requested David to buy his share. David wanted to
            know how much the share cost. When given the figure, he said
            &ldquo;impossible&rdquo;. Then to be fair Nahil offered to buy
            David&rsquo;s share at the same price quoted by Nahil for his share.
            When Lester heard of Nahil&rsquo;s offer to buy David&rsquo;s share,
            he questioned Nahil as to what he was trying to get into and what
            the heck he knew about running a restaurant. At that point, Nahil
            reminded Lester that they had started lots of projects they knew
            nothing about and not done too badly. &lsquo;&lsquo;Ironically, here
            we were talking about operating a restaurant; a few years into the
            future and we are operating a 5 star hotel!&rsquo;&rsquo; The next
            day David bought Nahil&rsquo;s share at the quoted price.
          </p>
          <p>
            Regressing from above, one evening David hosted a dinner for many of
            his friends. Included in the guest list was Nahil. During the
            dinner, David stood up and announced to his guests that he was
            contesting the upcoming election for the post of Captain of the
            Royal Colombo Golf Club (RCGC) and requested all present to join the
            club as members and vote for him. This is how Nahil ended up as a
            member of the Golf Club.
          </p>
          <p>
            <strong>
              <em>East-West Trading</em>
            </strong>{" "}
            &ndash; It was incorporated in 1982, headed by Mazar Fazerally,
            mainly trading in spice exports to India. The spices were stored in
            a rented warehouse that caught fire or wilfully set on fire during
            the &rsquo;83 communal riots. As a result all the stored goods were
            destroyed amounting to huge losses since neither the goods nor the
            warehouse were insured. After settling in full the bank loan taken,
            they immediately discontinued the operation. The other shareholders
            were Nihal Wickramanayake and Jith Warnakulasuriya. The two of them
            including Mazer backed out leaving Nahil to carry the burden.
            Nahilsettled the bank loan in two years.
          </p>
          <p>
            <strong>
              <em>Mentors</em>
            </strong>{" "}
            &ndash; During his tenure as an apprentice at Walkers, he had the
            privilege of being mentored by three machine operators, Siripala,
            Simon and Camillus &ndash; &ldquo;The best I&rsquo;ve encountered in
            all my years,&rdquo; says Nahil. They taught him drilling, boring,
            milling, etc., inculcating in him the finer &lsquo;tricks&rsquo; of
            the trade. After the three retired they were somewhat destitute and
            he took it upon himself to look after the three financially. Of the
            three, only Camillus is alive and sadly has cancer. He comes around
            periodically to the office where the staff have adopted him, helping
            him with his requirements. Matthias was the foreman at that time.
          </p>
        </Col>
      </Row>
    </div>
  );
};
