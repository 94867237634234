import { Row, Col, Image } from "antd";

export const AnaMalalgoda = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="ana-malalgoda">Ana Malalgoda</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/20-ana-malalgoda/arches-chiang-mai-hotel.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Beautiful Arches at the Chiang Mai Hotel</small>
              </p>
            </div>
          </div>
          <p>
            Ana had a heart issue and needed funds to go in for a heart bypass.
            In need to raise money for the operation, he wanted to know if Nahil
            would be interested in buying a block of land he owned in Nuwara
            Eliya behind the Grand Hotel. “I was enthusiastic. Besides helping
            Ana, the location of the land seemed great. I said to Ana ‘let’s go
            take a look at it.’” Once they got there, sitting inside the car, he
            requested Ana to point out the boundaries of his property. Ana just
            waved his hands around and said, “Somewhere there, machang.”
          </p>
          <p>
            Noting the potential, he agreed to buy the land. After settling Ana,
            he developed the land, paved a new road right up the hill bordering
            one side of the land, opened the by-pass, blocked the land out and
            sold it all off within weeks of developing the site.
          </p>
          <p>
            Driving pass the land recently, he says, ‘‘It’s heartening to see
            some nice hotels built on this site after the road was paved.
            Interesting stuff.’’ He then fondly recalls a trip he made to
            Thailand, with Ana, Nawaz Rajabdeen, and customs lawyer M.L.M.
            Ameen.
          </p>
          <p>
            Back on the subject of his business, it was by now a fully-fledged
            company with an excellent infrastructure in place geared to handle
            all aspects of shipping and road haulage. It was well-equipped and
            had the necessary haulage equipment to transport containers and
            heavy machinery throughout the island. The heavy vehicles and
            equipment were parked at their container yard, down Dutugemunu
            Mawatha, Peliyagoda.
          </p>
        </Col>
      </Row>
    </div>
  );
};
