import { Row, Col } from "antd";

export const DonationstoTrinityCollegeKandy = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="donations-to-trinity-college-kandy">
          Donations to Trinity College Kandy
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            It was the time when Lakshman Karaliadda’s father’s funeral took
            place. As expected, many of their college mates were there and even
            though a very somber occasion, a mini college reunion was taking
            place. He recalls Nimal Maralanda and some of Lakshman’s good
            buddies were also present. As they sat around at the funeral,
            discussing college affairs in general, the state of the college
            cricket team which was in the doldrums due to the lack of a proper
            cricket coach came up. When it came to cricket coaches, the practice
            up to that point was to hire coaches from in and around Kandy to
            keep the cost down. Due to this, they were very poorly paid and not
            up to the expected standard. Taking this into consideration, each
            one of the old boys present at the funeral agreed to start a cricket
            fund, to enable the hiring of an effective coach, preferably from
            Colombo. The deposits were to be made into an account and the
            interest earned paid as a supplementary fee to the coach in addition
            to his monthly stipend. However, on the Monday that followed, he was
            the only one who had got the deposit ready. After a few reminders,
            the others followed suit. As agreed the cash was deposited into an
            account as a fixed deposit at Central Finance since the Chairman,
            Chandra Wijeynayake, a Trinitian, gave them an inflated interest
            rate of 18-20%, which was way above the going rate at the time. The
            sole purpose of this exercise as agreed upon earlier was that this
            interest earned would be an added source of remuneration for the
            coaches.
          </p>
          <p>
            There was a story doing the rounds at the time, that the Principal
            didn’t take any notice or action to help out in any manner because
            he had heard that the new cricket coaches, once hired, would receive
            from this special fund remuneration higher than his income. As a
            result, nothing happened for a few years, by which time the interest
            had accumulated quite handsomely.
          </p>
          <p>
            Nahil requested Kavan Rambukwelle, a senior school mate, very
            dedicated to the affairs of the college, to take over as the
            administrator of the cricket coaches. It was of paramount importance
            to choose the right guy with the best connections. Kavan fitted in
            perfectly. He was a Director at Bartleets and, being a part of a
            wide social circle, knew several influential people including a few
            efficient cricket coaches. Most importantly, he had the time to get
            fully involved in the business.
          </p>
          <p>
            Kavan (Kavantissa Rambukwelle) was an exceptional human being. An
            all-rounder, educated at Trinity, he grew up under the care of his
            uncle, Sir Richard Aluvihare. In an appreciation featured in the
            Island Newspaper after Kavan’s demise, Jayantha Dhanapala says of
            him ‘on the rugby field he had the speed and grace of a panther, in
            the professional field he was a company director with the highest
            ethical norms, and as a citizen, he was fiercely independent,
            conscientious and caring.’
          </p>
          <p>
            Kavan found two guys – Shaw Wilson, who played for Royal College,
            and C.T. Edwards, who opened for Sri Lanka – as coaches. All of this
            in place, as agreed by all the ‘cricket fund’ depositors; it was
            time to start paying the coaches from the interest of the FD. Nahil
            decided to check on the fixed deposit, only to find it had
            disappeared after many years from the books of Central Finance.
            Remarkably, all this was exposed during the investigation of a huge
            financial misappropriation scandal at Trinity, including the abuse
            of college funds by the administration.
          </p>
          <p>
            In due course, he decided to check on the fate of the FDs that had
            disappeared during the tenure of a friend of his who was the
            Chairman of Central Finance. Though he had the precise date and time
            the deposits were made, which coincided with Lakshman’s father’s
            funeral, this was a futile exercise and as time went by depleted
            into a bygone incident.
          </p>
          <p>
            While the college pool was under construction, whenever the
            contractor was short of cement or steel, Nahil would supply it free,
            making him the ‘go-to guy’ for this project. He also donated a
            computer centre to the school, set up by Sanjeewa Wickremanayake,
            the head of Ewis, who was a Director of East West Information
            Systems at the time. Nahil realised all these college projects were
            badly planned, even in little things like rugby. For instance, when
            a penalty is awarded or a try has been scored, the player who tries
            to kick the ball to goal makes a little mound of soil to assist in
            placing the ball vertically before kicking it over the goal post, an
            archaic and time-consuming practice. The college rugby coach,
            Quentin Israel, sometimes watching international rugby, had noticed
            the bigger rugby playing nations use a little rubber cone to
            position the ball upright, a simple little thing. Quentin requested
            Nahil who was living in the UK at the time to source the cones,
            which he did and had a bunch of these little cones flown in from the
            UK for use in their future matches. He set the trend, with all the
            rugby-playing schools and clubs following suit.
          </p>
          <p>
            With the conviction that the cricket team’s performance could be
            enhanced even marginally, at the request of Shaw Wilson, who cited
            the lack of fast bowlers in the college team to practice against at
            a good bowling speed, Nahil, who was in London at the time, shipped
            out a ‘John Edrich’ bowling machine named after John Hugh Edrich, a
            former English first-class cricketer who, during a career that ran
            from 1956 to 1978, was considered one of the best batsmen of his
            generation. The machine was set up at the nets on the Asgiriya
            Grounds. Once again this was a first in Sri Lanka, even before the
            Sri Lanka Cricket Board installed its own bowling machine.
          </p>
          <p>
            Even with the bowling machine in place, the Trinity team was still
            getting thrashed at cricket. Whenever the old boys met, there were
            many discussions on finding a solution to the cricket team’s
            pathetic performances. One evening a few club members came up with a
            brilliant suggestion, assumed as brilliant only by them, about
            purchasing a batting machine. This group’s thinking was that the
            college team practicing with both a bowling and batting machine
            would be the game-clincher in the future. They said, ‘‘Nahil, don’t
            you think it’s a great idea?’’ ‘‘Why don’t you help by getting down
            a batting machine for the team?”
          </p>
          <p>
            Nahil patiently gave ear to this foolish suggestion and quite aptly
            replied that he already had the winning formula for the next match.
            The solution was simple. All they had to do at the next match was to
            send the batting and bowling machines on to the pitch instead of the
            team. The machines will win. Machines always do.
          </p>
        </Col>
      </Row>
    </div>
  );
};
