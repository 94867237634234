import { Row, Col } from "antd";

export const DanMukundan = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="dan-mukundan">Dan Mukundan</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Six months into their haulage business, a young man, Mukundan,
            working as a clearing agent at Jones Carriers, the freight
            forwarding arm of the Maharaja organisation, walked into the East
            West office, requesting a quotation for the transport of some cargo.
            At the time Jones were the big guys in cargo clearing and East West
            the transport heavyweights. Mukundan was referred to by everyone by
            the moniker “Muksi’’ which Nahil changed to Dan, a better
            alternative, coined from the Dan in Mukundan. Dan suggested to Nahil
            if he would agree to him joining East West to set up and manage a
            clearing and forwarding section, that would tie in with their
            transport business. Nahil suggested instead of managing the clearing
            and forwarding section, Dan could join the firm as an equal partner
            along with Lester and himself. After a bit of back and forth on how
            much to invest, etc.,
          </p>
          <p>
            Dan had a motorcycle which was his only asset which he sold and
            invested the proceed into East West Clearing & Forwarding,
            registered at Rs.10/- per share, with Lester, Dan and Nahil as equal
            partners. Dan with his ‘star’ wharf clerks (two guys) initially
            housed on the fourth floor made Rs.300,000/- even before leaving
            Jones carriers. In a few weeks, as things started looking up, he
            moved his office to the fifth floor of Ceylinco House.
          </p>
        </Col>
      </Row>
    </div>
  );
};
