export const chapterData = [
  {
    key: "1",
    page: "6",
    title: "The Beginning",
    href: "the-beginning",
  },
  {
    key: "2",
    page: "7",
    title: "Agnes De Silva Malalgoda – Amma",
    href: "agnes-de-silva-malalgoda-amma",
  },
  {
    key: "3",
    page: "13",
    title: "Gratian Wijesuriya – Thaththa",
    href: "gratian-wijesuriya-thaththa",
  },
  {
    key: "4",
    page: "17",
    title: "Journey to Trinity – Respice Finem",
    href: "journey-to-trinity-respice-finem",
  },
  {
    key: "5",
    page: "30",
    title: "Pursuing an Engineering Degree in The UK",
    href: "pursuing-an-engineering-degree-in-the-uk",
  },
  {
    key: "6",
    page: "33",
    title: "Leicester College, England",
    href: "leicester-college-england",
  },
  {
    key: "7",
    page: "42",
    title: "Tuula Rippati",
    href: "tuula-rippati",
  },
  {
    key: "8",
    page: "44",
    title: "Amma’s Sudden Demise",
    href: "ammas-sudden-demise",
  },
  {
    key: "9",
    page: "45",
    title: "5th Engineer, P&O Lines",
    href: "5th-engineer-p&o-lines",
  },
  {
    key: "10",
    page: "49",
    title: "2nd Engineer on ‘Lanka Kalyani’",
    href: "2nd-engineer-on-lanka-kalyani",
  },
  {
    key: "11",
    page: "51",
    title: "Meeting Indrani",
    href: "meeting-indrani",
  },
  {
    key: "12",
    page: "57",
    title: "Family Drama",
    href: "family-drama",
  },
  {
    key: "13",
    page: "58",
    title: "Starting up of East West Enterprises",
    href: "starting-up-of-east-west-enterprises",
  },
  {
    key: "14",
    page: "58",
    title: "A Great Business Partnership",
    href: "a-great-business-partnership",
  },
  {
    key: "15",
    page: "64",
    title: "Donations to Trinity College Kandy",
    href: "donations-to-trinity-college-kandy",
  },
  {
    key: "16",
    page: "67",
    title: "Gifting the Club House to the TCK - OBA",
    href: "gifting-the-club-house-to-the-tck-oba",
  },
  {
    key: "17",
    page: "71",
    title: "Bunker 1 - The 37,000 Tonne Dead Weight Tanker",
    href: "bunker-1-the-37000-tonne-dead-weight-tanker",
  },
  {
    key: "18",
    page: "73",
    title: "Salvaging of the ‘Ravidas’",
    href: "salvaging-of-the-ravidas",
  },
  {
    key: "19",
    page: "77",
    title: "Single Point Mooring Buoy - Colombo Port",
    href: "single-point-mooring-buoy-colombo-port",
  },
  {
    key: "20",
    page: "80",
    title: "Ana Malalgoda",
    href: "ana-malalgoda",
  },
  {
    key: "21",
    page: "82",
    title: "East West Haulage",
    href: "east-west-haulage",
  },
  {
    key: "22",
    page: "85",
    title: "Dan Mukundan",
    href: "dan-mukundan",
  },
  {
    key: "23",
    page: "86",
    title: "Nahil and Avril",
    href: "nahil-and-avril",
  },
  {
    key: "24",
    page: "88",
    title: "Meeting Tuula in Sweden",
    href: "meeting-tuula-in-sweden",
  },
  {
    key: "25",
    page: "93",
    title: "The IBM Building",
    href: "the-ibm-building",
  },
  {
    key: "26",
    page: "98",
    title: "Parakrama Jayasekera and Family",
    href: "parakrama-jayasekera-and-family",
  },
  {
    key: "27",
    page: "103",
    title: "IBM Sri Lanka",
    href: "ibm-sri-lanka",
  },
  {
    key: "28",
    page: "108",
    title: "The Navam Mawatha Business Complex",
    href: "the-navam-mawatha-business-complex",
  },
  {
    key: "29",
    page: "109",
    title: "Mohan Sahayam",
    href: "mohan-sahayam",
  },
  {
    key: "30",
    page: "110",
    title: "East West Properties",
    href: "east-west-properties",
  },
  {
    key: "31",
    page: "111",
    title: "168 Blomendhal Road",
    href: "168-blomendhal-road",
  },
  {
    key: "32",
    page: "112",
    title: "Jothy Godage",
    href: "jothy-godage",
  },
  {
    key: "33",
    page: "114",
    title: "Sri Lanka Shipping – Robert Senanayake Building",
    href: "sri-lanka-shipping-robert-senanayake-building",
  },
  {
    key: "34",
    page: "118",
    title: "Lanka Marine Services – Nahil Takes On John Keells",
    href: "lanka-marine-services-nahil-takes-on-john-keells",
  },
  {
    key: "35",
    page: "121",
    title: "The IBM Agency",
    href: "the-ibm-agency",
  },
  {
    key: "36",
    page: "124",
    title: "South African Marine",
    href: "south-african-marine",
  },
  {
    key: "37",
    page: "125",
    title: "The Kirula Road Yard - Now Asiri Medical",
    href: "the-kirula-road-yard-now-asiri-medical",
  },
  {
    key: "38",
    page: "131",
    title: "Family Holiday to Egypt",
    href: "family-holiday-to-egypt",
  },
  {
    key: "39",
    page: "132",
    title: "Friends Indrani and Anarkali",
    href: "friends-indrani-and-anarkali",
  },
  {
    key: "40",
    page: "134",
    title: "ETV, - Extra Terrestrial Vision",
    href: "etv-extra-terrestrial-vision",
  },
  {
    key: "41",
    page: "135",
    title: "Mr. Lakshman Kadirgamar – His Mentor",
    href: "mr-lakshman-kadirgamar-his-mentor",
  },
  {
    key: "42",
    page: "144",
    title: "In Memorium",
    href: "in-memorium",
  },
  {
    key: "43",
    page: "151",
    title: "Nahil Visits John Keells Holdings",
    href: "nahil-visits-john-keells-holdings",
  },
  {
    key: "44",
    page: "152",
    title: "ETV Sold!",
    href: "etv-sold",
  },
  {
    key: "45",
    page: "154",
    title: "Lester and Nahil End Their Partnership at East West",
    href: "lester-and-nahil-end-their-partnership-at-east-west",
  },
  {
    key: "46",
    page: "155",
    title: "Nahil Moves to the UK",
    href: "nahil-moves-to-the-uk",
  },
  {
    key: "47",
    page: "161",
    title: "Crescat Residencies - John Keells Holdings",
    href: "crescat-residencies-john-keells-holdings",
  },
  {
    key: "48",
    page: "166",
    title: "No.44, Flower Road",
    href: "no44-flower-road",
  },
  {
    key: "49",
    page: "170",
    title: "Roshanne 1966-2002",
    href: "roshanne-1966-2002",
  },
  {
    key: "50",
    page: "171",
    title: "Kavan and Anne Rambukwella",
    href: "kavan-and-anne-rambukwella",
  },
  {
    key: "51",
    page: "173",
    title: "Dr.S.L. Gunasekera",
    href: "dr-s-l-gunasekera",
  },
  {
    key: "52",
    page: "175",
    title: "Donating 15 M Rupees",
    href: "donating-15-m-rupees",
  },
  {
    key: "53",
    page: "177",
    title: "Anecdotes",
    href: "anecdotes",
  },
  {
    key: "54",
    page: "186",
    title: "Cleaning the Beira Lake",
    href: "cleaning-the-beira-lake",
  },
  {
    key: "55",
    page: "193",
    title: "Arcadia and Compass Heights Singapore",
    href: "arcadia-and-compass-heights-singapore",
  },
  {
    key: "56",
    page: "195",
    title: "Reflections",
    href: "reflections",
  },
  {
    key: "57",
    page: "196",
    title: "Acquiring and Sale of Hotel Intercontinental Colombo",
    href: "acquiring-and-sale-of-hotel-intercontinental-colombo",
  },
  {
    key: "58",
    page: "198",
    title: "Donating A Building to the Marine Fraternity",
    href: "donating-a-building-to-the-marine-fraternity",
  },
  {
    key: "59",
    page: "202",
    title: "The Agnes Wijesuriya Charity Fund",
    href: "the-agnes-wijesuriya-charity-fund",
  },
  {
    key: "60",
    page: "205",
    title: "Chairman CWE",
    href: "chairman-cwe",
  },
  {
    key: "61",
    page: "209",
    title: "This and That",
    href: "this-and-that",
  },
  {
    key: "62",
    page: "212",
    title: "The Forbes & Walker Building",
    href: "the-forbes-&-walker-building",
  },
  {
    key: "63",
    page: "215",
    title: "Airlanka Cargo",
    href: "airlanka-cargo",
  },
  {
    key: "64",
    page: "219",
    title: "Patent for Method of Manufacture – Fixed Dish Antennas",
    href: "patent-for-method-of-manufacture-fixed-dish-anetennas",
  },
  {
    key: "65",
    page: "222",
    title: "Marriot Resort and Spa Weligama",
    href: "marriott-resort-and-spa-weligama",
  },
  {
    key: "66",
    page: "226",
    title: "His Typical Day",
    href: "his-typical-day",
  },
  {
    key: "67",
    page: "228",
    title: "Author’s Words",
    href: "authors-words",
  },
  {
    key: "68",
    page: "",
    title: "Lady Ridgeway Hospital",
    href: "lady-ridgeway-hospital",
  },
];
