import { Image } from "antd";

export const AmmasSuddenDemise = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="ammas-sudden-demise">Amma’s Sudden Demise</h1>
      </div>
      <p>
        <div
          style={{
            float: "right",
            margin: "10px",
          }}
        >
          <Image
            style={{
              maxWidth: "400px",
            }}
            src={
              "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/08-ammas-sudden-demise/family-grave-site.jpg"
            }
          />
          <div style={{ textAlign: "center" }}>
            <p>
              <small>Family grave site – Roshanne, Amma and Thaththa</small>
            </p>
          </div>
        </div>
        Digging deeper into his life in the UK, he tells me that despite all the
        highs during the time he spent in London, easily the lowest would be the
        day he got a call from his brother-in-law Lakshman Karalliedde, urging
        him to make his way back to Sri Lanka immediately. “Lakshman informed me
        that my mother was sick and I needed to get back home fast.” He left the
        UK, secure in the fact that with doctors in the family she was in good
        hands. Unfortunately, once in Colombo, he was informed that she had
        passed away from a heart attack. He was about to turn 24 years and in
        shock. “Amma’s sudden death was by far the most devastating single
        incident in my life,” says Nahil. The only immediate solace he had were
        his dad and siblings who, though going through the same emotions, were a
        source of comfort. At the time of her passing his parents were living at
        No. 04 Galkanda Road, Aniwatte, which remains their family home to date
        and where his mother’s ashes were interred in the family burial grounds
        in the compound. His dad who lived to the ripe old age of 97 finally
        joined her in the same resting place in 2011.
      </p>

      <p>
        After a moment’s pause, the conversation still centred on his mother’s
        death. “Amma was a diabetic, I am convinced that the German medication
        Rustinon, prescribed for controlling her sugar, somehow contributed to
        the onset of her heart attack.” This was an era where testing blood
        sugar and other tests were done in a Bunsen burner over a stove. His
        mother’s sugar was high when it was tested the Bunsen burner way at his
        home. Thankfully Rustinon manufactured by Hoertz was withdrawn from the
        market many years back. He returned to London a few days after her
        funeral.
      </p>
    </div>
  );
};
