import { Button, Modal } from "antd";

import { useState } from "react";

export const Video = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const VideoModal = () => {
    return (
      <div className="fontStyleButton">
        <Button
          style={{
            backgroundColor: "salmon",
            color: "white",
          }}
          shape="round"
          onClick={showModal}
          type="link"
        >
          Video
        </Button>
        <Modal
          title="Video"
          width={"700px"}
          centered
          okButtonProps={{
            type: "primary",
            style: { backgroundColor: "salmon" },
          }}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <iframe
            title="video-sup"
            src="https://player.vimeo.com/video/163721649?h=7eff968989"
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal>
      </div>
    );
  };

  return <VideoModal />;
};
