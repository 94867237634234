import { Col, Image, Row } from "antd";
import { ChapterFooter } from "../chapterFooter/chapterFooter";

export const GratianWijesuriyaThaththa = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="gratian-wijesuriya-thaththa">Gratian Wijesuriya - Thaththa</h1>
      </div>
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/03-Gratian-Wijesuriya%E2%80%93Thaththa/thaththa-01.jpeg"
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Gratian Wijesuriya - Thaththa</small>
              </p>
            </div>
          </div>

          <p>
            Fondly talking about his late father, he remembers that as a child,
            that he looked up to dad as his hero. “We had a great father-son
            relationship. I loved pleasing him and he was always terrifically
            proud of my accomplishments.”
          </p>
          <p>
            Even as an adult most times he would go to his dad for advice,
            taking whatever wisdom he imparted very seriously. Amazingly, after
            the untimely demise of his mother, his father, among other things,
            was responsible for setting up a marriage proposal for Nahil with
            Indrani, his first wife! “A versatile man of untiring determination
            - that was my dad!”
          </p>
          <p>
            Being a Civil Engineer working for the Irrigation Department, his
            father was transferred multiple times all over the island, while the
            family remained in Kandy. Finally, when Nahil was around seven, to
            the family’s utter relief, his father decided to continue staying in
            the house they were living at 665, Peradeniya Road. Kandy, opposite
            Mohan Tennakoon’s father’s home, which is now ‘Suwa Sevana
            Hospital,’ Kandy.
          </p>
          <p>
            Nahil’s earliest memory of their Peradeniya Road home was their
            German Shepherd, Wendy. She was very protective of the family and
            would on occasion terrorise their home help whenever she tried to
            serve a meal at the dining table, especially if the ménage was
            gathered there. Unfortunately one morning Wendy took her doggy
            duties too far by attacking the postman, leading to a tragic event
            following the incident. The home help called the Police, who shot
            the dog since an epidemic of rabies was going around. Even though
            Wendy’s test results for rabies turned out negative, the Police had
            no choice but to put the dog down, after which the postman and the
            entire Wijesuriya family were subject to rabies injections for 21
            days each, which was a positively painful business. Regressing to
            the fate of the dog, Nahil says; “That was one sad, depressing
            incident in my life, the memory of which has stayed with me to
            date.”
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/03-Gratian-Wijesuriya%E2%80%93Thaththa/achchi-01.jpeg"
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Ranatunge Jayasekara Koralage Adeline - Achchi</small>
              </p>
            </div>
          </div>
          <p>
            He recalls the rickshaw driver Subramanium who would sit on the
            front steps of their home until it was time to take the siblings to
            school and the ‘Kiri Kos’ lady who was an absolute terror! If she
            stopped at your gate you had to buy, if not all hell broke loose...
          </p>
          <p>
            While living in Peradeniya his father decided to retire from the
            Irrigation Department consequent to a Government directive that
            offered early retirement including all the usual perks for
            government servants who were dissatisfied. Thereafter he worked
            independently and took on Irrigation Department contracts. An
            amiable man, he continued to maintain friendships he had cultivated
            with his co-workers at the Irrigation Department. After the
            contracts tapered off, he concentrated on constructing and selling
            houses, starting with the house at Watarantenna, followed by
            Piachaud Gardens and Aniwatte. He was a great friend of Mrs.
            Piachaud, who, whenever she sold a block of land always offered him
            the best available site for his house constructions.
          </p>
          <p>
            His father was the disciplinarian at home. He remembers one time
            how, as his dad gave him ‘six of the best,’ he tenaciously kept
            shouting, “beat me as much as you want, it does not hurt.” It
            probably hurt plenty, given that he can vividly recall the incident
            even now! One of 12 children, his father was a student at St.
            Joseph’s College, Colombo 10, where he captained the college
            volleyball team, the first non-Catholic to do so. He had a wide
            circle of friends in college; among them was a young Josephian, Mr.
            Hilary Marcelline of cricketing fame who remained friends with him
            until he passed away. Mr. Marcelline would frequently invite his dad
            to invigilate athletic meets since he was an excellent athlete who
            had even won a 400-metre sprint against Olympic Medalist Duncan
            White at a local athletics meet. His father was one of the longest
            living Joes at one time.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/03-Gratian-Wijesuriya%E2%80%93Thaththa/achchi-seeya-01.jpeg"
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>
                  Seeya - Muthukuda Wijesuriya Arachchilage Appuhamy, Achchi -
                  Ranatunge Jayasekara Koralage Adeline"
                </small>
              </p>
            </div>
          </div>

          <p>
            To celebrate his 90th birthday, the family and the East West staff
            dug out all his old scrapbooks and photographs, searched the college
            archives and compiled them into a revolving slide show which was
            played during a party they hosted for him at their family home on
            Galkanda Road, Aniwatte. Enthralled and in shock, he spent the
            evening engrossed watching the slide show, not paying much attention
            to the guests at the party!
          </p>
          <p>
            His paternal grandmother lived until she was 108. Nahil humorously
            says he is depending on the ‘family longevity genes’ to do their bit
            in his lifetime too! After his mother passed away, Nahil’s grandma
            was keen that his father remarried but he refused. “Achchi would
            relentlessly nag dad in Sinhala saying, ‘I know how the Wijesuriyas
            are,” as she kept on insisting that he re-married while imparting to
            the siblings otherwise little-known snippets about their father and
            how difficult it was to control him as a young man, etc. She
            rebutted all his excuses, going on and on at him. Later on, his dad
            got involved with a girl much younger than him, who lived with him
            and took care of him, in fact almost until the time he passed away.
            Since all three children were living overseas, they were grateful
            that she did an excellent job of taking care of their father, even
            though none of them were sure of their civil status. That aside, the
            great job she was doing caring for their father was their only
            concern. Nahil thinks she was a student who used to attend his
            mother’s classes. Now, in her late sixties, she is still around,
            though none of the siblings keep in touch with her.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/03-Gratian-Wijesuriya%E2%80%93Thaththa/nahil-8-years-01.jpeg"
            />

            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Nahil at 8 years old – Watarantenna</small>
              </p>
            </div>
          </div>
          <p>
            We drift on to the subject of his wonder years. He tells me how his
            dad, in the scheme of things, had a grandiose plan for the siblings’
            futures in the area of academia. Putting his brilliant plan into
            action, he used reverse psychology on Nahil and his two siblings.
            Nahil was around eight years old at the time and was living at
            Katugastota. One afternoon, among the many soothsayers that came by,
            Mr. Karolis, a soothsayer of great repute – at least that’s what
            Nahil was told – turned up at their home for a consultation. It was
            an indisputable fact that this man Karolis had an amazing knack of
            making accurate predictions; according to his dad of course.
          </p>
          <p>
            Karolis was there specifically to dish out the futures of the three
            siblings. The family sat around Karolis; his father, mother,
            Maheswari, Sri Kanthi and Nahil, his mouth agape, all ears, to find
            out what Karolis had to say. The consultation about their future
            lasted a mere five minutes. Karolis told Mahes and Kanthi it was
            written in the stars that their destiny was to be doctors and Nahil
            an engineer. “Oh yes, he was on point’’ says Nahil. Years later
            while the three of them were reminiscing at a family gathering, the
            conversation turned to that amazing prediction. That’s when his dad
            chipped in and debunked Karolis’ soothsaying abilities. Apparently,
            Karolis, including the rest of the visiting astrologers, had been
            tutored by Nahil’s father on what to say! Astounded they were. They
            have always been grateful that their father made sure this was their
            destiny by encouraging them.
          </p>
        </Col>
      </Row>
      <ChapterFooter title={"Gratian Wijesuriya - Thaththa"} />
    </div>
  );
};
