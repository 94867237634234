import { Row, Col, Image } from "antd";

export const TheIBMBuilding = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="the-ibm-building">The IBM Building</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/25-the-ibm-building/nawam-prior-to-development.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>Nawam Mawatha prior to development</small>
              </p>
            </div>
          </div>
          <p>
            In the mid-eighties, Lester and Nahil were looking for land to
            construct the East West office building and the only suitable area
            available was at Wekanda, Colombo 2. It was three acres owned by
            George Stuarts where their warehouses with tin roofs were clumped
            together in the middle of the city. How George Stuarts came to own
            this land is an interesting story.
          </p>
          <p>
            Among the enterprising British settlers who bought land in and
            around Colombo in the year 1818, the land at Wekanda was bought by
            Mr. James Stuart, Captain of a ship and a Master Mariner. On arrival
            in Ceylon in 1818, he set himself up as a Merchant Banker and went
            on to buy an extensive acreage of land on the shores of the Beira
            Lake off Wekanda and the shores of Polwatte. Here he set up his
            business and lent his name to &lsquo;Stuart Place&rsquo; that
            section of Galle Road which runs through his former property. James
            Stuart secured the business to his brother George in 1835 and named
            the firm after George. This was done because James held a
            substantive position at the time as Master Attendant of the Colombo
            Port, which precluded him from engaging in any other
            trade.&nbsp;&nbsp;In the 1980s these warehouses were used to store
            their produce of tea.&nbsp;
          </p>
          <p>
            At the time East West was in the process of procuring land for an
            office building, George Stuarts was headed by two European
            gentleman. Despite advertising this large area of land for sale for
            many months, they were unable to attract buyers due to not having a
            proper entrance to the site except through a dirt road which ran
            parallel to the canal off Justice Akbar Mawatha, opposite Elephant
            House, Slave Island.
          </p>
          <p>
            By this time they had let go the idea of buying land here since
            Navam Mawatha was not completed, nor were there signs of a bridge
            being built, though there was talk that a bridge spanning the Beira
            between R.A. De Mel Mawatha and Navam Mawatha, was in the pipeline.
            A few weeks later there were new developments. They heard from a
            friend of Nahil&rsquo;s, Madhan Coomaraswamy of
            &lsquo;Coomsco,&rsquo; that the bridge project was confirmed because
            he had been awarded the contract from the UDA to build it. They had
            also heard through the grapevine that Podi Hamadhuruwo of the
            Gangaramaya Temple had informed Mr.Premadasa that he would like to
            have the annual perahera go around the lake instead of up and down
            Perahera Mawatha.
          </p>
          <p>
            This was exciting news. The land had been advertised for around
            Rupees 125,000 a perch. This price was negotiated and reduced to
            Rupees 110,000 per perch, assuring George Stuarts that once their
            building project was initiated; there would be many others who would
            follow suit.
          </p>
          <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/25-the-ibm-building/ibm-building.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>IBM / East West Building</small>
              </p>
            </div>
          </div>
          <p>
            Getting started on the pre-sale, they found out that one of the
            European gents had a full blocking-out plan of the land which helped
            with their decision. They bought the prime block right in front
            &ndash; eighty perches &ndash; after which they met the sellers
            (first meeting with them) and told them, &ldquo;We have to do a soil
            test the next day.&rdquo;
          </p>
          <p>
            Though it was their initial meeting with the sellers, Nahil had
            already called the soil test guys beforehand and made arrangements
            for the soil test to be conducted as scheduled the next day.
            That&rsquo;s how fast they proceeded from that point on, up to the
            completion of the building, including settling of all bank loans to
            the lenders within a period of eighteen months from inception. This
            was record breaking. The project was helped along by friends and
            family too.
          </p>
          <p>
            Mahen Thambiah&rsquo;s father was the Country Manager of HSBC. East
            West, besides being on good terms with the bank, produced a letter
            issued by IBM confirming their intended lease of the first and
            ground floor of the proposed building, which added extra leverage
            with the bank.
          </p>
          <p>
            Nahil, would occasionally have a drink with Mr. Thambiah, put in a
            word on behalf of East West regarding their loan, which was granted
            to East West in record time.
          </p>

          <p>
            They were delighted to find out that HSBC had never given any
            property development loans to anyone since it only dealt with
            trading. This first was quite uplifting for East West because it
            spoke volumes&nbsp;&nbsp;concerning their credibility. To top it
            all, the loan to East West by HSBC had created a &lsquo;buzz&rsquo;
            in the corporate sector. They settled the loan in eighteen months as
            an appreciation of the bank&rsquo;s confidence in East West, in
            granting the loan and making it a first for property development.
            The payback within this time frame was also possible due to IBM
            paying them the first year lease rental in advance.
          </p>
          <div
            style={{
              float: "left",
              margin: "10px",
            }}
          >
            <Image
              style={{
                maxWidth: "400px",
              }}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-nahil/25-the-ibm-building/ibm-building-present.jpg"
              }
            />
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <p>
                <small>IBM/East West Building – at present</small>
              </p>
            </div>
          </div>
          <p>
            Many others helped in small ways to make this building a reality.
            Avril had a part to play too. She was in London at the time and
            bought a suitcase full of aluminum window locks for the building,
            which were not available in Sri Lanka. On her trip back to Colombo,
            she nearly fainted at the baggage carousel trying to unload the huge
            bag onto a malfunctioning trolley.
          </p>

          <p>
            Once the construction of the IBM building was sanctioned, Madhan
            Coomaraswamy who was an award-winning design engineer who was also
            assigned to construct the Navam Mawatha Bridge was contracted to
            design and supervise the construction of the IBM building.
          </p>
          <p>
            &nbsp; &nbsp;&nbsp;Nahil expected Madhan to be on the site
            supervising his workers for at least 28 hours (sic) a day. Not
            noticing Madhan on the site Nahil questioned his supervisor who
            informed him that Madhan hadn&rsquo;t turned up in weeks to the site
            though his advertising hoarding was secured at a vantage point
            overlooking R.A. De Mel Mawatha on the side of the lake &ldquo;I
            phoned Madhan,&rdquo; says Nahil, &ldquo;And asked him
            &lsquo;What&rsquo;s up? You are supposed to be supervising the
            construction on-site and you are out of sight.&rsquo;&rsquo;
            Madhan&rsquo;s initial explanation was that due to Nahil travelling
            to the UK every few weeks throughout the year he obviously would not
            be aware if Madan was on site or not, after which he comes up with
            this award-winning excuse. &ldquo;As I drive on R.A. De Mel Mawatha
            I can see the building coming up nicely, so I don&rsquo;t need to be
            there.&rdquo; Nahil pitched into him, threatening to dig out his
            hoarding and throw it into the lake. He did follow up on his promise
            and the hoarding was floating on the lake a few minutes after the
            call.&nbsp;
          </p>
          <p>
            Finally, Madhan turned up; he was there every day supervising the
            construction to a finish. &nbsp;It was then that Nahil realised that
            he had travelled to UK and back eleven times within a period of nine
            months to see Roshanne. Madhan eventually migrated to the UK. As
            long as his company &lsquo;Coomsco&rsquo; was in operation in Sri
            Lanka, his advertisement in the yellow pages was a picture of the
            IBM building.
          </p>
        </Col>
      </Row>
    </div>
  );
};
