import { Row, Col } from "antd";

export const StartingupofEastWestEnterprises = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="starting-up-of-east-west-enterprises">
          Starting up of East West Enterprises
        </h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Regarding his business empire East West, he explained how he decided
            on the name of the company. While he was working for P&O Lines based
            in London, he had been discussing with a friend how difficult it was
            to send goods from the West to the East.
          </p>
          <p>
            Then in an eureka moment it dawned on him that the name ‘East West’
            would be ideal for an import/export business. This was a time there
            were restrictions on importing certain items to Sri Lanka with some
            folks even going to the extent of sending things like Wilkinson
            blades in aerogrammes to the island.
          </p>
          <p>
            Meanwhile, Vajira Wijewardena of Anglo Asian, fame was manufacturing
            blades at his Anglo Asian factory under the brand name ‘Sharp’ and
            helping fill the dearth in the market.
          </p>
          <p>
            Nahil says of these blades ‘‘When you shave one side of your face
            you need a second blade to shave the other side!’’ He registered the
            name East West Enterprises UK, following which he started his
            business by buying a supply of Parker pens, which were in great
            demand, to export them to Sri Lanka for sale.
          </p>
          <p>
            To cash in on the wholesale discount price, the buyers were required
            to submit a letter, written on a company letterhead to the
            wholesaler. In lieu, he printed a few letterheads that read ‘East
            West Enterprises United Kingdom’ to secure the discount.
          </p>
          <p>
            Unfortunately, after all his trouble, the pen business did not turn
            out to be very lucrative for him, thanks to his friends. They took
            all his merchandise free of charge, before he could send the
            consignment to Colombo. That was the prelude to East West today,
            sans the pengrabbing friends!
          </p>
        </Col>
      </Row>
    </div>
  );
};
