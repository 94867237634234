import { Row, Col } from "antd";

export const AuthorsWords = () => {
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <h1 id="authors-words">Author’s Words</h1>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <p>
            Dear reader, while compiling and writing Nahil&rsquo;s biography, I
            &lsquo;ve strived to do my best to expand and highlight in as much
            detail as possible the narrative of his life without bordering on
            boredom &ndash; the proactive and productive times of this
            man&rsquo;s exciting career and his maverick approach to life along
            with short and subtle glimpses of his colourful personal life.
          </p>
          <p>
            I sincerely hope you draw inspiration from his many achievements in
            the dynamic sphere of his work-life - never shy of hard work, his
            ability to pursue and persevere, reach for the stars, occasionally
            falling short but rising above, his compassion, the ability of being
            a &lsquo;machang&rsquo; to people from all backgrounds, being
            generous to a fault, his fireball advocacy for the underdog, and
            being always confident in himself and what he stands for, despite
            the many curve balls thrown at him in life.
          </p>
          <p>
            I have spent many hours reflecting on his story, trying to come up
            with one word to describe him. I finally did! Unique. That&rsquo;s
            the &lsquo;Mot juste.&rsquo;
          </p>
          <p>He is himself; he is &lsquo;simply Nahil.&rsquo;</p>
        </Col>
      </Row>
    </div>
  );
};
